import React, { useEffect } from 'react';
import logo from './logo.svg';
import Header from './components/layout/header';
import Menu from './components/layout/menu';
import './App.css';
import Dashboard from './pages/Dashboard';
import { BrowserRouter, Route, Routes, Navigate, RouteProps } from 'react-router-dom';
import Register from './pages/Register';
import Login from './pages/Login';

import Users from './pages/users';
import UserCreate from "./pages/users/create";
import UserEdit from "./pages/users/edit";

import Roles from './pages/roles';
import RoleCreate from './pages/roles/create';
import RoleEdit from './pages/roles/edit';


import Meets from './pages/recruitment/meets';
import MeetsCreate from './pages/recruitment/meets/create';
import MeetsEdit from './pages/recruitment/meets/edit';

import Results from './pages/attribute/results';
import ResultsCreate from './pages/attribute/results/create';
import ResultsEdit from './pages/attribute/results/edit';

import Communications from './pages/attribute/communications';
import CommunicationsCreate from './pages/attribute/communications/create';
import CommunicationsEdit from './pages/attribute/communications/edit';

import Calls from './pages/asistant/calls';
import CallsCreate from './pages/asistant/calls/create';
import CallseCreate from './pages/asistant/calls/ecreate';
import CallsEdit from './pages/asistant/calls/edit';

import CustomersCreate from './pages/accounting/customers/create';
import CustomersEdit from './pages/accounting/customers/edit';

import Transactions from './pages/accounting/transactions';
import TransactionsCreate from './pages/accounting/transactions/create';
import TransactionsEdit from './pages/accounting/transactions/edit';

import Giros from './pages/report/giros';

import TableRevenues from './pages/accounting/table-revenues';
import TableRevenuesCreate from './pages/accounting/table-revenues/create';
import TableRevenuesEdit from './pages/accounting/table-revenues/edit';

import Expenses from './pages/accounting/expenses';
import ExpensesCreate from './pages/accounting/expenses/create';
import ExpensesEdit from './pages/accounting/expenses/edit';


import ContractTemplates from './pages/contract/contract-templates';
import ContractTemplatesCreate from './pages/contract/contract-templates/create';
import ContractTemplatesEdit from './pages/contract/contract-templates/edit';

import AuthorizationAgreements from './pages/contract/authorization-agreements';
import AuthorizationAgreementsCreate from './pages/contract/authorization-agreements/create';
import AuthorizationAgreementsEdit from './pages/contract/authorization-agreements/edit';

import LeaseAgreements from './pages/contract/lease-agreements';
import LeaseAgreementsCreate from './pages/contract/lease-agreements/create';
import LeaseAgreementsEdit from './pages/contract/lease-agreements/edit';

import DownPaymentAgreements from './pages/contract/down-payment-agreements';
import DownPaymentAgreementsCreate from './pages/contract/down-payment-agreements/create';
import DownPaymentAgreementsEdit from './pages/contract/down-payment-agreements/edit';

import PurchaseSaleAgreements from './pages/contract/purchase-sale-agreements';
import PurchaseSaleAgreementsCreate from './pages/contract/purchase-sale-agreements/create';
import PurchaseSaleAgreementsEdit from './pages/contract/purchase-sale-agreements/edit';

import Contracts from './pages/contract/contracts';
import ContractsCreate from './pages/contract/contracts/create';
import ContractsEdit from './pages/contract/contracts/edit';

import WatchSchedule from './pages/asistant/watch-schedule';
import { UserProvider } from './providers/user/userProvider';
import TransactionCombineProvider from './providers/combine/transactionCombineProvider';
import RankingAgents from './pages/report/giros/ranking-agent';
import RankingLocations from './pages/report/giros/ranking-location';
import CashbookCombineProvider from './providers/combine/cashbookCombineProvider';

import RevenueExpense from './pages/report/revenue-expense';

import WorkSchedule from './pages/employee/work-schedule';
import WorkSchedules from './pages/employee/work-schedules';

import SatisfactionSurvey from './pages/customer/satisfaction-survey';

import Buildings from './pages/real-estate/buildings';
import BuildingsCreate from './pages/real-estate/buildings/create';
import BuildingsEdit from './pages/real-estate/buildings/edit';

function App() {

  useEffect(() => {
    const today = new Date();
    localStorage.setItem('ver', 'v 1.19.5');
    localStorage.setItem('verDate','14.06.2024');
  }, []);
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />

          <Route path="/" element={<Dashboard />} />
          <Route path={'/users'} element={<Users />} />
          <Route path={'/users/create'} element={<UserCreate />} />
          <Route path={'/users/:id/edit'} element={<UserEdit />} />

          <Route path={'/roles'} element={<Roles />} />
          <Route path={'/roles/create'} element={<RoleCreate />} />
          <Route path={'/roles/:id/edit'} element={<RoleEdit />} />

          <Route path={'/recruitment/meets'} element={<Meets />} />
          <Route path={'/recruitment/meets/create'} element={<MeetsCreate />} />
          <Route path={'/recruitment/meets/:id/edit'} element={<MeetsEdit />} />

          <Route path={'/attribute/results'} element={<Results />} />
          <Route path={'/attribute/results/create'} element={<ResultsCreate />} />
          <Route path={'/attribute/results/:id/edit'} element={<ResultsEdit />} />

          <Route path={'/attribute/communications'} element={<Communications />} />
          <Route path={'/attribute/communications/create'} element={<CommunicationsCreate />} />
          <Route path={'/attribute/communications/:id/edit'} element={<CommunicationsEdit />} />

          <Route path={'/asistant/calls'} element={<Calls />} />
          <Route path={'/asistant/calls/create'} element={<CallsCreate />} />
          <Route path={'/asistant/calls/ecreate'} element={<CallseCreate />} />
          <Route path={'/asistant/calls/:id/edit'} element={<CallsEdit />} />

          <Route path={'/asistant/watch-schedule'} element={<WatchSchedule />} />

          {/* <Route path={'/accounting/customers'} element={<Customers/>}/> */}
          <Route path={'/accounting/customers/create'} element={<CustomersCreate />} />
          <Route path={'/accounting/customers/:id/edit'} element={<CustomersEdit />} />

          <Route path={'/revenue-expense-reports'} element={<RevenueExpense />} />

          <Route path={'/contract/contract-templates'} element={<ContractTemplates />} />
          <Route path={'/contract/contract-templates/create'} element={<ContractTemplatesCreate />} />
          <Route path={'/contract/contract-templates/:id/edit'} element={<ContractTemplatesEdit />} />

          <Route path={'/contract/contracts'} element={<Contracts />} />
          <Route path={'/contract/contracts/create'} element={<ContractsCreate />} />
          <Route path={'/contract/contracts/create/:id'} element={<ContractsCreate />} />
          <Route path={'/contract/contracts/:id/edit'} element={<ContractsEdit />} />

          <Route path={'/employee/work-schedule'} element={<WorkSchedule />} />
          <Route path={'/employee/work-schedules'} element={<WorkSchedules />} />

          <Route path={'/customer/satisfaction-survey'} element={<SatisfactionSurvey />} />

        </Routes>

        {/* //Transaction operations */}
        <TransactionCombineProvider>
          <Routes>            
            <Route path={'/accounting/transactions'} element={<Transactions />} />
            <Route path={'/accounting/transactions/create'} element={<TransactionsCreate />} />
            <Route path={'/accounting/transactions/:id/edit'} element={<TransactionsEdit />} />

            <Route path={'/report/giros'} element={<Giros />} />
            <Route path={'/report/ranking-agents'} element={<RankingAgents />} />
            <Route path={'/report/ranking-locations'} element={<RankingLocations />} />

            <Route path={'/report/giros'} element={<Giros />} />
            <Route path={'/report/ranking-agents'} element={<RankingAgents />} />
            <Route path={'/report/ranking-locations'} element={<RankingLocations />} />

            <Route path={'/contract/authorization-agreements'} element={<AuthorizationAgreements />} />
            <Route path={'/contract/authorization-agreements/create'} element={<AuthorizationAgreementsCreate />} />
            <Route path={'/contract/authorization-agreements/:id/edit'} element={<AuthorizationAgreementsEdit />} />

            <Route path={'/contract/lease-agreements'} element={<LeaseAgreements />} />
            <Route path={'/contract/lease-agreements/create'} element={<LeaseAgreementsCreate />} />
            <Route path={'/contract/lease-agreements/:id/edit'} element={<LeaseAgreementsEdit />} />

            <Route path={'/contract/down-payment-agreements'} element={<DownPaymentAgreements />} />
            <Route path={'/contract/down-payment-agreements/create'} element={<DownPaymentAgreementsCreate />} />
            <Route path={'/contract/down-payment-agreements/:id/edit'} element={<DownPaymentAgreementsEdit />} />

            <Route path={'/contract/purchase-sale-agreements'} element={<PurchaseSaleAgreements />} />
            <Route path={'/contract/purchase-sale-agreements/create'} element={<PurchaseSaleAgreementsCreate />} />
            <Route path={'/contract/purchase-sale-agreements/:id/edit'} element={<PurchaseSaleAgreementsEdit />} />

            <Route path={'/real-estate/buildings'} element={<Buildings />} />
            <Route path={'/real-estate/buildings/create'} element={<BuildingsCreate />} />
            <Route path={'/real-estate/buildings/:id/edit'} element={<BuildingsEdit />} />

          </Routes>
        </TransactionCombineProvider>


        {/* //Cashbook operations */}
        <CashbookCombineProvider>
          <Routes>
            <Route path={'/accounting/table-revenues'} element={<TableRevenues />} />
            <Route path={'/accounting/table-revenues/create'} element={<TableRevenuesCreate />} />
            <Route path={'/accounting/table-revenues/:id/edit'} element={<TableRevenuesEdit />} />

            <Route path={'/accounting/expenses'} element={<Expenses />} />
            <Route path={'/accounting/expenses/create'} element={<ExpensesCreate />} />
            <Route path={'/accounting/expenses/:id/edit'} element={<ExpensesEdit />} />
          </Routes>
        </CashbookCombineProvider>

      </BrowserRouter>
    </>
  );
}

export default App;
