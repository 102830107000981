import { useEffect, useState } from 'react';
import axios from 'axios';
import { Radio } from 'antd';
import CustomInput from '../../form/customInput';
import BarChart from '../chart/barChart';
import { Bar } from 'react-chartjs-2';

const TransactionChart = () => {
    const [transactions_report, setTransactionsReport] = useState<any | null>([]);
    const [send_transaction_filter, setSendTransactionFilter] = useState(true);
    const [seeMonth, setSeeMonths] = useState<number>(24);
    const [seeTransactionType, setSeeTransactionType] = useState<number>(1);
    const [transactionsChartData, setTransactionsChartData] = useState<{ labels: string[], datasets: any[] }>({ labels: [], datasets: [] });
    const [totalDataSets, setTotalDataSets] = useState<{ sales: number[], rent: number[], total: number[] }>({ sales: [], rent: [], total: [] });
    const [totalLabels, setTotalLabels] = useState<string[]>([]);

 
    

    useEffect(() => {
        if (transactions_report?.giro_type?.total_transaction?.Satış || transactions_report?.giro_type?.total_giro?.Satış) {
            var salesData: any;
            var rentData: any;
            var totalData: any;
            if (seeTransactionType == 1) {
                salesData = transactions_report.giro_type.total_transaction.Satış;
                rentData = transactions_report.giro_type.total_transaction.Kiralama;
                totalData = transactions_report.giro_type.total_transaction.Toplam;
            } else if (seeTransactionType == 2) {
                salesData = transactions_report.giro_type.total_giro.Satış;
                rentData = transactions_report.giro_type.total_giro.Kiralama;
                totalData = transactions_report.giro_type.total_giro.Toplam;
            }

            const salesLabels = Object.keys(salesData);
            const salesDatasets = salesLabels.map(month => salesData[month]);

            const rentLabels = Object.keys(rentData);
            const rentDatasets = rentLabels.map(month => rentData[month]);

            const totalLabels = Object.keys(totalData);
            const totalDatasets = totalLabels.map(month => totalData[month]);

            setTransactionsChartData({
                labels: salesLabels,
                datasets: [{
                    label: 'Satış',
                    data: salesDatasets,
                    backgroundColor: Array(salesLabels.length).fill('rgb(240, 77, 128)'),
                    borderColor: Array(salesLabels.length).fill('rgb(240, 77, 128)'),
                    borderWidth: 1
                }, {
                    label: 'Kiralama',
                    data: rentDatasets,
                    backgroundColor: Array(rentLabels.length).fill('rgb(105, 81, 206)'),
                    borderColor: Array(rentLabels.length).fill('rgb(105, 81, 206)'),
                    borderWidth: 1
                }, {
                    label: 'Toplam',
                    data: totalDatasets,
                    backgroundColor: Array(totalLabels.length).fill('rgb(255 204 90)'),
                    borderColor: Array(totalLabels.length).fill('rgb(255 204 90)'),
                    borderWidth: 1
                }]
            });

            // totalDataSets ve totalLabels güncelleme
            setTotalDataSets({ sales: salesDatasets, rent: rentDatasets, total: totalDatasets });
            setTotalLabels(totalLabels);
        }
    }, [transactions_report]);

    useEffect(() => {
        (
            async () => {
                if (send_transaction_filter) {
                    axios.get(`dashboard/transaction-report?filter_see_month=${seeMonth}&filter_see_transaction_type=${seeTransactionType}`)
                        .then(({ data }) => {
                            setTransactionsReport(data.transactions.report);

                        });
                    setSendTransactionFilter(false);
                }
            }
        )()
    }, [send_transaction_filter]);

    return (
        <>
               <div className="card">
                                <div className="card-body">
                                    <div className="d-lg-flex d-block justify-content-between mb-4">
                                        <Radio.Group
                                            className="d-flex justify-content-center justify-lg-content-end d-sm-block"
                                            value={seeTransactionType}
                                            onChange={(e) => { setSendTransactionFilter(true); setSeeTransactionType(e.target.value); }}
                                            buttonStyle="solid"
                                        >
                                            <Radio.Button value={1}>İşlem Bazlı Rapor</Radio.Button>
                                            <Radio.Button value={2}>Ciro Bazlı Rapor</Radio.Button>
                                        </Radio.Group>
                                        <div className="d-flex justify-content-center justify-lg-content-end">
                                            <div className="align-self-center">
                                                Gösterilen Ay Sayısı
                                            </div>
                                            <div className="ms-2 col-3">
                                                <CustomInput
                                                    type={'number'}
                                                    required={true}
                                                    defaultValue={seeMonth}
                                                    onBlur={(e: any) => { setSeeMonths(e.target.value); setSendTransactionFilter(true); }}
                                                />
                                            </div>
                                        </div>

                                    </div>

                                    <div className="row align-items-center">
                                        <div className="col-xl-12">
                                        <Bar options={{
            responsive: true
        }} data={transactionsChartData} />
                                        </div>
                                    </div>
                                </div>
                            </div>
        </>

    );
};

export default TransactionChart;
