import { useEffect, useState } from 'react';
import axios from 'axios';
import DoughnutChartWithCard from '../chart/doughnutChartWithCard';

const RecruitmentMeetCountChart = ({ start_date, end_date, dateTypeName, sendFilter }: any) => {

    const [meet_report, setMeetReport] = useState<any | null>([]);
    const [meet_results, setMeetResults] = useState([]);

    const [meetsChartData, setMeetsChartData] = useState({
        labels: [''],
        datasets: [
            {
                label: '# of Votes',
                data: [0],
                backgroundColor: [
                    'rgba(255, 204, 90, 0.85)',
                    'rgb(240, 77, 128)',
                    'rgb(105, 81, 206)',
                    'rgb(22, 218, 241)'
                ],
                borderColor: [
                    'rgba(255, 204, 90, 0.85)',
                    'rgb(240, 77, 128)',
                    'rgb(105, 81, 206)',
                    'rgb(22, 218, 241)'
                ],
                borderWidth: 1,
            },
        ],
    });

    useEffect(() => {
        const meetReportKeys = Object.keys(meet_report);
        const wantedProperties = meetReportKeys.filter(property => property.startsWith('total_result_'));
        const meetReportValues = wantedProperties.map(property => meet_report[property]);
        setMeetsChartData(prevState => ({
            ...prevState,
            datasets: [{
                ...prevState.datasets[0],
                data: meetReportValues
            }]
        }));
    }, [meet_report]);

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('attributes/52');

                setMeetResults(data.data);
            }
        )()
    }, []);
    useEffect(() => {
        (
            async () => {
                const generateChartData = (propertyGenus: any) => {
                    const labels: any = [];
                    const data: any = [];

                    Object.values(propertyGenus).forEach((item: any) => {
                        labels.push(item.name);
                        data.push(item.value);
                    });

                    return {
                        labels: labels,
                        datasets: [
                            {
                                label: '# of Votes',
                                data: data,
                                backgroundColor: [
                                    'rgb(105, 81, 206)',
                                    'rgb(240, 77, 128)',
                                    'rgba(255, 204, 90, 0.85)',
                                    'rgb(22, 218, 241)'
                                ],
                                borderColor: [
                                    'rgb(105, 81, 206)',
                                    'rgb(240, 77, 128)',
                                    'rgba(255, 204, 90, 0.85)',
                                    'rgb(22, 218, 241)'
                                ],
                                borderWidth: 1,
                            }
                        ],
                    };
                };
                axios.get(`dashboard/meet-report?filter_between[]=${start_date}&filter_between[]=${end_date}`)
                    .then(({ data }) => {
                        setMeetReport(data?.meet?.report);

                    });
            }
        )()
    }, [sendFilter]);

    meetsChartData.labels = meet_results.map((result: any) => result.name.toString());

    return (
        <DoughnutChartWithCard data={meetsChartData} count={meet_report?.total} dateTypeName={dateTypeName} text={'Recruiter Görüşmeleri'} url={'/recruitment/meets'} />
    );
};

export default RecruitmentMeetCountChart;
