import { Link } from "react-router-dom";
import { CgArrowsExchangeAlt } from 'react-icons/cg';
import { AiOutlineUserSwitch } from 'react-icons/ai';
import { BiMapPin } from 'react-icons/bi';

//Table
import type { ColumnsType } from 'antd/es/table';

import { Table, Tag, Statistic } from "antd";
import { Modal, Button } from "react-bootstrap";
import ProfilePhoto from "../../../user/profilePhoto";
import { NumericFormat } from "react-number-format";
import { useEffect, useState } from "react";
import { GiroReport } from "../../../../models/report/giro/giroReport";
import { RankingAgent } from "../../../../models/report/giro/rankingAgent";
import axios from "axios";

import moment from 'moment';
import dayjs from 'dayjs';
import 'dayjs/locale/tr';
import { Property } from "../../../../models/general/property/property";
import { PropertyType } from "../../../../models/attribute/propertyType/propertyType";
import { PropertyGenus } from "../../../../models/attribute/propertyGenus/propertyGenus";
import { GiroType } from "../../../../models/attribute/giroType/giroType";
import { Customer } from "../../../../models/accounting/customer/customer";
import TransactionDetail from "../../../transaction/detail";
import { Status } from "../../../../models/attribute/status/status";
import { Attribute } from "../../../../models/attribute/attribute";
import { User } from "../../../../models/user";
dayjs.locale("tr");
const { Countdown } = Statistic;
//Table
interface DataType {
  id(id: any): unknown;
  created_at: any;
  status: Status;
  penalty_clause_amount: number;
  penalty_clause_amount_currency: Attribute;
  sale_price: number;
  sale_currency: Attribute;
  deposit_price: number;
  deposit_currency: Attribute;
  property: Property;
  propertyType: PropertyType;
  propertyGenus: PropertyGenus;
  document_no: string;
  key: React.Key;
  transaction_type: Attribute;
  buyer: Customer;
  seller: Customer;
  partner_count: any;
  agent: User
  ;
}

const PurchaseSaleAgreementList = ({ start_date, end_date }: any) => {
  const rowClassName = () => 'no-bottom-border'; // Özel sınıf adı

  const [show_id, setShowID] = useState<any | null>(0);
  const [show, setShow] = useState(false);
  const [show_full_name, setShowFullName] = useState<any | null>('');
  const handleClose = () => setShow(false);

  const [sort, setSort] = useState(false);
  const [sort_url, setSortUrl] = useState<any | null>('&sort[order]=desc');
  const [sort_req, setSortReq] = useState<any | null>([]);


  const [reports, setReports] = useState<GiroReport | any | null>(null);
  const [giro_type_data, setGiroTypeData] = useState<RankingAgent | any | null>();

  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState<any | null>({
    current: 1,
    pageSize: 20,
    currentPageSize: 100,
    total: reports?.total,
    pageSizeOptions: [10, 20, 50, 100, 500, 100],
    showQuickJumper: true,
    showSizeChanger: true,
    showLessItems: true,
    responsive: true,
  });

  const [data, setData] = useState<DataType[]>([]);

  const [redirect_id, setRedirectId] = useState(0);
  const handleRowDoubleClick = (id: any) => {
    setRedirectId(id);
  }
  useEffect(() => {
    (
      async () => {
        setLoading(true);
        axios.get(`contract/purchase-sale-agreements?limit=${pagination.pageSize}&page=${pagination.current}${sort_url}&filter_between[]=${start_date}&filter_between[]=${end_date}`)
          .then(({ data }) => {
            setData(data.purchase_sale_agreement.data);
            setReports(data.purchase_sale_agreement?.report);
            setPagination({
              current: pagination.current,
              pageSize: pagination.pageSize,
              currentPageSize: pagination.currentPageSize,
              total: data.purchase_sale_agreement?.meta?.total
            });
            setLoading(false);
          });
      }
    )();
  }, []);

  //Table
  const columns: ColumnsType<DataType> = [
    {
      title: 'G. Tipi',
      key: 'property',
      dataIndex: 'property',
      render: (property, record) => {
        let giro_type = record.giro_type;
        let propertyGen = '';
        if (property?.propertyGenus) {
          propertyGen = property.propertyGenus.name;
          if (property.propertyType) {
            propertyGen = `${propertyGen} ${property.propertyType.name}`;
          }
        }
        let color = '';
        if (giro_type) {
          if (giro_type?.id == 116) { color = 'red'; } else { color = 'geekblue'; }
        }
        return (
          <>
            <a onClick={() => { setShow(true); setShowID(record.id); setShowFullName(`${record.agent.first_name} ${record.agent.last_name}`); }}>
              {`${record?.agent.first_name} ${record.agent.last_name}`}
            </a>
            <br />
          <div className="d-flex justify-content-start">
          {record.status?.id == 302 && moment(record.last_date_time, 'DD/MM/YYYY HH:mm:ss') > moment() && (

<Tag color="red">
    <Countdown className="custom-antd-countdown"
        value={moment(record.last_date_time, 'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm')}
        format="D Gün HH:mm:ss"
        onFinish={() => {
            return;
        }}

    />
</Tag>
)}
{record.status?.id == 302 && moment(record.last_date_time, 'DD/MM/YYYY HH:mm:ss') < moment() && (
<strong>
    <Tag color="red">Süresi Bitti</Tag>
</strong>
)}

            </div>
          </>
        );
      },
    },
    {
      title: 'Ciro',
      dataIndex: 'deposit_price',
      className: 'text-end',
      sorter: true,
      render: (deposit_price, record) => {
        return (
          <>
            <span>{record.created_at}</span>
            <br />
            <strong>
              <NumericFormat
                value={Number(deposit_price).toString().replace('.', ',')}
                thousandSeparator="."
                decimalSeparator=","
                displayType="text"
              /> {record.deposit_currency?.name} </strong>
          </>
        );
      },
    },
    Table.EXPAND_COLUMN
  ];


  return (
    <>
      <Modal show={show} onHide={handleClose} size="xl" animation={false}>
        {show_id && show_id !== null && show_id !== undefined &&
          <>
            <Modal.Header closeButton>
              <Modal.Title><ProfilePhoto id={show_id} imgHeight={35} /> <span className="ms-2">{show_full_name}</span> - <span className="text-danger">{show_id}</span></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <TransactionDetail id={show_id} />
            </Modal.Body><Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Kapat
              </Button>
              <Link className="btn btn-primary" to={`/contract/purchase-sale-agreements/${show_id}/edit`}>
                Güncelle
              </Link>
            </Modal.Footer>
          </>
        }
      </Modal>
      <Table
        className="table table-striped"
        size="small"
        columns={columns}
        dataSource={data}
        showHeader={false}
        loading={loading}
        scroll={{ y: 300, x: true }}
        rowKey={(record) => `${record.id}`}
        onRow={(record) => {
          return {
            onDoubleClick: (event) => handleRowDoubleClick(record.id)
          };
        }}
        expandable={{
          expandedRowRender: (record) => {
              return (
                  <>
                      <div className="table-reponsive">
                          <table className={`table`}>
                          </table>
                      </div>
                  </>
              )
          },
          rowExpandable: (record) => record.customer?.first_name !== 'Not Expandable',
      }}
        pagination={pagination}
        onChange={(val: any, filter: any, sorter: any, extra: any) => {
          setPagination({
            current: val.current,
            pageSize: val.pageSize,
            currentPageSize: val.currentPageSize
          });
          setSortReq(sorter);
          setSort(true);
        }}
      />
    </>
  );
}

export default PurchaseSaleAgreementList;

