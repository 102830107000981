import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Wrapper from "../components/layout/wrapper";
import { DatePicker, Tag, notification, Space, Radio, Carousel, Rate } from 'antd';
import { connect } from "react-redux";

import CountUp from 'react-countup';

import {
    Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale,
    LinearScale,
    BarElement,
    Title,
} from 'chart.js';
import { Doughnut, Bar } from 'react-chartjs-2';
import axios from "axios";
import { User } from "../models/user";
import { Attribute } from "../models/attribute/attribute";
import dayjs from 'dayjs';
import 'dayjs/locale/tr';

import moment from 'moment';
import locale from 'antd/es/date-picker/locale/tr_TR';

import ProfilePhoto from "../components/user/profilePhoto";
import { Modal, Button } from "react-bootstrap";
import TransactionDetail from "../components/transaction/detail";
import { NumericFormat } from "react-number-format";
import CustomInput from "../components/form/customInput";
import AgentGiroList from "../components/dashboard/agent/list";
import GiroList from "../components/dashboard/giro/list";
import { Customer } from "../models/accounting/customer/customer";
import { WorkSchedules } from "../models/employee/workSchedule/workSchedule";
import PurchaseSaleAgreementList from "../components/dashboard/contract/purchase-sale-agreement/list";
import LeaseAgreementList from "../components/dashboard/contract/lease-agreement/list";
import CallAreaChart from "../components/dashboard/call/callAreaChart";
import TransactionMap from "../components/dashboard/map/transactionMap";
import { NavLink } from "react-router-dom";
import RecruitmentMeetCountChart from "../components/dashboard/recruitment/recruitmentMeetCountChart";
import WatchScheduleActionCountChart from "../components/dashboard/asistant/watchScheduleActionCountChart";
import WatchSchedulesCarousel from "../components/dashboard/asistant/watchSchedulesCarousel";
import SatisfactionSurveyCarousel from "../components/dashboard/customer/satisfactionSurveyCarousel";
import WorkSchedulesCarousel from "../components/dashboard/employee/workSchedulesCarousel";
import TransactionTypesCountChart from "../components/dashboard/transaction/transactionTypesCountChart";
import TransactionChart from "../components/dashboard/transaction/transactionChart";


ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

dayjs.locale("tr");
const { RangePicker } = DatePicker;
const rangePresets: any = {
    'Son 7 Gün': [dayjs().subtract(7, 'days'), dayjs()],
    'Bu Ay': [dayjs().startOf('month'), dayjs().endOf('month')],
    'Geçen Ay': [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
    'Bu Yıl': [dayjs().startOf('year'), dayjs()],
    'Geçen Yıl': [dayjs().subtract(1, 'year').startOf('year'), dayjs().subtract(1, 'year').endOf('year')],
    '1. Çeyrek': [dayjs().startOf('year').add(0, 'month').startOf('month'), dayjs().startOf('year').add(4, 'month').endOf('month')],
    '2. Çeyrek': [dayjs().startOf('year').add(3, 'month').startOf('month'), dayjs().startOf('year').add(5, 'month').endOf('month')],
    '3. Çeyrek': [dayjs().startOf('year').add(6, 'month').startOf('month'), dayjs().startOf('year').add(8, 'month').endOf('month')],
    '4. Çeyrek': [dayjs().startOf('year').add(9, 'month').startOf('month'), dayjs().startOf('year').add(11, 'month').endOf('month')]
};

const monthNames = ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"];
for (let i = 0; i < 12; i++) {
    rangePresets[monthNames[i]] = [dayjs().month(i).startOf('month'), dayjs().month(i).endOf('month')];
}

const Dashboard = (props: { user: User }) => {
    const [verData, setVerData] = useState({ name: 'Ver', visible: true });
    const [selectedDateName, setSelectedDateName] = useState<string>('Bu Ay');
    const [send_filter, setSendFilter] = useState(true);

    useEffect(() => {
        localStorage.removeItem('data6');
        localStorage.removeItem('lastUpdate6');
        localStorage.removeItem('data7');
        localStorage.removeItem('lastUpdate7');

        const storedData = localStorage.getItem('data8');

        if (!storedData) {
            localStorage.setItem('data8', JSON.stringify(verData));
        } else {
            setVerData(JSON.parse(storedData));
        }
    }, []);

    useEffect(() => {
        const lastUpdate = localStorage.getItem('lastUpdate8');

        if (!lastUpdate || new Date().getTime() - new Date(lastUpdate).getTime() > 1000 * 60 * 60 * 24 * 7) {
            const newData = { name: 'İşlem güncelleme alanında sözleşme tarihi değiştirme sorunu çözüldü', visible: true };

            localStorage.removeItem('data8');
            localStorage.setItem('data8', JSON.stringify(newData));
            localStorage.setItem('lastUpdate8', new Date().toString());

            showNotification(newData.name);

            setVerData({ ...verData, visible: false });
        }
    }, [verData]);

    useEffect(() => {
        localStorage.setItem('data8', JSON.stringify(verData));
    }, [verData]);

    const showNotification = (name: string) => {
        const verInfo = localStorage.getItem('ver');
        notification.open({
            type: 'info',
            duration: 0,
            message: `Versiyon ${verInfo} Güncellemesi`,
            description: (
                <div>
                    <strong>Güncelleme kapsımında yapılan yenilikler</strong>
                    <ul className="list-unstyled">
                        <li>✔ Arama kayıtları otomatik veri çekmek için işlemler yapıldı.</li>
                        <li>✔ Hız için optimizasyon yapıldı.</li>
                    </ul>
                </div>
            ),
        });
    };

    const [date_range, setDateRange] = useState<any | null>([]);
    const [start_date, setStartDate] = useState<any | null>(dayjs().startOf('month').format('YYYY-MM-DD'));
    const [end_date, setEndDate] = useState<any | null>(dayjs().endOf('month').format('YYYY-MM-DD'));

    //Transciton Modal 
    const [show_id, setShowID] = useState<number>(0);
    const [show_full_name, setShowFullName] = useState<any | null>('');

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const startOfWeek = moment().startOf('isoWeek').format('YYYY-MM-DD');
    const endOfWeek = moment().endOf('isoWeek').format('YYYY-MM-DD');

    const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
    const endOfMonth = moment().endOf('month').format('YYYY-MM-DD');

    const startOfYear = moment().startOf('year').format('YYYY-MM-DD');
    const endOfYear = moment().endOf('year').format('YYYY-MM-DD');

    const modalLoaded = () => {
    };

    return (
        <Wrapper>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0 font-size-18">Remax AQUA</h4>
                        {props.user.role_id === 1 || props.user.id === 1 ?
                            <Space>
                                <label className="pt-2">Rapor Tarih Aralığı</label>
                                <div className="form-group select-style">
                                    <RangePicker
                                        format={'DD/MM/YYYY'}
                                        locale={locale}
                                        ranges={rangePresets}
                                        value={date_range}
                                        style={{ width: '100%' }}
                                        onChange={(value: any, dateString) => {
                                            setDateRange(value);
                                            setStartDate(dayjs(dateString.toString().split(',')[0], 'DD/MM/YYYY').format('YYYY-MM-DD'));
                                            setEndDate(dayjs(dateString.toString().split(',')[1], 'DD/MM/YYYY').format('YYYY-MM-DD'));
                                            setSendFilter(true);

                                            const selectedRangeKey = Object.keys(rangePresets).find(key => {
                                                const range = rangePresets[key];
                                                return dayjs(value[0]).isSame(range[0]) && dayjs(value[1]).isSame(range[1]);
                                            });

                                            if (selectedRangeKey) {
                                                setSelectedDateName(selectedRangeKey);
                                            } else {
                                                setSelectedDateName(dateString.toString().split(',')[0] + '-' + dateString.toString().split(',')[1]);
                                            }

                                        }
                                        }
                                    />
                                </div>

                            </Space> : null}
                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><a href="javascript: void(0);">Gösterge Paneli</a></li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>
            {props.user.role_id === 1 || props.user.id === 1 ?
                <>
                    <div className="row">
                        <div className="col-xl-3 col-md-6">
                            <RecruitmentMeetCountChart start_date={start_date} end_date={end_date} dateTypeName={selectedDateName} sendFilter={send_filter} />
                        </div>
                        <div className="col-xl-3 col-md-6">
                            <WatchScheduleActionCountChart start_date={start_date} end_date={end_date} dateTypeName={selectedDateName} sendFilter={send_filter} />
                        </div>
                        <TransactionTypesCountChart start_date={start_date} end_date={end_date} dateTypeName={selectedDateName} sendFilter={send_filter} />
                    </div>
                    <div className="row">
                        <div className="col-xl-6">
                            <div className="card pb-0">
                                <div className="card-header align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">Anlık Arama Raporları</h4>
                                </div>
                                <div className="card-body px-3">
                                    <CallAreaChart />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-4">
                            <div className="card  pb-0">
                                <div className="card-header align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">Son İşlemler</h4>
                                </div>

                                <div className="card-body px-3">
                                    <GiroList start_date={startOfMonth} end_date={endOfMonth} />
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-4">
                            <div className="card  pb-0">
                                <div className="card-header align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">Alım Satım Protokolleri</h4>
                                </div>

                                <div className="card-body px-3">
                                    <PurchaseSaleAgreementList start_date={start_date} end_date={end_date} />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div className="card  pb-0">
                                <div className="card-header align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">Kira Kontratları</h4>
                                </div>

                                <div className="card-body px-3">
                                    <LeaseAgreementList start_date={start_date} end_date={end_date} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-4">
                            <div className="card">
                                <WatchSchedulesCarousel />
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div className="card">
                                <div className="card-header align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">Müşteri Yorumları</h4>
                                </div>
                                <div className="card-body">
                                    <SatisfactionSurveyCarousel />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div className="card">
                                <div className="card-header align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">Personel Aktiviteleri</h4>
                                </div>
                                <div className="card-body">
                                    <WorkSchedulesCarousel />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-4">
                            <div className="card">
                                <div className="card-header align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">Haftanın Danışmanları</h4>
                                </div>

                                <div className="card-body px-3 pb-0">
                                    <AgentGiroList start_date={startOfWeek} end_date={endOfWeek} />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div className="card  pb-0">
                                <div className="card-header align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">Ayın Danışmanları</h4>
                                </div>

                                <div className="card-body px-3 pb-0">
                                    <AgentGiroList start_date={startOfMonth} end_date={endOfMonth} />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div className="card  pb-0">
                                <div className="card-header align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">Yılın Danışmanları</h4>
                                </div>

                                <div className="card-body px-3 pb-0">
                                    <AgentGiroList start_date={startOfYear} end_date={endOfYear} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex flex-wrap align-items-center mb-2">
                                        <h5 className="card-title me-2">Anlık İşlem Haritası</h5>
                                        <div className="ms-auto">
                                            <div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row align-items-center">
                                        <div className="col-xl-12">
                                            <TransactionMap start_date={startOfMonth} end_date={endOfMonth} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12">
                            <TransactionChart />
                        </div>
                    </div>


                    <Modal show={show} onHide={handleClose} onEntered={modalLoaded} size="xl" animation={false}>
                        {show_id && show_id !== null && show_id !== undefined &&
                            <>
                                <Modal.Header closeButton>
                                    <Modal.Title><ProfilePhoto id={show_id} imgHeight={35} /> <span className="ms-2">{show_full_name}</span> - <span className="text-danger">{show_id}</span></Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <TransactionDetail id={show_id} />
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleClose}>
                                        Kapat
                                    </Button>
                                    <Link className="btn btn-primary" to={`/accounting/transactions/${show_id}/edit`}>
                                        Güncelle
                                    </Link>
                                </Modal.Footer>
                            </>
                        }
                    </Modal>
                </>
                :
                <>
                    <div className="row">
                        <div className="profile-content">
                            <div className="row align-items-end">
                                <div className="col-sm">
                                    <div className="d-flex align-items-end mt-5 mt-sm-5 pt-3">
                                        <div className="flex-shrink-0">
                                            <div className="avatar-xxl me-3">
                                                <img src="/assets/images/users/avatar-1.jpg" alt="" className="img-fluid rounded-circle d-block img-thumbnail" />
                                            </div>
                                        </div>
                                        <div className="flex-grow-1">
                                            <div>
                                                <h5 className="font-size-16 mb-1">{props.user.name}</h5>
                                                <p className="text-muted font-size-13 mb-2 pb-2">{props.user?.role?.name}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-xl-8 col-lg-8">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title mb-0">Görev Tanımı</h5>
                                </div>

                                <div className="card-body">
                                    <div>
                                        <div className="pb-3">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-4 col-lg-4">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title mb-0">Departman</h5>
                                </div>
                                <div className="card-body">
                                    <div className="d-flex flex-wrap gap-2 font-size-16">
                                        <a href="#" className="badge badge-soft-primary">{props.user.role.name}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }

        </Wrapper>

    )
}

export default connect(
    (state: { user: User }) => {
        return {
            user: state.user
        };
    }
)(Dashboard);