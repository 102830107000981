import axios from "axios";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { Link, Navigate, NavLink, useParams } from "react-router-dom";
import Wrapper from "../../../components/layout/wrapper";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { Result } from "../../../models/attribute/result/result";
import { Communication } from "../../../models/attribute/communication/communication";
import isSelectOption from '../../../components/helper/selectSetting';
import AsyncCreatableSelect from 'react-select/async-creatable';

import { Select, DatePicker, Checkbox, ConfigProvider, message } from 'antd';
import tr from 'date-fns/locale/tr';

import { Attribute } from "../../../models/attribute/attribute";
import CustomInput from "../../../components/form/customInput";
import { BreadcrumbItem } from "../../../models/constant/breadcrumb/breadcrumb";
import Breadcrumb from "../../../components/constant/breadcrumb";
import moment from "moment";
import { User } from "../../../models/user";
import { PatternFormat } from "react-number-format";
import { PhoneCode } from "../../../models/general/country/phoneCode";

const { Option } = Select;

interface FormValues {
    first_name: string;
    last_name: string;
    email: string;
    phone_code_id: number | null;
    phone: string;
    address: string;
    definition: string;
    customer_type_id: number | null;
    directed_id: number | null;
    result_id: number | null;
    communication_id: number | null;
    call_date_time: string;
}


const Edit = () => {

    const { id } = useParams();

    function getCurrentDateTime() {
        const now = new Date();
        const offset = now.getTimezoneOffset();
        const localDateTime = new Date(now.getTime() - offset * 60 * 1000);
        return localDateTime.toISOString().slice(0, -8);
    }

    const [formValues, setFormValues] = useState<FormValues>({
        first_name: '',
        last_name: '',
        email: '',
        phone_code_id: 34,
        phone: '',
        address: '',
        definition: '',
        directed_id: null,
        customer_type_id: null,
        result_id: null,
        communication_id: null,
        call_date_time: moment().format('YYYY-MM-DD HH:mm'),
    });



    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await axios.get(`asistant/calls/${id}`);
                const updatedData = {
                    ...data,
                    first_name: data.customer.first_name,
                    last_name: data.customer.last_name,
                    email: data.customer.email,
                    phone: data.customer.phone,
                    phone_code_id: data.customer.phone_code_id,
                    address: data.customer.address,
                    customer_type_id: data.customerType.id,
                    communication_id: data.communication.id,
                    result_id: data.result.id,
                    directed_id: data.directed.id,
                };
                setFormValues(updatedData);
            } catch (error) {
                console.error('Error fetching data', error);
            }
        };

        fetchData();
    }, [id]);



    const [isFormValid, setIsFormValid] = useState(true);
    const [phone_codes, setPhoneCodes] = useState([]);
    const [phone_code_id, setPhoneCodeId] = useState(34);

    const [redirect, setRedirect] = useState(false);

    const [results, setResults] = useState([]);
    const [customer_types, setCustomerTypes] = useState([]);
    const [communications, setCommunications] = useState([]);
    const [redirects, setRedirects] = useState([]);


    const customFilterOption = (input: any, option: any) => {
        const normalizedInput = input.toLowerCase().trim();
        const normalizedOption = option.children.toLowerCase().trim();

        return normalizedOption.localeCompare(normalizedInput, 'tr', { sensitivity: 'base' }) === 0;
    };

    const handleInputChange = (field: any, value: any) => {
        setFormValues({ ...formValues, [field]: value });
    };

    const getFieldDisplayName = (field: keyof FormValues): string => {
        const fieldNames: Record<keyof FormValues, string> = {
            first_name: 'Ad',
            last_name: 'Soyad',
            email: 'Email',
            phone: 'Telefon',
            address: 'Adres',
            definition: 'Not',
            directed_id: 'Yönlendirilen',
            result_id: 'Görüşme Sonucu',
            communication_id: 'İletişim Kaynağı',
            customer_type_id: 'Müşteri Türü',
            call_date_time: 'İşlem Tarih / Saat',
        };
        return fieldNames[field];
    };


    const handleSubmit = async (e: any) => {
        e.preventDefault();

        // Check if all required fields are filled
        const requiredFields: (keyof FormValues)[] = [
            'first_name',
            'last_name',
            'email',
            'phone',
            'address',
            'result_id',
            'communication_id',
            'customer_type_id',
            'directed_id',
            'call_date_time',
        ];

        for (const field of requiredFields) {
            if (!formValues[field]) {
                setIsFormValid(false);
                message.error(`Lütfen ${getFieldDisplayName(field)} alanını doldurun.`);
                return;
            }
        }

        // Submit the form if all required fields are filled
        setIsFormValid(true);
        await axios.put(`asistant/calls/${id}`, formValues);
        setRedirect(true);
    };

    const [call_date_time, setCallDateTime] = useState(getCurrentDateTime());

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('general/countries');

                setPhoneCodes(data);
            }
        )()
    }, []);

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('attributes/61');

                setResults(data.data);
            }
        )()
    }, []);

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('attributes/60');

                setCommunications(data.data);
            }
        )()
    }, []);

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('users-filter?filter_status_id=255&limit=1000&sort[by]=first_name&sort[order]=asc');

                setRedirects(data.users.data.data);
            }
        )()
    }, []);


    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('attributes/62');

                setCustomerTypes(data.data);
            }
        )()
    }, []);

    function withEvent(func: Function): React.ChangeEventHandler<any> {
        return (event: React.ChangeEvent<any>) => {
            const { target } = event;
            func(target.value);
        };
    }

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('calls');

                setCalls(data);
            }
        )()
    }, []);


    if (redirect) {
        return <Navigate to={'/asistant/calls'} />;
    }

    const brItems: BreadcrumbItem[] = [
        { label: 'Gösterge Paneli', path: '/' },
        { label: 'Aramalar', path: '/asistant/calls' },
        { label: 'Güncelle', active: true },
    ];

    return (
        <Wrapper>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0 font-size-18">Arama Güncelle</h4>

                        <div className="page-title-right">
                            <Breadcrumb items={brItems} />
                        </div>

                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body p-4">
                            <div>
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-xl-6 col-md-6">
                                            <div className="form-group mb-3">
                                                <label>Ad</label>
                                                <input type="text" required data-pristine-required-message="Lütfen bir isim giriniz."
                                                    className="form-control"
                                                    value={formValues.first_name}
                                                    onChange={(e) => handleInputChange('first_name', e.target.value)}
                                                />
                                                {!formValues.first_name && !isFormValid && <p style={{ color: 'red' }}>Lütfen Ad alanını doldurun.</p>}
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-6">
                                            <div className="form-group mb-3">
                                                <label>Soyad</label>
                                                <input type="text" required data-pristine-required-message="Lütfen bir soyisim giriniz."
                                                    className="form-control"
                                                    value={formValues.last_name}
                                                    onChange={(e) => handleInputChange('last_name', e.target.value)}
                                                />
                                                {!formValues.last_name && !isFormValid && <p style={{ color: 'red' }}>Lütfen soyad alanını doldurun.</p>}
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-6">
                                            <div className="form-group mb-3">
                                                <label>Email</label>
                                                <input type="email" required data-pristine-required-message="Lütfen bir email adresi giriniz."
                                                    className="form-control"
                                                    value={formValues.email}
                                                    onChange={(e) => handleInputChange('email', e.target.value)}
                                                />
                                                {!formValues.email && !isFormValid && <p style={{ color: 'red' }}>Lütfen email alanını doldurun.</p>}
                                            </div>
                                        </div>

                                        <div className="col-xl-6 col-md-6">
                                            <div className="form-group mb-3">
                                                <label>Telefon</label>
                                                <div className="input-group">
                                                    <div className="input-group-text p-0">
                                                        <Select defaultValue={phone_code_id} style={{ width: 100 }} showSearch
                                                            optionFilterProp="children"
                                                            onChange={e => handleInputChange('phone_code_id', e)}
                                                            value={formValues.phone_code_id}
                                                            filterOption={(input, option) => {
                                                                return (
                                                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                );

                                                            }}
                                                        >
                                                            {phone_codes?.map((phone_codes: PhoneCode) => {
                                                                let img_url = `https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/6.6.6/flags/4x3/${phone_codes.code}.svg`;
                                                                return (
                                                                    <Option value={phone_codes.id} title={phone_codes.name}
                                                                        key={phone_codes.name}
                                                                        id={phone_codes.phone_code + ' ' + phone_codes.name}><img src={img_url} height={16} /> +{phone_codes.phone_code}<br /><small className="text-black-50"> {phone_codes.name}</small></Option>
                                                                )
                                                            })}
                                                        </Select>

                                                    </div>
                                                    <PatternFormat format="(###) ### ## ##" name="phone" required className="form-control" data-pristine-required-message="Lütfen bir telefon numarası giriniz." allowEmptyFormatting mask=" " value={formValues.phone} onChange={e => handleInputChange('phone', e.target.value)} />
                                                    {!formValues.phone && !isFormValid && <p style={{ color: 'red' }}>Lütfen bir telefon numarası giriniz.</p>}
                                                </div>

                                            </div>
                                        </div>

                                        <div className="col-xl-4 col-md-4">
                                            <div className="form-group mb-3 select-style">
                                                <label>Yönlendirilen</label>
                                                <Select className="form-control" defaultValue={formValues.directed_id} showSearch
                                                    value={formValues.directed_id}
                                                    placeholder="Yönlendirilen Kişiyi Seçiniz"
                                                    onChange={e => handleInputChange('directed_id', e)}
                                                    optionFilterProp="children"
                                                    filterOption={customFilterOption}
                                                >
                                                    {redirects?.map((redirect: User) => {
                                                        return (
                                                            <Option value={redirect.id} title={`${redirect.first_name} ${redirect.last_name}`}
                                                                key={`${redirect.first_name} ${redirect.last_name}`}
                                                                id={`${redirect.first_name} ${redirect.last_name}`}>{`${redirect.first_name} ${redirect.last_name}`}</Option>
                                                        )
                                                    })}
                                                </Select>
                                                {!formValues.directed_id && !isFormValid && <p style={{ color: 'red' }}>Lütfen yönlendirilen kişiyi seçiniz.</p>}
                                            </div>
                                        </div>

                                        <div className="col-xl-4 col-md-4">
                                            <div className="form-group mb-3 select-style">
                                                <label>Müşteri Türü</label>
                                                <Select className="form-control" defaultValue={formValues.customer_type_id} showSearch
                                                    value={formValues.customer_type_id}
                                                    placeholder="Müşteri Türünü Seçiniz"
                                                    onChange={e => handleInputChange('customer_type_id', e)}
                                                    optionFilterProp="children"
                                                    filterOption={customFilterOption}
                                                >
                                                    {customer_types?.map((customer_type: Attribute) => {
                                                        return (
                                                            <Option value={customer_type.id} title={customer_type.name}
                                                                key={customer_type.name}
                                                                id={customer_type.name}>{customer_type.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                                {!formValues.customer_type_id && !isFormValid && <p style={{ color: 'red' }}>Lütfen müşteri türünü seçiniz.</p>}
                                            </div>
                                        </div>

                                        <div className="col-xl-4 col-md-4">
                                            <div className="form-group mb-3 select-style">
                                                <label>Görüşme Sonucu</label>
                                                <Select className="form-control" defaultValue={formValues.result_id} showSearch
                                                    value={formValues.result_id}
                                                    placeholder="Görüşme Sonucunu Seçiniz"
                                                    onChange={e => handleInputChange('result_id', e)}
                                                    optionFilterProp="children"
                                                    filterOption={customFilterOption}
                                                >
                                                    {results?.map((result: Attribute) => {
                                                        return (
                                                            <Option value={result.id} title={result.name}
                                                                key={result.name}
                                                                id={result.name}>{result.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                                {!formValues.result_id && !isFormValid && <p style={{ color: 'red' }}>Lütfen görüşme sonucunu seçiniz.</p>}
                                            </div>
                                        </div>


                                        <div className="col-xl-4 col-md-4">
                                            <div className="form-group mb-3 select-style">
                                                <label>İletişim Kaynağı</label>
                                                <Select className="form-control" defaultValue={formValues.communication_id} showSearch
                                                    value={formValues.communication_id}
                                                    placeholder="İletişim Kaynağını Seçiniz"
                                                    onChange={e => handleInputChange('communication_id', e)}
                                                    optionFilterProp="children"
                                                    filterOption={customFilterOption}
                                                >
                                                    {communications?.map((communication: Attribute) => {
                                                        return (
                                                            <Option value={communication.id} title={communication.name}
                                                                key={communication.name}
                                                                id={communication.name}>{communication.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                                {!formValues.communication_id && !isFormValid && <p style={{ color: 'red' }}>Lütfen iletişim kaynağını seçiniz.</p>}
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Görüşme Tarih / Saat</label>
                                                <CustomInput
                                                    value={formValues.call_date_time}
                                                    type={'datetime-local'}
                                                    onChange={(e: any) => handleInputChange('call_date_time', e.target.value)}
                                                />
                                                {!formValues.call_date_time && !isFormValid && <p style={{ color: 'red' }}>Lütfen görüşme tarih satini giriniz.</p>}
                                            </div>
                                        </div>

                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Adres</label>
                                                <input type="text" data-pristine-required-message="Lütfen adres giriniz."
                                                    className="form-control"
                                                    value={formValues.address}
                                                    onChange={e => handleInputChange('address', e.target.value)}
                                                />
                                                {!formValues.address && !isFormValid && <p style={{ color: 'red' }}>Lütfen adres alanını doldurun.</p>}
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Not</label>
                                                <textarea
                                                    className="form-control"
                                                    value={formValues.definition}
                                                    onChange={e => handleInputChange('definition', e.target.value)}
                                                >
                                                </textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-primary">Güncelle</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}

export default Edit;