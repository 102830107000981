import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, NavLink, Navigate } from "react-router-dom";
import Wrapper from "../../../components/layout/wrapper";
import Paginator from "../../../components/paginator";
import Mailto from "../../../components/link/mailto";
import Tel from "../../../components/link/tel";
import { Customer } from "../../../models/accounting/customer/customer";
import { Modal, ModalBody, ModalFooter, ModalTitle, Button } from "react-bootstrap";
import { NumericFormat, PatternFormat } from 'react-number-format';
import { FiArrowRight, FiEdit2, FiPlus } from "react-icons/fi";


import Swal from 'sweetalert2';
import { DatePicker, Tag, Select, Table } from 'antd';

//Table
import type { ColumnsType } from 'antd/es/table';

import "react-datepicker/dist/react-datepicker.css";
import { PaymentMethod } from "../../../models/attribute/paymentMethod/paymentMethod";
import TransactionDetail from "../../../components/transaction/detail";
import ProfilePhoto from "../../../components/user/profilePhoto";
import { useGiroTypeContext } from "../../../providers/attribute/giroType/giroTypeProvider";
import { GiroType } from "../../../models/attribute/giroType/giroType";

import 'moment/locale/tr';
import locale from 'antd/es/date-picker/locale/tr_TR';

import moment from 'moment';
import dayjs from 'dayjs';
import 'dayjs/locale/tr';
import { PropertyGenus } from "../../../models/attribute/propertyGenus/propertyGenus";
import { useUserContext } from "../../../providers/user/userProvider";
import { useCountryContext } from "../../../providers/general/location/country/countryProvider";
import { useResourceOfSellContext } from "../../../providers/attribute/sourceOfSell/sourceOfSellProvider";
import { useResourcesOfFindContext } from "../../../providers/attribute/sourceOfFind/sourceOfFindProvider";
import { useLandOfficeContext } from "../../../providers/general/location/landOffice/landOfficeProvider";
import { useProvinceContext } from "../../../providers/general/location/province/provinceProvider";
import { usePropertyGenusContext } from "../../../providers/attribute/propertyGenus/propertyGenusProvider";
import { PhoneCode } from "../../../models/general/country/phoneCode";
import { PropertyType } from "../../../models/attribute/propertyType/propertyType";
import { User } from "../../../models/user";
import { SourceOfFind } from "../../../models/attribute/sourceOfFind/sourceOfFind";
import { Province } from "../../../models/general/location/province/province";
import { District } from "../../../models/general/location/district/district";
import { Neighbourhood } from "../../../models/general/location/neighbourhood/neighbourhood";
import { Site } from "../../../models/general/site/site";
import { Status } from "../../../models/attribute/status/status";
import LeaseAgreementDetail from "../../../components/contract/lease-agreement/detail";
import { LeaseAgreementReport } from "../../../models/contract/lease-agreements/leaseAgreementReport";
import { AiOutlineClose, AiOutlineDelete } from "react-icons/ai";
import { MeetMeta } from "../../../models/recruitment/meet/meetMeta";
import { Meet } from "../../../models/recruitment/meet/meet";
import { Attribute } from "../../../models/attribute/attribute";
import { Metadata } from "react-phone-number-input";
import MeetDetail from "../../../components/recruitment/meet/detail";
import { CallMeta } from "../../../models/asistant/call/callMeta";
import CallDetail from "../../../components/asistant/call/detail";


dayjs.locale("tr");
const { Option } = Select;

const { RangePicker } = DatePicker;
const currentYear = moment().year();
const rangePresets: any = {
    'Son 7 Gün': [dayjs().subtract(7, 'days'), dayjs()],
    'Bu Ay': [dayjs().startOf('month'), dayjs().endOf('month')],
    'Geçen Ay': [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
    'Bu Yıl': [dayjs().startOf('year'), dayjs()],
    'Geçen Yıl': [dayjs().subtract(1, 'year').startOf('year'), dayjs().subtract(1, 'year').endOf('year')],
    '1. Çeyrek': [dayjs().startOf('year').add(0, 'month').startOf('month'), dayjs().startOf('year').add(4, 'month').endOf('month')],
    '2. Çeyrek': [dayjs().startOf('year').add(3, 'month').startOf('month'), dayjs().startOf('year').add(5, 'month').endOf('month')],
    '3. Çeyrek': [dayjs().startOf('year').add(6, 'month').startOf('month'), dayjs().startOf('year').add(8, 'month').endOf('month')],
    '4. Çeyrek': [dayjs().startOf('year').add(9, 'month').startOf('month'), dayjs().startOf('year').add(11, 'month').endOf('month')]
};

const monthNames = ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"];
for (let i = 0; i < 12; i++) {
    rangePresets[monthNames[i]] = [dayjs().month(i).startOf('month'), dayjs().month(i).endOf('month')];
}


//Table
interface DataType {
    id(id: any): unknown;
    customer: Customer;
    directed: User;
    customerType: Attribute;
    communication: Attribute;
    result: Attribute;
    user: User;
    creadet_at: any;
    meta: CallMeta;
    key: React.Key;
}

const Calls = () => {

    const [selected_agents, setSelectedAgents] = useState([]);

    const calculateDateDifference = (end_date: any) => {
        const today = moment();
        const endDate = moment(end_date, "DD/MM/YYYY");
        const differenceInDays = endDate.diff(today, "days");
        return differenceInDays;
    };

    const [reports, setReports] = useState<LeaseAgreementReport | any | null>(null);
    const [users, setUsers] = useState<any | null>([]);
    const [giro_types, setGiroTypes] = useGiroTypeContext();
    const [phone_codes, setPhoneCodes] = useCountryContext();
    const [resources_of_find, setResourcesOfFind] = useResourcesOfFindContext();
    const [land_offices, setLandOffices] = useLandOfficeContext();
    const [provinces, setProvinces] = useProvinceContext();
    const [property_genuses, setPropertyGenuses] = usePropertyGenusContext();

    const [data, setData] = useState<any | null>([]);
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(0);
    const [show_id, setShowID] = useState<number>(0);
    const [show_full_name, setShowFullName] = useState<any | null>('');
    const [redirect_id, setRedirectId] = useState(0);
    const [property_types, setPropertyTypes] = useState([]);
    const [statuses, setStatuses] = useState([]);

    const [districts, setDistricts] = useState([]);
    const [neighbourhoods, setNeighbourhoods] = useState([]);

    const [calls, setCalls] = useState([]);
    const [meet, setMeet] = useState<Meet | any>([]);
    const [meetMeta, setMeetMeta] = useState([]);
    const [meetID, setMeetID] = useState('');

    //Detail Modal
    const [loading, setLoading] = useState(true);
    const [full_name, setFullName] = useState('');


    //Modal    
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (id: number) => {
        (
            async () => {
                setShow(true);
                const meet = await axios.get(`asistant/calls/${id}`);
                setMeet(meet.data);
                setMeetID(meet.data.id);
                setFullName(meet.data.customer?.first_name + " " + meet.data.customer?.last_name);
                setMeetMeta(meet.data.meta);
            }
        )()
    };

    const modalLoaded = () => {
    };

    const del = async (id: number) => {

        Swal.fire({
            title: 'Emin misiniz?',
            text: "Kalıcı olarak silinecektir",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#1c84ee',
            cancelButtonColor: '#fd625e',
            confirmButtonText: 'Evet, sil!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await axios.delete(`recruitment/calls/${id}`);

                setCalls(calls.filter((u: Meet) => u.id !== id));
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Veri silindi',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        })
    }


    //Modal add meet
    const [name, setName] = useState('');
    const [definition, setDefinition] = useState('');
    const [meet_date_time, setMeetDateTime] = useState(new Date());


    //For Filter
    const [user_categories, setUserCategories] = useState([]);
    const [realtors, setRealtors] = useState([]);
    const [sites, setSites] = useState([]);

    const [customer_type_id, setCustomerTypeId] = useState<any | null>(null);
    const [customer_types, setCustomerTypes] = useState([]);
    const [communication_id, setCommunicationId] = useState<any | null>(null);
    const [communications, setCommunications] = useState([]);


    const [property_genus_ids, setPropertyGenusIds] = useState<any | null>();
    const [property_type_ids, setPropertyTypeIds] = useState<any | null>([]);
    const [giro_type_id, setGiroTypeId] = useState<any | null>(null);
    const [category_id, setCategoryId] = useState<any | null>(null);
    const [extra_filter_see, setExtraFilterSee] = useState(false);
    const [realtor_id, setRealtorId] = useState<any | null>(null);
    const [realtor_search, setRealtorSearch] = useState('');
    const [site_id, setSiteId] = useState<any | null>(null);
    const [site_search, setSiteSearch] = useState('');
    const [source_of_sell_id, setSourceOfSellId] = useState<any | null>(null);
    const [source_of_find_id, setSourceOfFindId] = useState<any | null>(null);
    const [status_id, setStatusId] = useState<any | null>(null);
    const [province_id, setProvinceId] = useState<any | null>(null);
    const [province_name, setProvinceName] = useState('');
    const [district_id, setDistrictId] = useState<any | null>(null);
    const [district_name, setDistrictName] = useState('');
    const [neighbourhood_id, setNeighbourhoodId] = useState<any | null>(null);
    const [neighbourhood_name, setNeighbourhoodName] = useState('');
    const [land_office_id, setLandOfficeId] = useState<any | null>(null);
    const [date_range, setDateRange] = useState<any | null>([]);
    const [start_date, setStartDate] = useState<any | null>(dayjs().startOf('month').format('YYYY-MM-DD'));
    const [end_date, setEndDate] = useState<any | null>(dayjs().endOf('month').format('YYYY-MM-DD'));
    const [out_office_see, setOutOfficeSee] = useState(false);
    const [document_no, setDocumentNo] = useState<any | null>(null);
    const [document_no_val, setDocumentNoVal] = useState<any | null>(null);
    const [customer_name, setCustomerName] = useState<any | null>(null);
    const [customer_name_val, setCustomerNameVal] = useState<any | null>(null);
    const [customer_id_no, setCustomerIdNo] = useState<any | null>(null);
    const [customer_id_no_val, setCustomerIdNoVal] = useState<any | null>(null);
    const [bob, setBob] = useState<any | null>(null);
    const [parcel, setParcel] = useState<any | null>(null);
    const [filter, setFilter] = useState(false);
    const [send_filter, setSendFilter] = useState(true);
    const [filter_url, setFilterUrl] = useState<any | null>('');
    const [sort, setSort] = useState(false);
    const [sort_url, setSortUrl] = useState<any | null>('&sort[order]=desc');
    const [sort_req, setSortReq] = useState<any | null>([]);

    const [customer_phone_code_id, setCustomerPhoneCodeId] = useState<any | null>(34);
    const [customer_phone, setCustomerPhone] = useState<any | null>(null);
    const [clear_filter_inputs, setClearFilterInputs] = useState(false);

    const [metas, setCustomerCount] = useState([0]);
    const handleRemoveItem = (item: any) => {
        setCustomerCount(metas.filter(item => item !== item));
    };

    //Table

    const [pagination, setPagination] = useState<any | null>({
        current: 1,
        pageSize: 100,
        currentPageSize: 100,
        total: reports?.total,
        pageSizeOptions: [10, 20, 50, 100, 500, 100],
        showQuickJumper: true,
        showSizeChanger: true,
        showLessItems: true,
        responsive: true
    });

    const columns: ColumnsType<DataType> = [
        {
            title: 'ID',
            dataIndex: 'id',
            sorter: true,
            responsive: ["md", "lg", "xl", "xxl"],
            render: (id, record) => {
                return (
                    <>
                        {id}
                    </>
                );
            },
        },
        {
            title: 'Ad Soyad',
            key: 'customer',
            dataIndex: 'customer',
            responsive: ["md", "lg", "xl", "xxl"],
            render: (customer,record) => (
                <>
                          <a onClick={() => { handleShow(record?.id); setShow(true); setShowID(record?.id); setShowFullName(`${record?.customer?.first_name} ${record?.customer?.last_name}`); }}>
                            {`${record?.customer?.first_name} ${record?.customer?.last_name}`}
                        </a>
                </>
            ),

        },
        {
            title: 'Yönlendirilen',
            key: 'directed',
            dataIndex: 'directed',
            responsive: ["md", "lg", "xl", "xxl"],
            render: (directed,record) => (
                <>
                          <a onClick={() => { handleShow(record?.id); setShow(true); setShowID(record?.id); setShowFullName(`${record?.directed?.first_name} ${record?.directed?.last_name}`); }}>
                            {`${record?.directed?.first_name} ${record?.directed?.last_name}`}
                        </a>
                </>
            ),

        },
        {
            title: 'Görüşme Tarihi',
            dataIndex: 'meta',
            sorter: true,
            render: (meta) => {
                return (
                    <>
                        {meta[0]?.date} {meta[0]?.time}
                    </>
                );
            },
        },
        {
            title: 'Müşteri Türü',
            key: 'customerType',
            dataIndex: 'customerType',
            render: (customerType) => {

                return (
                    <>
                       <Tag color={customerType?.color}>{customerType?.name}</Tag>
                    </>
                );
            },
        },
        {
            title: 'İletişim Kaynağı',
            dataIndex: 'communication',
            responsive: ["md", "lg", "xl", "xxl"],
            sorter: true,
            render: (communication) => {
                return (
                    <>
                       <Tag color={communication?.color}>{communication?.name}</Tag>

                    </>
                );
            },
        },
        {
            title: 'Görüşme Sonucu',
            responsive: ["md", "lg", "xl", "xxl"],
            dataIndex: 'result',
            sorter: true,
            render: (result) => {
                return (
                    <>
                        <Tag color={result?.color}>{result?.name}</Tag>
                    </>
                );
            },
        },
        {
            title: 'Ekleyen Personel',
            responsive: ["md", "lg", "xl", "xxl"],
            dataIndex: 'user',
            sorter: true,
            render: (user) => {
                return (
                    <>
                        {user?.first_name} {user?.last_name}
                    </>
                );
            },
        },
        
        {
            title: 'Kayıt Tarihi',
            dataIndex: 'created_at',
            sorter: true
        },
        {
            title: '',
            dataIndex: 'command',
            sorter: false,
            render: (id, record: any) => {
                return (
                    <>
                        <div className="dropdown">
                            <button className="btn btn-link font-size-16 shadow-none py-0 text-muted dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="bx bx-dots-horizontal-rounded"></i>
                            </button>

                            <ul className="dropdown-menu dropdown-menu-end">
                                <li>
                                    <Link to={`/asistant/calls/${record.id}/edit`}
                                        className="dropdown-item">Güncelle</Link>
                                </li>
                                <li><a className="dropdown-item" href="#" onClick={() => del(record.id)}>Sil</a></li>
                            </ul>
                        </div>
                    </>
                );
            },
        },
    ];

    //Filter
    useEffect(() => {
        if (clear_filter_inputs) {
            setStatusId(null);
            setCommunicationId(null);
            setCustomerTypeId(null);
            setSelectedAgents([]);
            setDateRange([]);
            setCustomerName('');
            setCustomerNameVal('');
            setCustomerIdNo('');
            setCustomerIdNoVal('');
            setCustomerPhone('');
            setCustomerPhoneCodeId(34);
            setClearFilterInputs(false);
            setFilter(true);
        }
    }, [clear_filter_inputs]);

    useEffect(() => {

        let fUrl = '';
        
        if (customer_type_id > 0) {
            fUrl = `${fUrl}&filter_customer_type_id=${customer_type_id}`;
        }
        if (communication_id > 0) {
            fUrl = `${fUrl}&filter_communication_id=${communication_id}`;
        }
        if (status_id > 0) {
            fUrl = `${fUrl}&filter_status_id=${status_id}`;

        }

        if (selected_agents?.length > 0) {
            selected_agents.forEach((directed_id: number) => {
                fUrl = `${fUrl}&filter_directed_id[]=${directed_id}`;
            });
        }
        setFilterUrl(fUrl);
        setFilter(false);
        setSendFilter(true);
    }, [filter]);

    useEffect(() => {
        setTimeout(() => {
            let sUrl = '';
            let sType = '';
            if (sort_req.order === undefined) {
                sUrl = '&sort[order]=desc';
            } else {
                (sort_req.order == 'ascend') ? sType = '&sort[order]=asc' : sType = '&sort[order]=desc';
                sUrl = `&sort[by]=${sort_req.field}`;
            }
            setSortUrl(sUrl + sType);
            setSort(false);
            setSendFilter(true);
        }, 500);
    }, [sort]);

    useEffect(() => {
        (
            async () => {
                if (send_filter) {
                    setLoading(true);

                    axios.get(`asistant/calls?limit=${pagination.pageSize}&page=${pagination.current}${sort_url}&filter_between[]=${start_date}&filter_between[]=${end_date}${filter_url}`)
                        .then(({ data }) => {
                            setData(data.call.data);
                            setReports(data.call.report);
                            setPagination({
                                current: pagination.current,
                                pageSize: pagination.pageSize,
                                currentPageSize: pagination.currentPageSize,
                                total: data.call.report?.total
                            });
                            setLoading(false);
                        });
                    setSendFilter(false);
                }
            }
        )()
    }, [send_filter]);


    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('attributes/61');
                setStatuses(data.data);
            }
        )()
    }, []);

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('attributes/62');
                setCustomerTypes(data.data);
            }
        )()
    }, []);


    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('attributes/60');
                setCommunications(data.data);
            }
        )()
    }, []);

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('users-filter?limit=1000&status_id=255');
                setUsers(data.users.data.data);
            }
        )()
    }, []);




    const edit = (id: number) => {
        return <Navigate to={`/asistant/calls/${id}/edit`} />;
    }


    const handleRowDoubleClick = (id: number) => {
        setRedirectId(id);
    }


    if (redirect_id > 0) {
        return <Navigate to={`/asistant/calls/${redirect_id}/edit`} />;
    }

    return (
        <Wrapper>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0 font-size-18">Aramalar</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><NavLink to={'/'}>Gösterge Paneli</NavLink></li>
                                <li className="breadcrumb-item active">Aramalar</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-3 col-sm-6 mb-3">
                                    <div className="form-group select-style">
                                        <label className="d-block">Tarih Aralığı</label>
                                        <RangePicker
                                            format={'DD/MM/YYYY'}
                                            locale={locale}
                                            ranges={rangePresets}
                                            value={date_range}
                                            style={{ width: '100%' }}
                                            onChange={(value, dateString) => {
                                                setDateRange(value);
                                                setStartDate(dayjs(dateString.toString().split(',')[0], 'DD/MM/YYYY').format('YYYY-MM-DD'));
                                                setEndDate(dayjs(dateString.toString().split(',')[1], 'DD/MM/YYYY').format('YYYY-MM-DD'));
                                                setSendFilter(true);
                                            }
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6">
                                    <div className="form-group select-style">
                                        <label>Müşteri Türü</label>
                                        <Select className="form-control" defaultValue={customer_type_id} showSearch
                                            value={customer_type_id}
                                            placeholder="Müşteri Türünü Seçiniz"
                                            allowClear={true}
                                            onChange={e => { setCustomerTypeId(e); setFilter(true); }}
                                            optionFilterProp="children"
                                            filterOption={(input, option) => {
                                                return (
                                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                );

                                            }}
                                        >
                                            {customer_types.map((customer_type: Attribute) => {
                                                return (
                                                    <Option value={customer_type.id} title={customer_type.name}
                                                        key={customer_type.name}
                                                        id={customer_type.name}>{customer_type.name}</Option>
                                                )
                                            })}
                                        </Select>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6">
                                    <div className="form-group select-style">
                                        <label>İletişim Kaynağı</label>
                                        <Select className="form-control" defaultValue={communication_id} showSearch
                                            value={communication_id}
                                            placeholder="Müşteri Türünü Seçiniz"
                                            allowClear={true}
                                            onChange={e => { setCommunicationId(e); setFilter(true); }}
                                            optionFilterProp="children"
                                            filterOption={(input, option) => {
                                                return (
                                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                );

                                            }}
                                        >
                                            {communications.map((communication: Attribute) => {
                                                return (
                                                    <Option value={communication.id} title={communication.name}
                                                        key={communication.name}
                                                        id={communication.name}>{communication.name}</Option>
                                                )
                                            })}
                                        </Select>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-6">
                                    <div className="form-group select-style">
                                        <label>Görüşme Sonucu</label>
                                        <Select className="form-control" defaultValue={status_id} showSearch
                                            value={status_id}
                                            placeholder="Müşteri Türünü Seçiniz"
                                            allowClear={true}
                                            onChange={e => { setStatusId(e); setFilter(true); }}
                                            optionFilterProp="children"
                                            filterOption={(input, option) => {
                                                return (
                                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                );

                                            }}
                                        >
                                            {statuses.map((status: Attribute) => {
                                                return (
                                                    <Option value={status.id} title={status.name}
                                                        key={status.name}
                                                        id={status.name}>{status.name}</Option>
                                                )
                                            })}
                                        </Select>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6">
                                    <div className="form-group mb-3 select-style">
                                        <label>Yönlendirilen (ler)</label>
                                        <Select className="form-control" showSearch
                                            allowClear={true}
                                            onChange={e => { setSelectedAgents(e); setFilter(true); }}
                                            value={selected_agents}
                                            defaultValue={selected_agents}
                                            mode="multiple"
                                            placeholder="Seçiniz"
                                            optionFilterProp="children"
                                            filterOption={(input, option) => {
                                                return (
                                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                );

                                            }}
                                        >
                                            {users.map((users: User) => {
                                                return (
                                                    <Option value={users.id} title={users.first_name + ' ' + users.last_name}
                                                        key={users.first_name + ' ' + users.last_name}
                                                        id={users.first_name + ' ' + users.last_name}>{users.first_name + ' ' + users.last_name}</Option>
                                                )
                                            })}
                                        </Select>
                                    </div>
                                </div>

                            </div>
                            <div className="mt-3">
                                <div className="float-start">
                                    <button type="button" onClick={e => setClearFilterInputs(true)} className="btn btn-soft-light btn-sm" >Temizle</button>
                                </div>
                                <div className="float-end">
                                    <Link to="/asistant/calls/create" className="ms-2 btn btn-light btn-sm"><i className="bx bx-plus me-1"></i> Yeni Ekle</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="card">
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table mb-0 table-bordered table-hover">
                                    <thead className="table-light">
                                        <tr>
                                            <th>Toplam</th>
                                            <th>Fiyat <strong className="text-danger d-inline-flex"></strong></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th><strong>{reports?.total}</strong></th>
                                            <td scope="row">
                                                <NumericFormat
                                                    value={reports?.total.toFixed(2).toString().replace('.', ',')}
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    displayType="text"
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div> */}
                    <div className="card">
                        <div className="card-body">
                            <Table
                                className="table table-striped"
                                size="small"
                                columns={columns}
                                dataSource={data}
                                loading={loading}
                                scroll={{ y: '100%', x: true }}
                                rowKey={(record) => `${record.id}`}
                                onRow={(record: any) => {
                                    return {
                                        onDoubleClick: (event) => handleRowDoubleClick(record.id)
                                    };
                                }}
                                expandable={{
                                    expandedRowRender: (record) => {
                                        return (
                                            <>
                                                <div className="table-reponsive">
                                                    <table className={`table`}>
                                                        <thead>
                                                            <tr>
                                                                <th></th>
                                                                <th>Başlık</th>
                                                                <th>Açıklama</th>
                                                                <th>Tarih</th>
                                                                <th>Saat</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {record?.meta?.map((meta: any,index:number) => {
                                                                return (
                                                                    <tr key={meta.id}>
                                                                        <td>{index+1}</td>
                                                                        <td>{`${meta?.title}`}</td>
                                                                        <td>{`${meta?.definition=='0'?'':meta?.definition}`}</td>
                                                                        <td>{`${meta?.date}`}</td>
                                                                        <td>{`${meta?.time}`}</td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </>
                                        )
                                    },
                                    rowExpandable: (record:any) => record?.meta !== 'Not Expandable',
                                }}
                                pagination={pagination}
                                onChange={(val: any, filter: any, sorter: any, extra: any) => {
                                    setPagination({
                                        current: val.current,
                                        pageSize: val.pageSize,
                                        currentPageSize: val.currentPageSize
                                    });
                                    setSortReq(sorter);
                                    setSort(true);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={show} onHide={handleClose} onEntered={modalLoaded} size="lg" animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Görüşme Detayları - {full_name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <CallDetail id={show_id} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Kapat
                    </Button>
                    <Link className="btn btn-primary" to={`/asistant/calls/${meetID}/edit`}>
                        Güncelle
                    </Link>
                </Modal.Footer>
            </Modal>

        </Wrapper>
    )
}

export default Calls;