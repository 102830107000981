import { useEffect, useState } from 'react';
import axios from 'axios';
import DoughnutChartWithCard from '../chart/doughnutChartWithCard';

interface CustomerApplicationType {
    name: string;
    total: number;
}


const WatchScheduleActionCountChart = ({ start_date, end_date, dateTypeName, sendFilter }: any) => {

    const [watch_schedule_action_report, setWatchScheduleActionReport] = useState<any | null>([]);
    

    const [watchScheduleActionChartData, setWatchScheduleActionChartData] = useState({
        labels: [''],
        datasets: [
            {
                label: '# of Votes',
                data: [0],
                backgroundColor: [
                    'rgba(255, 204, 90, 0.85)',
                    'rgb(240, 77, 128)',
                    'rgb(105, 81, 206)',

                    'rgb(22, 218, 241)'
                ],
                borderColor: [
                    'rgba(255, 204, 90, 0.85)',
                    'rgb(240, 77, 128)',
                    'rgb(105, 81, 206)',
                    'rgb(22, 218, 241)'
                ],
                borderWidth: 1,
            },
        ],
    });

    useEffect(() => {
        (
            async () => {
                    axios.get(`dashboard/watch-schedule-action-report?filter_between[]=${start_date}&filter_between[]=${end_date}`)
                        .then(({ data }) => {
                            setWatchScheduleActionReport(data?.watch_schedule_action?.report);
                            const customerApplicationTotals: any = data?.watch_schedule_action?.report?.customer_application_type?.total?.map((item: CustomerApplicationType) => item.total);
                            const customerApplicationLabels: any = data?.watch_schedule_action?.report?.customer_application_type?.total?.map((item: CustomerApplicationType) => item.name.toString());
                            setWatchScheduleActionChartData(prevState => ({
                                labels: customerApplicationLabels,
                                datasets: [{
                                    ...prevState.datasets[0],
                                    data: customerApplicationTotals
                                }]
                            }));
                        });
            }
        )()
    }, [sendFilter]);

    
    return (
        <DoughnutChartWithCard data={watchScheduleActionChartData} count={watch_schedule_action_report?.total} dateTypeName={dateTypeName} text={'Nöbet Müşterileri'} url={'/asistant/watch-schedule'} />
    );
};

export default WatchScheduleActionCountChart;
