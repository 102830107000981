import React, { useEffect, useState } from 'react';
import axios from "axios";
import { Link,Navigate } from 'react-router-dom';
import { connect } from "react-redux";
import { User } from "../../models/user";
import { FiBell, FiSettings, FiMoon, FiSun, FiGrid } from "react-icons/fi";
import moment from 'moment';
import Cookies from 'js-cookie';
const themeMode = 'theme-mode';

const Header = (props: { user: User }) => {
 
    const [redirect, setRedirect] = useState(false);
    const [theme_mode, setThemeMode] = useState<'light' | 'dark'>('light');

    useEffect(() => {
        const storedThemeMode = localStorage.getItem(themeMode);
        if (storedThemeMode) {
            setThemeMode(storedThemeMode as 'light' | 'dark');
        } else {
            localStorage.setItem(themeMode, theme_mode);
        }
    }, []);

    useEffect(() => {
        localStorage.setItem(themeMode, theme_mode);
    }, [theme_mode]);

    const [menu_type, setMenuType] = useState<'lg' | 'sm' | any>('lg');
    const [menu_type_class, setMenuTypeClass] = useState<'' | 'sidebar-enable' | any>('');


    useEffect(() => {
        document.body.setAttribute('data-layout-mode', theme_mode);
        document.body.setAttribute('data-sidebar', theme_mode);
    }, [theme_mode])


    const toggleThemeMode = () => {
        setThemeMode(theme_mode === 'light' ? 'dark' : 'light');
    };

    useEffect(() => {
        document.body.setAttribute('data-sidebar-size', menu_type);
        document.body.setAttribute('class', menu_type_class);
    }, [menu_type, menu_type_class])

    const toggleMenuType = () => {
        if (window.innerWidth <= 768) {
            setMenuType('lg');
            setMenuTypeClass(menu_type_class === '' ? 'sidebar-enable' : '');
        } else {
            // setMenuType(menu_type === 'lg' ? 'sm' : 'lg');
            // setMenuTypeClass(menu_type_class === '' ? 'sidebar-enable' : '');
            const newMenuType = menu_type === 'lg' ? 'sm' : 'lg';
            const newMenuTypeClass = menu_type_class === '' ? 'sidebar-enable' : '';
    
            setMenuType(newMenuType);
            setMenuTypeClass(newMenuTypeClass);
            localStorage.setItem('menu_type', newMenuType);
            localStorage.setItem('menu_type_class', newMenuTypeClass);
        }
    };

    useEffect(() => {
        const storedMenuType = localStorage.getItem('menu_type');
        const storedMenuTypeClass = localStorage.getItem('menu_type_class');
    
        if (storedMenuType && storedMenuTypeClass) {
            setMenuType(storedMenuType);
            setMenuTypeClass(storedMenuTypeClass);
        }
    
        document.body.setAttribute('data-sidebar-size', menu_type);
        document.body.setAttribute('class', menu_type_class);
    }, [menu_type, menu_type_class])
    
    const logout = async () => {
        await axios.post('logout', {})
        .then(response => {
            setRedirect(true);
        });
    }

    const [onDutyPerson, setOnDutyPerson] = useState<string|null>('');
    const [hoursElapsed, setHoursElapsed] = useState<number>(0);

    useEffect(() => {
        const personCookie = Cookies.get('onDutyPerson');
        if (personCookie) {
            setOnDutyPerson(personCookie);
        }

        const calculateHoursElapsed = () => {
            const startOfDay = moment().startOf('day');
            const now = moment();
            const elapsed = now.diff(startOfDay, 'hours');
            setHoursElapsed(elapsed);
        };

        calculateHoursElapsed();
        const intervalId = setInterval(calculateHoursElapsed, 3600000); // Update every hour

        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, []);

    
    if (redirect) {
        return <Navigate to={'/login'} />;
    }
    return (
        <header id="page-topbar">
            <div className="navbar-header">
                <div className="d-flex">

                    <div className="navbar-brand-box">
                        <a href="/" className="logo logo-dark">
                            <span className="logo-sm">
                                <img src="/assets/images/logo-sm.svg" alt="" height="30" />
                            </span>
                            <span className="logo-lg">
                                <img src="/assets/images/logo-sm.svg" alt="" height="24" /> <span className="logo-txt">LLUPAR</span>
                            </span>
                        </a>

                        <a href="index.html" className="logo logo-light">
                            <span className="logo-sm">
                                <img src="/assets/images/logo-sm.svg" alt="" height="30" />
                            </span>
                            <span className="logo-lg">
                                <img src="/assets/images/logo-sm.svg" alt="" height="24" /> <span className="logo-txt">LLUPAR</span>
                            </span>
                        </a>
                    </div>

                    <button type="button" onClick={toggleMenuType} className="btn btn-sm px-3 font-size-16 header-item" id="vertical-menu-btn">
                        <i className="fa fa-fw fa-bars"></i>
                    </button>

                    <form className="app-search d-none d-lg-block">
                        <div className="position-relative">
                            <input type="text" className="form-control" placeholder="Arama kelimesi CTRL+K" />
                            <button className="btn btn-primary" type="button"><i className="bx bx-search-alt align-middle"></i></button>
                        </div>
                    </form>
                </div>

                <div className="d-flex">

                    <div className="dropdown d-inline-block d-lg-none ms-2">
                        <button type="button" className="btn header-item" id="page-header-search-dropdown"
                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i data-feather="search" className="icon-lg"></i>
                        </button>
                        <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                            aria-labelledby="page-header-search-dropdown">

                            <form className="p-3">
                                <div className="form-group m-0">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Ara ..." aria-label="Arama sonuçları" />

                                        <button className="btn btn-primary" type="submit"><i className="mdi mdi-magnify"></i></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="dropdown d-inline-block">
                        <button type="button" className="btn header-item" id="mode-setting-btn" onClick={toggleThemeMode}>
                            <FiMoon className="icon-lg layout-mode-dark" />
                            <FiSun className="icon-lg layout-mode-light" />
                        </button>
                    </div>

                    <div className="dropdown d-none d-lg-inline-block ms-1">
                        <button type="button" className="btn header-item"
                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <FiGrid className="icon-lg" />
                        </button>
                        <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end">
                            <div className="p-2">
                                <div className="row g-0">
                                    <div className="col">
                                        <a className="dropdown-icon-item" href="#">
                                            <img src="/assets/images/brands/github.png" alt="Github" />
                                            <span> </span>
                                        </a>
                                    </div>

                                </div>

                                <div className="row g-0">
                                    <div className="col">
                                        <a className="dropdown-icon-item" href="#">
                                            <img src="/assets/images/brands/dropbox.png" alt="dropbox" />
                                            <span> </span>
                                        </a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="dropdown d-inline-block">
                        <button type="button" className="btn header-item noti-icon position-relative" id="page-header-notifications-dropdown"
                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <FiBell className="icon-lg" />
                            <span className="badge bg-danger rounded-pill">1</span>
                        </button>
                        <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                            aria-labelledby="page-header-notifications-dropdown">
                            <div className="p-3">
                                <div className="row align-items-center">
                                    <div className="col">
                                        <h6 className="m-0"> Bildirimler </h6>
                                    </div>
                                    <div className="col-auto">
                                        <a href="#!" className="small text-reset text-decoration-underline"> Okunmayan (1)</a>
                                    </div>
                                </div>
                            </div>
                            <div data-simplebar style={{ maxHeight: '230px' }}>
                                <a href="#!" className="text-reset notification-item">
                                    <div className="d-flex">
                                        <div className="flex-shrink-0 avatar-sm me-3">
                                            <span className="avatar-title bg-primary rounded-circle font-size-16">
                                                <i className="bx bx-user"></i>
                                            </span>
                                        </div>
                                        <div className="flex-grow-1">
                                            <h6 className="mb-1">Günün nöbetçi danışmanı</h6>
                                            <div className="font-size-13 text-muted">
                                                <p className="mb-1"> {onDutyPerson ? `${onDutyPerson}` : ''} </p>
                                                <p className="mb-0"><i className="mdi mdi-clock-outline"></i> <span>  {`${hoursElapsed} saat önce`}</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </a>

                            </div>
                            <div className="p-2 border-top d-grid">
                                <a className="btn btn-sm btn-link font-size-14 text-center" href="javascript:void(0)">
                                    <i className="mdi mdi-arrow-right-circle me-1"></i> <span>Tümünü gör..</span>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="dropdown d-inline-block">
                        <button type="button" className="btn header-item right-bar-toggle me-2">
                            <FiSettings className="icon-lg" />
                        </button>
                    </div>

                    <div className="dropdown d-inline-block">
                        <button type="button" className="btn header-item bg-soft-light border-start border-end" id="page-header-user-dropdown"
                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <img className="rounded-circle header-profile-user" src="/assets/images/users/avatar-1.jpg"
                                alt="Header Avatar" />
                            <span className="d-none d-xl-inline-block ms-1 fw-medium">{props.user.name} </span>
                            <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                        </button>
                        <div className="dropdown-menu dropdown-menu-end">

                            <Link to="/" className="dropdown-item"><i className="mdi mdi-face-profile font-size-16 align-middle me-1"></i> Hesabım</Link>
                            <a className="dropdown-item" href="#"><i className="mdi mdi-lock font-size-16 align-middle me-1"></i> Ekranı kilitle</a>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item" onClick={logout} href={'javascript:;'}><i className="mdi mdi-logout font-size-16 align-middle me-1"></i> Çıkış</a>
                        </div>
                    </div>

                </div>
            </div>
        </header>
    );
}


export default connect(
    (state: { user: User }) => {
        return {
            user: state.user
        };
    }
)(Header);