import { useEffect, useState } from 'react';
import axios from 'axios';
import { Carousel, Tag } from 'antd';
import { WatchSchedules } from '../../../models/asistant/watchSchedule/watchSchedule';
import ProfilePhoto from '../../user/profilePhoto';
import WatchScheduleList from '../watchSchedule/list';
import moment from 'moment';
import Cookies from 'js-cookie';

const WatchSchedulesCarousel = () => {

    const [watchSchedules, setWatchSchedules] = useState([]);
    const startOfWeek = moment().startOf('isoWeek').format('YYYY-MM-DD');

    useEffect(() => {
        (
            async () => {
                const todayDate = moment().format('YYYY-MM-DD');
                const { data } = await axios.get(`asistant/watch-schedules?limit=50&page=1&filter_between[]=${startOfWeek}&filter_between[]=${todayDate}&&sort[by]=schedule_date&sort[order]=desc`);
                setWatchSchedules(data.watch_schedule.data.data);
                const todaysPerson: any = watchSchedules.find((schedule: WatchSchedules) => schedule.schedule_date === todayDate);

                if (todaysPerson) {
                    Cookies.set('onDutyPerson', `${todaysPerson?.user?.first_name} ${todaysPerson?.user?.last_name}`, { expires: 1 });
                }
            }
        )()
    }, []);

    return (
        <Carousel autoplay autoplaySpeed={15000} dots={false}>
            {watchSchedules.map((watchSchedules: WatchSchedules) => {
                let scheduleDate = moment(watchSchedules.schedule_date);
                let displayDate;

                if (scheduleDate.isSame(moment(), 'day')) {
                    displayDate = "Bugün";
                } else if (scheduleDate.isSame(moment().subtract(1, 'day'), 'day')) {
                    displayDate = "Dün";
                } else {
                    displayDate = scheduleDate.format('dddd');
                }

                return (
                    <>
                        <div className="card-header align-items-center d-flex">
                            <h4 className="card-title mb-0 flex-grow-1">Nöbetçi Danışman <Tag className="float-end" color={displayDate == 'Bugün' ? 'red' : 'yellow'}>{displayDate}</Tag></h4>
                        </div>
                        <div className="card-body">
                            <div className="d-flex align-items-center">
                                <div>
                                    <ProfilePhoto id={watchSchedules.user?.id} imgClass="avatar-lg rounded-circle img-thumbnail" />
                                </div>
                                <div className="flex-1 ms-3">
                                    <h5 className="font-size-15 mb-1"><a href="#" className="text-dark">{watchSchedules.user?.first_name} {watchSchedules.user?.last_name}</a></h5>
                                    <p className="text-muted mb-0">{watchSchedules.user?.category?.name}</p>
                                </div>
                            </div>
                            <div className="mt-3 pt-1 pb-3">
                                <WatchScheduleList user_id={watchSchedules.user?.id} meet_date={watchSchedules.schedule_date} />
                            </div>
                        </div>
                    </>
                )
            })}
        </Carousel>
    );
};

export default WatchSchedulesCarousel;
