import React from 'react';
import Chart from 'react-apexcharts';

const CallAreaChart: React.FC = () => {
    const series = [{
        name: 'Arama',
        data: [120,100, 111, 95, 89, 145, 102, 96, 91, 125]
    }];

    const options:any = {
        chart: {
            height: 350,
            type: 'area',
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
            categories: ['5 Haz','6 Haz', '7 Haz', '8 Haz', '9 Haz', '10 Haz', '11 Haz', '12 Haz', '13 Haz', '14 Haz'],
        },
    };

    return (
        <div>
            <Chart options={options} series={series} type="area" height={200} />
        </div>
    );
};

export default CallAreaChart;
