import React, { SyntheticEvent, useEffect, useState } from "react";
import { Link, Navigate, NavLink, useParams } from "react-router-dom";
import axios from "axios";

//Boostrap
import { Modal, Button } from "react-bootstrap";

import { Radio, Select, DatePicker, Checkbox, ConfigProvider, message } from 'antd';
import { NumericFormat, PatternFormat } from 'react-number-format';
import { animateScroll as scroll } from 'react-scroll'

import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import Geocode from "react-geocode";

import Wrapper from "../../../components/layout/wrapper";
import { User } from "../../../models/user";

import { PlusOutlined, InboxOutlined, DownloadOutlined } from "@ant-design/icons";

import { CustomerType } from "../../../models/attribute/customerType/customerType";
import { PhoneCode } from "../../../models/general/country/phoneCode";
import { IdType } from "../../../models/attribute/idType/idType";
import { LandOffice } from "../../../models/general/location/landOffice/landOffice";
import { Province } from "../../../models/general/location/province/province";
import { District } from "../../../models/general/location/district/district";
import { Neighbourhood } from "../../../models/general/location/neighbourhood/neighbourhood";
import { PropertyGenus } from "../../../models/attribute/propertyGenus/propertyGenus";
import { PropertyType } from "../../../models/attribute/propertyType/propertyType";
import { FloorLocation } from "../../../models/attribute/floorLocation/floorLocation";
import { BuildType } from "../../../models/attribute/buildType/buildType";
import { Site } from "../../../models/general/site/site";
import { GiroType } from "../../../models/attribute/giroType/giroType";
import { Realtor } from "../../../models/general/realtor/realtor";
import { PaymentMethod } from "../../../models/attribute/paymentMethod/paymentMethod";

import { SourceOfFind } from "../../../models/attribute/sourceOfFind/sourceOfFind";
import { SourceOfSell } from "../../../models/attribute/sourceOfSell/sourceOfSell";
import moment from 'moment';
import dayjs from 'dayjs';
import { Country } from "../../../models/general/country/country";
import { useUserContext } from "../../../providers/user/userProvider";
import { useIdTypeContext } from "../../../providers/attribute/idType/idTypeProvider";
import { useCustomerTypeContext } from "../../../providers/attribute/customerType/customerTypeProvider";
import { useCountryContext } from "../../../providers/general/location/country/countryProvider";
import { usePaymentMethodContext } from "../../../providers/attribute/paymentMethod/paymentMethodProvider";
import { useCommentStatusContext } from "../../../providers/attribute/commentStatus/commentStatusProvider";
import { useResourceOfSellContext } from "../../../providers/attribute/sourceOfSell/sourceOfSellProvider";
import { useResourcesOfFindContext } from "../../../providers/attribute/sourceOfFind/sourceOfFindProvider";
import { useLandOfficeContext } from "../../../providers/general/location/landOffice/landOfficeProvider";
import { useProvinceContext } from "../../../providers/general/location/province/provinceProvider";
import { useBuildTypeContext } from "../../../providers/attribute/buildType/buildTypeProvider";
import { Attribute } from "../../../models/attribute/attribute";
import { usePropertyGenusContext } from "../../../providers/attribute/propertyGenus/propertyGenusProvider";
const { Option } = Select;

Geocode.setApiKey("AIzaSyA4ZrsurXwR2QD4Xx5-CpHPD4N3qvurBMo");

const Edit = () => {

    //Edit
    const { id } = useParams();
    const [transaction, setTransaction] = useState<any | null>(null);

    // General
    const [disable_button, setDisableButton] = useState(false);
    const [save_button_text, setSaveButtonText] = useState('Güncelle');
    const [ret_agents, setRetAgents] = useState<any | null>([]);

    // Seller
    const [seller_customer_type_id, setSellerCustomerTypeId] = useState<any | null>(null);
    const [seller_company_name, setSellerCompanyName] = useState('');
    const [seller_tax_admin, setSellerTaxAdmin] = useState('');
    const [seller_tax_number, setSellerTaxNumber] = useState('');
    const [seller_phone_code_id, setSellerPhoneCodeId] = useState<any | null>(null);
    const [seller_first_name, setSellerFirstName] = useState<any | null>('');
    const [seller_last_name, setSellerLastName] = useState<any | null>('');
    const [seller_nationality_id, setSellerNationalityId] = useState(34);
    const [seller_id_type_id, setSellerIdTypeId] = useState<any | null>(null);
    const [seller_id_no, setSellerIdNo] = useState('');
    const [seller_birthday, setSellerBirthday] = useState('');
    const [seller_email, setSellerEmail] = useState('');
    const [seller_address, setSellerAddress] = useState('');
    const [seller_phone, setSellerPhone] = useState('');
    const [seller_note, setSellerNote] = useState('');

    // Buyer
    const [buyer_customer_type_id, setBuyerCustomerTypeId] = useState<any | null>(null);
    const [buyer_company_name, setBuyerCompanyName] = useState('');
    const [buyer_tax_admin, setBuyerTaxAdmin] = useState('');
    const [buyer_tax_number, setBuyerTaxNumber] = useState('');
    const [buyer_phone_code_id, setBuyerPhoneCodeId] = useState<any | null>(null);
    const [buyer_first_name, setBuyerFirstName] = useState('');
    const [buyer_last_name, setBuyerLastName] = useState('');
    const [buyer_nationality_id, setBuyerNationalityId] = useState(34);
    const [buyer_id_type_id, setBuyerIdTypeId] = useState<any | null>(null);
    const [buyer_id_no, setBuyerIdNo] = useState('');
    const [buyer_birthday, setBuyerBirthday] = useState('');
    const [buyer_email, setBuyerEmail] = useState('');
    const [buyer_address, setBuyerAddress] = useState('');
    const [buyer_phone, setBuyerPhone] = useState('');
    const [buyer_note, setBuyerNote] = useState('');

    //Other
    const [seller_note_see, setSellerNoteSee] = useState(false);
    const [buyer_note_see, setBuyerNoteSee] = useState(false);
    const [property_note_see, setPropertyNoteSee] = useState(false);
    const [out_office_see, setOutOfficeSee] = useState(false);
    const [calc_status, setCalcStatus] = useState(false);
    const [ref_calc_status, setRefCalcStatus] = useState(false);
    const [calc_status_for_agent, setCalcStatusForAgent] = useState(false);
    const [selected_agent_reload, setSelectedAgentReload] = useState(false);
    const [agents_total_service_fee, setAgentsTotalServiceFee] = useState(0);


    const [agent_price_status, setAgentPriceStatus] = useState([]);
    const MAX_LIMIT = 100;

    const [other_realtor_show, setOtherRealtorShow] = useState(false);

    //Property
    const [province_id, setProvinceId] = useState<any | null>();
    const [province_name, setProvinceName] = useState('');
    const [district_id, setDistrictId] = useState<any | null>();
    const [district_name, setDistrictName] = useState('');
    const [neighbourhood_id, setNeighbourhoodId] = useState<any | null>();
    const [neighbourhood_name, setNeighbourhoodName] = useState('');

    const [bob, setBob] = useState<any | null>(null);
    const [parcel, setParcel] = useState<any | null>(null);
    const [gross, setGross] = useState<any | null>(null);
    const [net, setNet] = useState<any | null>(null);
    const [land_office_id, setLandOfficeId] = useState(1);
    const [deed_date, setDeedDate] = useState('');
    const [property_address, setPropertyAddress] = useState('');
    const [build_date, setBuildDate] = useState<any | null>(null);
    const [property_note, setPropertyNote] = useState('');
    const [site_search, setSiteSearch] = useState('');
    const [site_id, setSiteId] = useState<any | null>(null);
    const [property_genus_id, setPropertyGenusId] = useState<any | null>();
    const [property_type_id, setPropertyTypeId] = useState<any | null>();
    const [floor_location_id, setFloorLocationId] = useState();
    const [build_type_id, setBuildTypeId] = useState(114);

    //Transaction
    const [contract_date, setContractDate] = useState('');
    const [sale_price, setSalePrice] = useState<any | null>(0);
    const [fair_price, setFairPrice] = useState<any | null>(0);
    const [title_deed_cost, setTitleDeedCost] = useState<any | null>(0);
    const [seller_service_fee, setSellerServiceFee] = useState<any | null>(0);
    const [buyer_service_fee, setBuyerServiceFee] = useState<any | null>(0.0);
    const [out_office_referral_fee, setOutOfficeReferralFee] = useState<any | null>(0);
    const [office_referral_fee, setOfficeReferralFee] = useState<any | null>(0);
    const [total_service_fee, setTotalServiceFee] = useState<any | null>(0);
    const [giro_type_id, setGiroTypeId] = useState(116);
    const [realtor_id, setRealtorId] = useState<any | null>(null);
    const [realtor_search, setRealtorSearch] = useState('');
    const [total_service_fee_for_agent, setTotalServiceFeeForAgent] = useState<any | null>(null);
    const [source_of_sell_id, setSourceOfSellId] = useState<any | null>(null);
    const [source_of_find_id, setSourceOfFindId] = useState<any | null>(null);
    const [payment_method, setPaymentMethod] = useState();
    const [comment_status, setCommentStatus] = useState();
    const [selected_agent, setSelectedAgent] = useState<any | null>(null);
    const [total_service_fee_agent, setTotalServiceFeeAgent] = useState<any | null>(null);
    const [agent_percent, setAgentPercent] = useState<any | null>(null);
    const [agent_service_fee, setAgentServiceFee] = useState<any | null>(0);
    const [agent_service_fee_for_send, setAgentServiceFeeForSend] = useState<any | null>(0);
    const [team_leader_percent, setTeamLeaderPercent] = useState<any | null>(null);
    const [team_leader_service_fee, setTeamLeaderServiceFee] = useState<any | null>(null);
    const [office_service_fee, setOfficeServiceFee] = useState<any | null>(null);
    const [download_word, setDownloadWord] = useState<any | null>(true);


    // Realtor
    const [realtor_first_name, setRealtorFirstName] = useState('');
    const [realtor_last_name, setRealtorLastName] = useState('');
    const [realtor_company_name, setRealtorCompanyName] = useState('');
    const [realtor_email, setRealtorEmail] = useState('');
    const [realtor_phone_code_id, setRealtorPhoneCodeId] = useState<any | null>();
    const [realtor_phone, setRealtorPhone] = useState('');
    const [realtor_note, setRealtorNote] = useState('');

    //Site
    const [site_name, setSiteName] = useState('');
    const [site_address, setSiteAddress] = useState('');
    const [total_house, setTotalHouse] = useState(0);
    const [manager_name, setManagerName] = useState('');
    const [mobile_phone, setMobilePhone] = useState('');
    const [office_phone, setOfficePhone] = useState('');
    const [site_note, setSiteNote] = useState('');

    //Calculate
    useEffect(() => {
        let totalServiceFee = Math.round((parseFloat(seller_service_fee) + parseFloat(buyer_service_fee)) - (parseFloat(office_referral_fee) + parseFloat(out_office_referral_fee))).toFixed(2).toString().replace(/[.]/g, '').replace(',', '.');
        if (giro_type_id === 117) {
            totalServiceFee = Math.round((parseFloat(seller_service_fee)) - (parseFloat(office_referral_fee) + parseFloat(out_office_referral_fee))).toFixed(2).toString().replace('.', ',');
        }
        if (agent_price_status.length > 0) {
            setTotalServiceFeeForAgent((parseFloat(totalServiceFee.replace(',', '.')) / parseInt(agent_price_status.length.toString())).toFixed(2));
        } else {
            setTotalServiceFeeForAgent(totalServiceFee);
        }
        setTotalServiceFee(totalServiceFee);
        if (giro_type_id === 116) {
            setSellerServiceFee((parseFloat(sale_price.toString().replace(/[.]/g, '').replace(',', '.')) * 0.02 * 1.20).toFixed(2).toString().replace('.', ','));
            setBuyerServiceFee((parseFloat(sale_price.toString().replace(/[.]/g, '').replace(',', '.')) * 0.02 * 1.20).toFixed(2).toString().replace('.', ','));
            setTitleDeedCost((parseFloat(fair_price.toString().replace(/[.]/g, '').replace(',', '.')) * 0.04 + 1298.50).toFixed(2).toString().replace('.', ','));
        }
        if (giro_type_id === 117) {
            setSellerServiceFee((parseFloat(sale_price.toString().replace(/[.]/g, '').replace(',', '.')) * 1.20).toFixed(2).toString().replace('.', ','));
        }
        setTimeout(() => {
            setCalcStatusForAgent(true);
        }, 500);
        setCalcStatus(false);

    }, [calc_status]);

    useEffect(() => {
        let totalServiceFee = Math.round((parseFloat(seller_service_fee.toString().replace(/[.]/g, '').replace(',', '.')) + parseFloat(buyer_service_fee.toString().replace(/[.]/g, '').replace(',', '.'))) - (parseFloat(office_referral_fee) + parseFloat(out_office_referral_fee.toString().replace(/[.]/g, '').replace(',', '.'))));
        if (giro_type_id === 117) {
            totalServiceFee = Math.round((parseFloat(seller_service_fee.toString().replace(/[.]/g, '').replace(',', '.'))) - (parseFloat(office_referral_fee) + parseFloat(out_office_referral_fee))).toFixed(2).toString().replace('.', ',');
        }
        if (agent_price_status.length > 0) {
            setTotalServiceFeeForAgent((parseFloat(totalServiceFee.toString().replace(/[.]/g, '').replace(',', '.')) / parseInt(agent_price_status.length.toString())).toFixed(2));
        } else {
            setTotalServiceFeeForAgent(totalServiceFee);
        }
        setTotalServiceFee(totalServiceFee);
        setRefCalcStatus(false);

    }, [ref_calc_status]);

    useEffect(() => {
        let totalServiceFee = (parseFloat(seller_service_fee) + parseFloat(buyer_service_fee) - (parseFloat(office_referral_fee.toString().replace(/[.]/g, '').replace(',', '.')) + parseFloat(out_office_referral_fee.toString().replace(/[.]/g, '').replace(',', '.')))).toFixed(2).toString().replace('.', ',');
        if (giro_type_id === 117) {
            totalServiceFee = (parseFloat(seller_service_fee) - (parseFloat(office_referral_fee.toString().replace(/[.]/g, '').replace(',', '.')) + parseFloat(out_office_referral_fee.toString().replace(/[.]/g, '').replace(',', '.')))).toFixed(2).toString().replace('.', ',');
        }
        if (agent_price_status.length > 0) {
            setTotalServiceFeeForAgent((parseFloat(totalServiceFee) / parseInt(agent_price_status.length.toString())).toFixed(2));
        } else {
            setTotalServiceFeeForAgent(totalServiceFee);
        }

        setAgentServiceFee((parseFloat(total_service_fee.toString().replace(/[.]/g, '').replace(',', '.')) * (parseFloat(agent_percent) / 100)).toFixed(2));
        if (transaction?.agent.team_leader_id > 0) {
            setTeamLeaderServiceFee((parseFloat(total_service_fee.toString().replace(/[.]/g, '').replace(',', '.')) * (parseFloat(team_leader_percent) / 100)).toFixed(2));
            setOfficeServiceFee((parseFloat(total_service_fee.toString().replace(/[.]/g, '').replace(',', '.')) - (parseFloat(team_leader_service_fee.toString().replace(',', '.')) + parseFloat(agent_service_fee.toString().replace(',', '.')))).toFixed(2));
        } else {
            setOfficeServiceFee((parseFloat(total_service_fee.toString().replace(/[.]/g, '').replace(',', '.')) - parseFloat(agent_service_fee)).toFixed(2));
        }
        setCalcStatusForAgent(false);
    }, [calc_status_for_agent]);


    const [selected_agents, setSelectedAgents] = useState([]);

    const [redirect, setRedirect] = useState(false);

    //const [customer_types, setCustomerTypes] = useState([]);
    //const [nationalities, setNationalities] = useState([]);
    //const [phone_codes, setPhoneCodes] = useState([]);
    //const [id_types, setIdTypes] = useState([]);
    //const [land_offices, setLandOffices] = useState([]);
    //const [provinces, setProvinces] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [neighbourhoods, setNeighbourhoods] = useState([]);
    //const [property_genuses, setPropertyGenuses] = useState([]);
    const [property_types, setPropertyTypes] = useState([]);
    const [floor_locations, setFloorLocations] = useState([]);
    //const [build_types, setBuildTypes] = useState([]);
    const [sites, setSites] = useState([]);
    const [realtors, setRealtors] = useState([]);
    //const [payment_methods, setPaymentMethods] = useState([]);
    //const [comment_statuses, setCommentStatuses] = useState([]);
    //const [resources_of_sell, setResourcesOfSell] = useState([]);
    //const [resources_of_find, setResourcesOfFind] = useState([]);

    const [giro_types, setGiroTypes] = useState([]);

    const [users, setUsers] = useUserContext();
    const [id_types, setIdTypes] = useIdTypeContext();
    const [customer_types, setCustomerTypes] = useCustomerTypeContext();
    const [phone_codes, setPhoneCodes] = useCountryContext();
    const [nationalities, setNationalities] = useCountryContext();
    const [payment_methods, setPaymentMethods] = usePaymentMethodContext();
    const [comment_statuses, setCommentStatuses] = useCommentStatusContext();
    const [resources_of_sell, setResourcesOfSell] = useResourceOfSellContext();
    const [resources_of_find, setResourcesOfFind] = useResourcesOfFindContext();
    const [land_offices, setLandOffices] = useLandOfficeContext();
    const [provinces, setProvinces] = useProvinceContext();
    const [build_types, setBuildTypes] = useBuildTypeContext();
    const [property_genuses, setPropertyGenuses] = usePropertyGenusContext();

    const [coordinates, setCoordinates] = useState<any | null>({ lat: 36.87524849186124, lng: 30.64364152042535 });
    const [property_coordinate, setPropertyCoordinate] = useState<any | null>('36.87524849186124,30.64364152042535');

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get(`transactions/${id}`);
                setTransaction(data);

                //Seller
                setSellerCustomerTypeId(data?.seller.customer_type_id);
                setSellerCompanyName(data?.seller.company_name !== '0' ? data?.seller.company_name : '');
                setSellerTaxAdmin(data?.seller.tax_admin !== '0' ? data?.seller.tax_admin : '');
                setSellerTaxNumber(data?.seller.tax_number !== '0' ? data?.seller.tax_number : '');
                setSellerFirstName(data?.seller.first_name);
                setSellerLastName(data?.seller.last_name);
                setSellerNationalityId(data?.seller.nationality_id);
                setSellerIdTypeId(data?.seller.idType.id);
                setSellerIdNo(data?.seller.id_no);
                setSellerPhoneCodeId(data?.seller.phone_code_id);
                setSellerPhone(data?.seller.phone);
                setSellerEmail(data?.seller.email !== '0' ? data?.seller.email : '');
                setSellerBirthday(data?.seller.birthday);
                setSellerAddress(data?.seller.address);
                (data?.seller.definition !== null && data?.seller.definition !== undefined && data?.seller.definition !== '0') ? setSellerNoteSee(true) : setSellerNoteSee(false);
                setSellerNote(data?.seller.definition);

                //Buyer
                setBuyerCustomerTypeId(data?.buyer.customer_type_id);
                setBuyerCompanyName(data?.buyer.company_name !== '0' ? data?.buyer.company_name : '');
                setBuyerTaxAdmin(data?.buyer.tax_admin !== '0' ? data?.buyer.tax_admin : '');
                setBuyerTaxNumber(data?.buyer.tax_number !== '0' ? data?.buyer.tax_number : '');
                setBuyerFirstName(data?.buyer.first_name);
                setBuyerLastName(data?.buyer.last_name);
                setBuyerNationalityId(data?.buyer.nationality_id);
                setBuyerIdTypeId(data?.buyer.idType.id);
                setBuyerIdNo(data?.buyer.id_no);
                setBuyerPhoneCodeId(data?.buyer.phone_code_id);
                setBuyerPhone(data?.buyer.phone);
                setBuyerEmail(data?.buyer.email !== '0' ? data?.buyer.email : '');
                setBuyerBirthday(data?.buyer.birthday);
                setBuyerAddress(data?.buyer.address);
                (data?.buyer.definition !== null && data?.buyer.definition !== undefined && data?.buyer.definition !== '0') ? setBuyerNoteSee(true) : setBuyerNoteSee(false);
                setBuyerNote(data?.buyer.definition);

                //Property
                if (data && data.property) {
                    setProvinceId(data?.property.province_id);
                    setDistrictId(data?.property.district_id);
                    setNeighbourhoodId(data?.property?.neighbourhood_id);
                    setBob(data?.property.bob);
                    setParcel(data?.property.parcel);
                    setGross(data?.property.gross);
                    setNet(data?.property.net);
                    setLandOfficeId(data?.property.land_office_id);
                    setDeedDate(data?.property.deed_date);
                    setPropertyAddress(data?.property.address);
                    setBuildDate(data?.property.build_date !== '0' ? data?.property.build_date : '0000');

                    setPropertyNote(data?.property.definition);
                    (data?.property.definition !== null && data?.property.definition !== undefined && data?.property.definition !== '0') ? setPropertyNoteSee(true) : setPropertyNoteSee(false);
                    setSiteId(data.property.site && data.property.site.id);
                    setPropertyGenusId(data?.property.propertyGenus.id);
                    setPropertyTypeId(data.property.propertyType && data.property.propertyType.id);
                    setFloorLocationId(data.property.floorLocation && data.property.floorLocation.id);
                    setBuildTypeId(data?.property.buildType.id);

                    let splitCoordinate = data.property.coordinate.split(',');
                    setCoordinates({ lat: splitCoordinate[0], lng: splitCoordinate[1] });
                    console.log({ lat: splitCoordinate[0], lng: splitCoordinate[1] });
                    setPropertyCoordinate(data.property.coordinate);
                }

                //Transaction
                setGiroTypeId(data?.giro_type.id);
                setContractDate(data?.contract_date);
                setSalePrice(data?.sale_price);
                setFairPrice(data?.fair_price);
                setTitleDeedCost(data?.title_deed_cost);
                setSellerServiceFee(data?.seller_service_fee);
                setBuyerServiceFee(data?.buyer_service_fee);
                (data?.other_realtor_id !== null && data?.other_realtor_id !== undefined && data?.other_realtor_id !== 0) ? setOutOfficeSee(true) : setOutOfficeSee(false);
                setOutOfficeReferralFee(data?.out_office_referral_fee);
                setOfficeReferralFee(data?.office_referral_fee);
                setTotalServiceFee(data?.total_service_fee);
                if (data?.other_realtor_id !== 0) { setRealtorId(data?.other_realtor_id); }
                setSourceOfSellId(data?.source_of_sell_id);
                setSourceOfFindId(data?.source_of_find_id);
                if (Array.isArray(data?.payment_methods)) {
                    const paymentMethodIds = data?.payment_methods.map((pm: { meta_id: any; }) => pm.meta_id);
                    setPaymentMethod(paymentMethodIds);
                }
                if (Array.isArray(data?.comment_statuses)) {
                    const commentStatusIds = data?.comment_statuses.map((cs: { meta_id: any; }) => cs.meta_id);
                    setCommentStatus(commentStatusIds);
                }
                setAgentPercent(data?.agent_percent);
                setAgentServiceFee(data?.agent_service_fee);
                setTeamLeaderPercent(data?.team_leader_percent);
                setTeamLeaderServiceFee(data?.team_leader_service_fee);

                setOfficeServiceFee(data?.office_service_fee);
            }
        )()
    }, [id]);

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('users');

                setUsers(data.data);
            }

        )()
    }, []);


    // useEffect(() => {
    //     (
    //         async () => {
    //             const { data } = await axios.get('attributes/11');

    //             setGiroTypes(data.data);
    //         }
    //     )()
    // }, []);

    // useEffect(() => {
    //     (
    //         async () => {
    //             const { data } = await axios.get('attributes/5');

    //             setCustomerTypes(data.data);
    //         }
    //     )()
    // }, []);

    // useEffect(() => {
    //     (
    //         async () => {
    //             const { data } = await axios.get('general/countries');

    //             setPhoneCodes(data);
    //         }
    //     )()
    // }, []);

    // useEffect(() => {
    //     (
    //         async () => {
    //             const { data } = await axios.get('general/countries');

    //             setPhoneCodes(data);
    //             setNationalities(data);
    //         }
    //     )()
    // }, []);


    // useEffect(() => {
    //     (
    //         async () => {
    //             const { data } = await axios.get('attributes/6');

    //             setIdTypes(data.data);
    //         }
    //     )()
    // }, []);

    // useEffect(() => {
    //     (
    //         async () => {
    //             const { data } = await axios.get('location/land-offices');

    //             setLandOffices(data);
    //         }
    //     )()
    // }, []);

    // useEffect(() => {
    //     (
    //         async () => {
    //             const { data } = await axios.get('location/provinces');

    //             setProvinces(data);
    //         }
    //     )()
    // }, []);

    // useEffect(() => {
    //     (
    //         async () => {

    //             const { data } = await axios.get('location/district/' + province_id);

    //             setDistricts(data);
    //         }
    //     )()
    // }, [province_id]);

    // useEffect(() => {

    //     (
    //         async () => {
    //             if (typeof province_name === 'string' && province_name.trim().length > 0) {
    //                 const { data } = await axios.get('location/province/name-like/' + province_name);
    //                 if (data.id > 0) { setDistrictId(null); setNeighbourhoodId(null); }
    //                 setProvinceId(data.id);
    //             }
    //         }
    //     )()

    // }, [province_name])


    // useEffect(() => {
    //     (
    //         async () => {
    //             const { data } = await axios.get('location/neighbourhood/' + district_id);

    //             setNeighbourhoods(data);
    //         }
    //     )()
    // }, [district_id]);

    // useEffect(() => {
    //     setTimeout(() => {

    //         (
    //             async () => {
    //                 if (typeof province_name === 'string' && province_name.trim().length > 0) {

    //                     const { data } = await axios.get(`location/district/name-like/${district_name}/${province_id}`);
    //                     setDistrictId(data.id);
    //                 }
    //             }
    //         )()
    //     }, 1000);

    // }, [district_name, province_id])

    // useEffect(() => {
    //     (
    //         async () => {
    //             const { data } = await axios.get('attributes/11');

    //             setGiroTypes(data.data);
    //         }
    //     )()
    // }, []);

    // useEffect(() => {
    //     (
    //         async () => {
    //             const { data } = await axios.get('attributes/5');

    //             setCustomerTypes(data.data);
    //         }
    //     )()
    // }, []);

    // useEffect(() => {
    //     (
    //         async () => {
    //             const { data } = await axios.get('general/countries');

    //             setPhoneCodes(data);
    //         }
    //     )()
    // }, []);

    // useEffect(() => {
    //     (
    //         async () => {
    //             const { data } = await axios.get('attributes/6');

    //             setIdTypes(data.data);
    //         }
    //     )()
    // }, []);

    // useEffect(() => {
    //     (
    //         async () => {
    //             const { data } = await axios.get('location/land-offices');

    //             setLandOffices(data);
    //         }
    //     )()
    // }, []);

    // useEffect(() => {
    //     (
    //         async () => {
    //             const { data } = await axios.get('location/provinces');

    //             setProvinces(data);
    //         }
    //     )()
    // }, []);

    useEffect(() => {
        (
            async () => {

                const { data } = await axios.get('location/district/' + province_id);

                setDistricts(data);
            }
        )()
    }, [province_id]);

    useEffect(() => {

        (
            async () => {
                if (typeof province_name === 'string' && province_name.trim().length > 0) {
                    const { data } = await axios.get('location/province/name-like/' + province_name);
                    if (data.id > 0) { setDistrictId(null); setNeighbourhoodId(null); }
                    setProvinceId(data.id);
                }
            }
        )()

    }, [province_name])


    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('location/neighbourhood/' + district_id);

                setNeighbourhoods(data);
            }
        )()
    }, [district_id]);

    useEffect(() => {
        setTimeout(() => {

            (
                async () => {
                    if (typeof province_name === 'string' && province_name.trim().length > 0) {

                        const { data } = await axios.get(`location/district/name-like/${district_name}/${province_id}`);
                        setDistrictId(data.id);
                    }
                }
            )()
        }, 1000);

    }, [district_name, province_id])

    useEffect(() => {
        setTimeout(() => {

            (
                async () => {
                    if (typeof province_name === 'string' && province_name.trim().length > 0) {

                        const { data } = await axios.get(`location/neighbourhood/name-like/${neighbourhood_name}/${district_id}`);
                        setNeighbourhoodId(data.id);
                    }
                }
            )()
        }, 1000);

    }, [neighbourhood_name, district_id])


    // useEffect(() => {
    //     (
    //         async () => {
    //             const { data } = await axios.get('attributes/7');

    //             setPropertyGenuses(data.data);
    //         }
    //     )()
    // }, []);

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('attributes/8/' + property_genus_id);

                setPropertyTypes(data.data);
            }
        )()
    }, [property_genus_id]);

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('attributes/9');

                setFloorLocations(data.data);
            }
        )()
    }, []);

    // useEffect(() => {
    //     (
    //         async () => {
    //             const { data } = await axios.get('attributes/10');

    //             setBuildTypes(data.data);
    //         }
    //     )()
    // }, []);

    useEffect(() => {
        (
            async () => {
                if (site_search.length > 0) {
                    const { data } = await axios.get('sites/search/' + site_search);
                    setSites(data.data);
                } else {
                    const { data } = await axios.get('sites');
                    setSites(data.data);
                }
            }
        )()
    }, [site_search]);

    useEffect(() => {
        (
            async () => {
                if (realtor_search.length > 0) {
                    const { data } = await axios.get('realtors/search/' + realtor_search);
                    setRealtors(data.data);
                } else {
                    const { data } = await axios.get('realtors');
                    setRealtors(data);
                }
            }
        )()
    }, [realtor_search]);



    // useEffect(() => {
    //     (
    //         async () => {
    //             const { data } = await axios.get('attributes/13');

    //             setPaymentMethods(data.data);
    //         }
    //     )()
    // }, []);


    // useEffect(() => {
    //     (
    //         async () => {
    //             const { data } = await axios.get('attributes/14');

    //             setCommentStatuses(data.data);
    //         }
    //     )()
    // }, []);

    // useEffect(() => {
    //     (
    //         async () => {
    //             const { data } = await axios.get('attributes/15');

    //             setResourcesOfFind(data.data);
    //         }
    //     )()
    // }, []);

    // useEffect(() => {
    //     (
    //         async () => {
    //             const { data } = await axios.get('attributes/16');

    //             setResourcesOfSell(data.data);
    //         }
    //     )()
    // }, []);


    const customizeRenderEmpty = () => (
        <div style={{ textAlign: 'center' }}>
            <InboxOutlined style={{ fontSize: 40 }} />
            <p>Emlakçı Bulunamadı!</p>
            <Button variant="primary" onClick={handleShow}>Yeni Ekle</Button>

        </div>
    );

    const customizeRenderEmptyForSite = () => (
        <div style={{ textAlign: 'center' }}>
            <InboxOutlined style={{ fontSize: 40 }} />
            <p>Site Bulunamadı!</p>
            <Button variant="primary" onClick={handleSiteShow}>Yeni Ekle</Button>

        </div>
    );


    //Modals
    //Add Realtor Modal
    const [show_realtor, setShowRealtor] = useState(false);
    const handleClose = () => setShowRealtor(false);

    const handleShow = (e: any) => {
        (
            () => {
                setShowRealtor(true);
            }
        )()
    };

    //Add Site Modal
    const [show_site_modal, setShowSiteModal] = useState(false);
    const handleSiteClose = () => setShowSiteModal(false);

    const handleSiteShow = (e: any) => {
        (
            () => {
                setShowSiteModal(true);
            }
        )()
    };

    //Downlaod Modal
    const [show_download, setShowDownload] = useState(false);
    const handleDownloadClose = () => { setShowDownload(false); setRedirect(true); }

    const handleDownloadShow = (e: any) => {
        (
            () => {
                setShowDownload(true);
            }
        )()
    };

    //Google Maps Geo For Site
    const [site_coordinates, setSiteCoordinates] = useState('');

    useEffect(() => {


        Geocode.fromAddress(site_address).then(
            (response: any) => {
                const { lat, lng } = response.results[0].geometry.location;
                setSiteCoordinates(`${lat},${lng}`)
            },
            (error: any) => {
                //console.error(error);
            }
        );
    }, [site_address]);

    // Google maps  geoFor Property


    useEffect(() => {
        Geocode.fromAddress(property_address).then(
            (response: any) => {
                const { lat, lng } = response.results[0].geometry.location;
                setCoordinates({ lat: lat, lng: lng })
                setPropertyCoordinate(`${lat},${lng}`)

                let administrativeAreaLevel1;

                for (const result of response.results) {
                    for (const component of result.address_components) {
                        if (component.types.includes('administrative_area_level_1')) {
                            administrativeAreaLevel1 = component.long_name;
                            break;
                        }
                    }
                }
                let administrativeAreaLevel2;

                for (const result of response.results) {
                    for (const component of result.address_components) {
                        if (component.types.includes('administrative_area_level_2')) {
                            administrativeAreaLevel2 = component.long_name;
                            break;
                        }
                    }
                }
                let administrativeAreaLevel3;

                for (const result of response.results) {
                    for (const component of result.address_components) {
                        if (component.types.includes('administrative_area_level_3')) {
                            administrativeAreaLevel3 = component.long_name;
                            break;
                        }
                    }
                }

                let administrativeAreaLevel4;
                for (const result of response.results) {
                    for (const component of result.address_components) {
                        if (component.types.includes('administrative_area_level_4')) {
                            administrativeAreaLevel4 = component.long_name;
                            break;
                        }
                    }
                }

                let administrativeAreaLevelNeighbourhood;
                for (const result of response.results) {
                    for (const component of result.address_components) {
                        if (component.types.includes('neighborhood')) {
                            administrativeAreaLevelNeighbourhood = component.long_name;
                            break;
                        }
                    }
                }

                setProvinceName(administrativeAreaLevel1);
                setDistrictName(administrativeAreaLevel2);
                if (administrativeAreaLevelNeighbourhood != null && administrativeAreaLevelNeighbourhood != '' && administrativeAreaLevelNeighbourhood.trim().length > 0) {
                    setNeighbourhoodName(administrativeAreaLevelNeighbourhood);
                } else if (administrativeAreaLevel3 != null && administrativeAreaLevel3 != '' && administrativeAreaLevel3.trim().length > 0) {
                    setNeighbourhoodName(administrativeAreaLevel3);
                } else if (administrativeAreaLevel4 != null && administrativeAreaLevel4 != '' && administrativeAreaLevel4.trim().length > 0) {
                    setNeighbourhoodName(administrativeAreaLevel4);
                }

            },
            (error: any) => {
                //console.error(error);
            }
        );
    }, [property_address]);

    const containerStyle = {
        width: '100%',
        height: '400px'
    };
    const center = {
        lat: 36.87524849186124,
        lng: 30.64364152042535
    };
    const position = {
        lat: 36.87524849186124,
        lng: 30.64364152042535
    }




    function withEvent(func: Function): React.ChangeEventHandler<any> {
        return (event: React.ChangeEvent<any>) => {
            const { target } = event;
            func(target.value);
        };
    }



    //Save operations 
    //Realtor
    const realtorSubmit = async (e: SyntheticEvent) => {
        e.preventDefault();

        const new_realtor_id = await axios.post('realtors', {
            first_name: realtor_first_name,
            last_name: realtor_last_name,
            company_name: realtor_company_name,
            email: realtor_email,
            phone_code_id: realtor_phone_code_id,
            phone: realtor_phone,
            definition: realtor_note,

        });
        setRealtorSearch(`${realtor_first_name} ${realtor_last_name}`);
        setRealtorId(new_realtor_id);
        setShowRealtor(false);
    }

    //Site
    const siteSubmit = async (e: SyntheticEvent) => {
        e.preventDefault();

        const new_site_id = await axios.post('sites', {
            name: site_name,
            address: site_address,
            manager_name: manager_name,
            office_phone: office_phone,
            mobile_phone: mobile_phone,
            coordinate: site_coordinates,
            definition: site_note,
            house_count: total_house,

        });
        setSiteSearch(`${site_name}`);
        setSiteId(new_site_id);
        setShowSiteModal(false);
    }

    //General
    interface FormState {
        error: { [key: string]: any | null };
        inputRefs: { [key: string]: any | React.RefObject<HTMLInputElement> };
    }

    const [formState, setFormState] = useState<any | null>({ error: {}, inputRefs: {} });
    const [messageApi, contextHolder] = message.useMessage();

    const submit = async (e: SyntheticEvent) => {
        e.preventDefault();
        let errorFields: { [key: string | number]: { name: string, key: any, error: string | null, label: string } } = {};
        let hasError = false;
        const requiredInputs: any = [
            { key: 'seller_first_name', name: 'Satıcı adı ' },
            { key: 'seller_last_name', name: 'Satıcı soyadı' },
            { key: 'seller_phone', name: 'Satıcı telefon numarası' },
            { key: 'seller_id_no', name: 'Satıcı kimlik numarası' },
            { key: 'seller_address', name: 'Satıcı adresi' },

            { key: 'buyer_first_name', name: 'Alıcı adı ' },
            { key: 'buyer_last_name', name: 'Alıcı soyadı' },
            { key: 'buyer_phone', name: 'Alıcı telefon numarası' },
            { key: 'buyer_id_no', name: 'Alıcı kimlik numarası' },
            { key: 'buyer_address', name: 'Alıcı adresi' },

            { key: 'property_address', name: 'Portföy adresi ' },
            { key: 'province_id', name: 'Portföy ili' },
            { key: 'district_id', name: 'Portföy ilçesi' },
            { key: 'neighbourhood_id', name: 'Portföy mahallesi' },

            { key: 'contract_date', name: 'Sözleşme tarihi' },
            { key: 'source_of_find_id', name: 'Portföy bulma kaynağı' },
            { key: 'source_of_sell_id', name: 'Portföy satma kaynağı' },
            { key: 'sale_price', name: 'Satış bedeli' },
            { key: 'seller_service_fee', name: 'Satıcı hizmet bedeli' },
        ];

        requiredInputs.forEach((input: { key: any; name: any; }) => {
            const el = (e.currentTarget as HTMLFormElement).elements[input.key] as HTMLInputElement;


            if (el && !el.value) {
                errorFields[input.key] = { key: input.key, label: input.name, name: input.name, error: "Bu alan zorunlu!" };
                messageApi.open({
                    type: 'error',
                    content: input.name + ' zorunludur. Lütfen doldurunuz.',
                });
                hasError = true;
            }

        });

        if (hasError) {
            if (source_of_find_id === 0 || source_of_find_id === null) {
                errorFields['source_of_find_id'] = { key: 'source_of_find_id', name: 'source_of_find_id', label: 'Portföy bulma kaynağı zorunludur.', error: "Bu alan zorunlu!" };
                messageApi.open({
                    type: 'error',
                    content: 'Portföy bulma kaynağı zorunludur.. Lütfen doldurunuz.',
                });
            }
            if (source_of_sell_id === 0 || source_of_sell_id === null) {
                errorFields['source_of_sell_id'] = { key: 'source_of_sell_id', name: 'source_of_sell_id', label: 'Portföy bulma kaynağı zorunludur.', error: "Bu alan zorunlu!" };
                messageApi.open({
                    type: 'error',
                    content: 'Portföy bulma kaynağı zorunludur.. Lütfen doldurunuz.',
                });
            }

            if (district_id === 0 || district_id === null) {
                errorFields['district_id'] = { key: 'district_id', name: 'district_id', label: 'İlçe seçimi zorunludur.', error: "Bu alan zorunlu!" };
                messageApi.open({
                    type: 'error',
                    content: 'İlçe seçimi zorunludur. Lütfen doldurunuz.',
                });
            }

            if (neighbourhood_id === 0 || neighbourhood_id === null) {
                errorFields['neighbourhood_id'] = { key: 'neighbourhood_id', name: 'neighbourhood_id', label: 'Mahalle seçimi zorunludur.', error: "Bu alan zorunlu!" };
                messageApi.open({
                    type: 'error',
                    content: 'Mahalle seçimi zorunludur. Lütfen doldurunuz.',
                });
            }

            setFormState({ error: errorFields, inputRefs: formState.inputRefs });

            return;
        }

        if (hasError) {


            messageApi.open({
                type: 'error',
                content: 'Lütfen zorunlu alanları doldurun!',
            });

            const firstErrorInput = Object.keys(errorFields).find((key) => errorFields[key] !== null);
            if (firstErrorInput && formState.inputRefs[firstErrorInput] && formState.inputRefs[firstErrorInput].current) {
                formState.inputRefs[firstErrorInput.toString()].current.focus();
                scroll.scrollTo(formState.inputRefs[firstErrorInput].current.offsetTop, {
                    duration: 500,
                    smooth: "easeInOutQuint"
                });
            }
            console.log(firstErrorInput);

        } else {
            setFormState({ error: {}, inputRefs: formState.inputRefs });

            setDisableButton(true);
            setSaveButtonText('Bekleyiniz...');
            //  setAgentServiceFee(agent_service_fee.toString().replace('.','.'));
            const res = await axios.put(`transactions/${id}`, {
                seller_customer_type_id,
                seller_company_name,
                seller_tax_admin,
                seller_tax_number,
                seller_first_name,
                seller_last_name,
                seller_email,
                seller_phone_code_id,
                seller_phone,
                seller_nationality_id,
                seller_id_type_id,
                seller_id_no,
                seller_birthday,
                seller_address,
                seller_note,

                buyer_customer_type_id,
                buyer_company_name,
                buyer_tax_admin,
                buyer_tax_number,
                buyer_first_name,
                buyer_last_name,
                buyer_email,
                buyer_phone_code_id,
                buyer_phone,
                buyer_nationality_id,
                buyer_id_type_id,
                buyer_id_no,
                buyer_birthday,
                buyer_address,
                buyer_note,

                bob,
                parcel,
                land_office_id,
                deed_date,
                property_address,
                province_id,
                district_id,
                neighbourhood_id,
                property_genus_id,
                property_type_id,
                floor_location_id,
                build_date,
                gross,
                net,
                build_type_id,
                site_id,
                property_note,
                property_coordinate,

                source_of_find_id,
                source_of_sell_id,
                sale_price,
                fair_price,
                title_deed_cost,
                seller_service_fee,
                buyer_service_fee,
                office_referral_fee,
                realtor_id,
                out_office_referral_fee,
                total_service_fee,
                agent_percent,
                agent_service_fee,
                team_leader_percent,
                team_leader_service_fee,
                office_service_fee,
                payment_method,
                comment_status,
                contract_date,
                download_word
            }).then(response => {
                console.log(response);
                if (response?.data.length > 0) {
                    setRetAgents(response.data);
                    (download_word) ? setShowDownload(true) : setRedirect(true)
                }
            })
                .catch(error => {
                    messageApi.open({
                        type: 'error',
                        content: 'İşlem sırasında bir hata gerçekleşti! Hata mesajı: ' + error,
                    });

                    if (source_of_find_id === 0 || source_of_find_id === null) {
                        errorFields['source_of_find_id'] = { key: 'source_of_find_id', name: 'source_of_find_id', label: 'Portföy bulma kaynağı zorunludur.', error: "Bu alan zorunlu!" };
                        messageApi.open({
                            type: 'error',
                            content: 'Portföy bulma kaynağı zorunludur.. Lütfen doldurunuz.',
                        });
                    }
                    if (source_of_sell_id === 0 || source_of_sell_id === null) {
                        errorFields['source_of_sell_id'] = { key: 'source_of_sell_id', name: 'source_of_sell_id', label: 'Portföy bulma kaynağı zorunludur.', error: "Bu alan zorunlu!" };
                        messageApi.open({
                            type: 'error',
                            content: 'Portföy bulma kaynağı zorunludur.. Lütfen doldurunuz.',
                        });
                    }

                    if (district_id === 0 || district_id === null) {
                        errorFields['district_id'] = { key: 'district_id', name: 'district_id', label: 'İlçe seçimi zorunludur.', error: "Bu alan zorunlu!" };
                        messageApi.open({
                            type: 'error',
                            content: 'İlçe seçimi zorunludur. Lütfen doldurunuz.',
                        });
                    }

                    if (neighbourhood_id === 0 || neighbourhood_id === null) {
                        errorFields['neighbourhood_id'] = { key: 'neighbourhood_id', name: 'neighbourhood_id', label: 'Mahalle seçimi zorunludur.', error: "Bu alan zorunlu!" };
                        messageApi.open({
                            type: 'error',
                            content: 'Mahalle seçimi zorunludur.. Lütfen doldurunuz.',
                        });
                    }

                    setFormState({ error: errorFields, inputRefs: formState.inputRefs });

                    const firstErrorInput = Object.keys(errorFields).find((key) => errorFields[key] !== null);
                    if (firstErrorInput && formState.inputRefs[firstErrorInput] && formState.inputRefs[firstErrorInput].current) {
                        formState.inputRefs[firstErrorInput.toString()].current.focus();
                        scroll.scrollTo(formState.inputRefs[firstErrorInput].current.offsetTop, {
                            duration: 500,
                            smooth: "easeInOutQuint"
                        });
                    }
                });
        }
        setSaveButtonText('Güncelle');
        setDisableButton(false);
    }

    if (redirect) {
        return <Navigate to={'/accounting/transactions'} />;
    }


    return (
        <Wrapper>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0 font-size-18">İşlem Güncelle</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><NavLink to={'/'}>Gösterge Paneli</NavLink></li>
                                <li className="breadcrumb-item"><NavLink to={'/accounting/transactions'}>İşlemler</NavLink></li>
                                <li className="breadcrumb-item active">Güncelle</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <form onSubmit={submit}>
                        <div className="card">
                            <div className="card-body p-4">
                                <div className="row align-items-end">
                                    <div className="col-12 col-lg-6">
                                        <div className="d-flex align-items-end mt-0 mt-lg-0">
                                            <div className="flex-shrink-0">
                                                <div className="avatar-xl me-3">
                                                    <img src="https://ps.w.org/user-avatar-reloaded/assets/icon-256x256.png?rev=2540745" alt="" style={{ width: 100 }} className="img-fluid rounded-circle d-block img-thumbnail" />
                                                </div>
                                            </div>
                                            <div className="flex-grow-1">
                                                <div>
                                                    <h5 className="font-size-16 mb-1">{transaction?.agent.first_name} {transaction?.agent.last_name}</h5>
                                                    <p className="text-muted font-size-13 mb-1 pb-0"><strong>İşlem Türü : </strong> {transaction?.giro_type.name}</p>
                                                    <p className="text-muted font-size-13 mb-1 pb-0"><strong>Ortak Sayısı : </strong> {transaction?.partner_count}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6">
                                        <div className="mt-2 mt-lg-0">
                                            <p className="text-muted font-size-13 mb-1"><strong>İşlem Tarihi : </strong> {transaction?.created_at}</p>
                                            <p className="text-muted font-size-13 mb-1 pb-0"><strong>Ekleyen / Güncelleyen Personel : </strong> {transaction?.user.first_name} {transaction?.user.last_name}</p>
                                            <p className="text-muted font-size-13 mb-1 pb-0"><strong>IP : </strong> {transaction?.ip}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Satıcı/Kiralayan Bilgileri */}
                        <div className="card">
                            <div className="card-body p-4">
                                <div>
                                    <div className="row">
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>{giro_type_id === 116 ? 'Satıcı Bilgileri' : 'Kiralayan Bilgileri'}</label>
                                                <div>
                                                    <Radio.Group defaultValue={seller_customer_type_id} value={seller_customer_type_id} onChange={e => setSellerCustomerTypeId(e.target.value)}>
                                                        {customer_types?.map((customer_types: CustomerType) => {
                                                            return (
                                                                <Radio value={customer_types.id} >{customer_types.name}</Radio>
                                                            )
                                                        })}
                                                    </Radio.Group>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-6" style={seller_customer_type_id == 2 ? {} : { display: 'none' }} >
                                            <div className="form-group mb-3">
                                                <label>Firma Adı</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir firma adı giriniz."
                                                    className="form-control"
                                                    value={seller_company_name}
                                                    onChange={e => setSellerCompanyName(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3" style={seller_customer_type_id == 2 ? {} : { display: 'none' }} >
                                            <div className="form-group mb-3">
                                                <label>Vergi Dairesi</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir vergi dairesi giriniz."
                                                    className="form-control"
                                                    value={seller_tax_admin}
                                                    onChange={e => setSellerTaxAdmin(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3" style={seller_customer_type_id == 2 ? {} : { display: 'none' }} >
                                            <div className="form-group mb-3">
                                                <label>Vergi Numarası</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir vergi numarası giriniz."
                                                    className="form-control"
                                                    value={seller_tax_number}
                                                    onChange={e => setSellerTaxNumber(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Ad</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir isim giriniz."
                                                    name="seller_first_name"
                                                    className="form-control"
                                                    required
                                                    value={seller_first_name}
                                                    onChange={e => setSellerFirstName(e.target.value)}
                                                />
                                                {formState.error.seller_first_name && <p className="text-danger">{formState.error.seller_first_name.error}</p>}

                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Soyad</label>
                                                <input type="text" name="seller_last_name" data-pristine-required-message="Lütfen bir soyisim giriniz."
                                                    className="form-control"
                                                    required
                                                    value={seller_last_name}
                                                    onChange={e => setSellerLastName(e.target.value)}
                                                />
                                                {formState.error.seller_last_name && <p className="text-danger">{formState.error.seller_last_name.error}</p>}
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3 select-style">
                                                <label>Kimlik Tipi</label>
                                                <Select className="form-control" defaultValue={seller_id_type_id} value={seller_id_type_id} showSearch
                                                    optionFilterProp="children"
                                                    onChange={e => setSellerIdTypeId(e)}
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >

                                                    {id_types?.map((id_types: IdType) => {
                                                        return (
                                                            <Option value={id_types.id} title={id_types.name}
                                                                key={id_types.name}
                                                                id={id_types.name}>{id_types.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Kimlik Numarası</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir kimlik numarası giriniz."
                                                    className="form-control"
                                                    name="seller_id_no"
                                                    value={seller_id_no}
                                                    required
                                                    onChange={e => setSellerIdNo(e.target.value)}
                                                />
                                                {formState.error.seller_id_no && <p className="text-danger">{formState.error.seller_id_no.error}</p>}
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Telefon</label>
                                                <div className="input-group">
                                                    <div className="input-group-text p-0">
                                                        <Select value={seller_phone_code_id} style={{ width: 100 }} showSearch
                                                            optionFilterProp="children"
                                                            onChange={e => setSellerPhoneCodeId(e)}
                                                            filterOption={(input, option) => {
                                                                return (
                                                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                );

                                                            }}
                                                        >

                                                            {phone_codes?.map((phone_codes: PhoneCode) => {
                                                                let img_url = `https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/6.6.6/flags/4x3/${phone_codes.code}.svg`;
                                                                return (
                                                                    <Option value={phone_codes.id} title={phone_codes.name}
                                                                        key={phone_codes.name}
                                                                        id={phone_codes.phone_code + ' ' + phone_codes.name}><img src={img_url} height={16} /> +{phone_codes.phone_code}<br /><small className="text-black-50"> {phone_codes.name}</small></Option>
                                                                )
                                                            })}
                                                        </Select>

                                                    </div>
                                                    <PatternFormat format="(###) ### ## ##" value={seller_phone} defaultValue={seller_phone} name="seller_phone" required className="form-control" data-pristine-required-message="Lütfen bir telefon numarası giriniz." allowEmptyFormatting mask=" " onChange={e => setSellerPhone(e.target.value)} />
                                                    {formState.error.seller_phone && <p className="text-danger">{formState.error.seller_phone?.error}</p>}
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Email</label>
                                                <input type="email" data-pristine-required-message="Lütfen bir email adresi giriniz."
                                                    className="form-control"
                                                    value={seller_email}
                                                    onChange={e => setSellerEmail(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3 select-style">
                                                <label>Uyruk</label>
                                                <Select className="form-control" defaultValue={seller_nationality_id} showSearch
                                                    optionFilterProp="children"
                                                    onChange={e => setSellerNationalityId(e)}
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >

                                                    {nationalities.map((nationalities: Country) => {
                                                        return (
                                                            <Option value={nationalities.id} title={nationalities.name}
                                                                key={nationalities.name}
                                                                id={nationalities.name}>{nationalities.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>                                        
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Doğum Tarihi</label>
                                                <input type="date" data-pristine-required-message="Lütfen doğum tarihi seçiniz."
                                                    className="form-control"
                                                    value={moment(seller_birthday, "DD/MM/YYYY").format("YYYY-MM-DD")}
                                                    onChange={e => setSellerBirthday(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Adres</label>
                                                <input type="text" data-pristine-required-message="Lütfen adres giriniz."
                                                    className="form-control"
                                                    name="seller_address"
                                                    value={seller_address}
                                                    required
                                                    onChange={e => setSellerAddress(e.target.value)}
                                                />
                                                {formState.error.seller_address && <p className="text-danger">{formState.error.seller_address.error}</p>}
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <button type="button" onClick={e => { seller_note_see ? setSellerNoteSee(false) : setSellerNoteSee(true); }} className="btn btn-link text-start" >Not Ekle <PlusOutlined className="font-size-16 align-middle me-2" /></button>
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12" style={seller_note_see ? {} : { display: 'none' }}>
                                            <div className="form-group mb-3">
                                                <label>Not</label>
                                                <textarea
                                                    className="form-control"
                                                    value={seller_note}
                                                    onChange={e => setSellerNote(e.target.value)}>
                                                    {seller_note}
                                                </textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Alıcı/Kiracı Bilgileri */}
                        <div className="card">
                            <div className="card-body p-4">
                                <div>
                                    <div className="row">
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>{giro_type_id === 116 ? 'Alıcı Bilgileri' : 'Kiracı Bilgileri'}</label>
                                                <div>
                                                    <Radio.Group defaultValue={buyer_customer_type_id} value={buyer_customer_type_id} onChange={e => setBuyerCustomerTypeId(e.target.value)}>
                                                        {customer_types?.map((customer_types: CustomerType) => {
                                                            return (
                                                                <Radio value={customer_types.id} >{customer_types.name}</Radio>
                                                            )
                                                        })}
                                                    </Radio.Group>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-md-6" style={buyer_customer_type_id == 2 ? {} : { display: 'none' }} >
                                            <div className="form-group mb-3">
                                                <label>Firma Adı</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir firma adı giriniz."
                                                    className="form-control"
                                                    value={buyer_company_name}
                                                    onChange={e => setBuyerCompanyName(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3" style={buyer_customer_type_id == 2 ? {} : { display: 'none' }} >
                                            <div className="form-group mb-3">
                                                <label>Vergi Dairesi</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir vergi dairesi giriniz."
                                                    className="form-control"
                                                    value={buyer_tax_admin}
                                                    onChange={e => setBuyerTaxAdmin(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3" style={buyer_customer_type_id == 2 ? {} : { display: 'none' }} >
                                            <div className="form-group mb-3">
                                                <label>Vergi Numarası</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir vergi numarası giriniz."
                                                    className="form-control"
                                                    value={buyer_tax_number}
                                                    onChange={e => setBuyerTaxNumber(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Ad</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir isim giriniz."
                                                    className="form-control"
                                                    name="buyer_first_name"
                                                    required
                                                    value={buyer_first_name}
                                                    onChange={e => setBuyerFirstName(e.target.value)}
                                                />
                                                {formState.error.buyer_first_name && <p className="text-danger">{formState.error.buyer_first_name.error}</p>}
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Soyad</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir soyisim giriniz."
                                                    className="form-control"
                                                    name="buyer_last_name"
                                                    required
                                                    value={buyer_last_name}
                                                    onChange={e => setBuyerLastName(e.target.value)}
                                                />
                                                {formState.error.buyer_last_name && <p className="text-danger">{formState.error.buyer_last_name.error}</p>}
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3 select-style">
                                                <label>Kimlik Tipi</label>
                                                <Select className="form-control" defaultValue={buyer_id_type_id} value={buyer_id_type_id} showSearch
                                                    onChange={e => setBuyerIdTypeId(e)}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >

                                                    {id_types?.map((id_types: IdType) => {
                                                        return (
                                                            <Option value={id_types.id} title={id_types.name}
                                                                key={id_types.name}
                                                                id={id_types.name}>{id_types.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Kimlik Numarası</label>
                                                <input type="text" data-pristine-required-message="Lütfen bir kimlik numarası giriniz."
                                                    className="form-control"
                                                    name="buyer_id_no"
                                                    required
                                                    value={buyer_id_no}
                                                    onChange={e => setBuyerIdNo(e.target.value)}
                                                />
                                                {formState.error.buyer_id_no && <p className="text-danger">{formState.error.buyer_id_no.error}</p>}
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Telefon</label>
                                                <div className="input-group">
                                                    <div className="input-group-text p-0">
                                                        <Select defaultValue={buyer_phone_code_id} value={buyer_phone_code_id} style={{ width: 100 }} showSearch
                                                            optionFilterProp="children"
                                                            onChange={e => setBuyerPhoneCodeId(e)}
                                                            filterOption={(input, option) => {
                                                                return (
                                                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                );

                                                            }}
                                                        >

                                                            {phone_codes?.map((phone_codes: PhoneCode) => {
                                                                let img_url = `https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/6.6.6/flags/4x3/${phone_codes.code}.svg`;
                                                                return (
                                                                    <Option value={phone_codes.id} title={phone_codes.name}
                                                                        key={phone_codes.name}
                                                                        id={phone_codes.phone_code + ' ' + phone_codes.name}><img src={img_url} height={16} /> +{phone_codes.phone_code}<br /><small className="text-black-50"> {phone_codes.name}</small></Option>
                                                                )
                                                            })}
                                                        </Select>

                                                    </div>
                                                    <PatternFormat format="(###) ### ## ##" value={buyer_phone} name="buyer_phone" required onChange={e => setBuyerPhone(e.target.value)} className="form-control" data-pristine-required-message="Lütfen bir telefon numarası giriniz." allowEmptyFormatting mask=" " />
                                                    {formState.error.buyer_phone && <p className="text-danger">{formState.error.buyer_phone?.error}</p>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Email</label>
                                                <input type="email" data-pristine-required-message="Lütfen bir email adresi giriniz."
                                                    className="form-control"
                                                    value={buyer_email}
                                                    onChange={e => setBuyerEmail(e.target.value)}
                                                />
                                            </div>
                                        </div>                                       
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3 select-style">
                                                <label>Uyruk</label>
                                                <Select className="form-control" defaultValue={buyer_nationality_id} showSearch
                                                    optionFilterProp="children"
                                                    onChange={e => setBuyerNationalityId(e)}
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >

                                                    {nationalities.map((nationalities: Country) => {
                                                        return (
                                                            <Option value={nationalities.id} title={nationalities.name}
                                                                key={nationalities.name}
                                                                id={nationalities.name}>{nationalities.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>                                      
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Doğum Tarihi</label>
                                                <input type="date" data-pristine-required-message="Lütfen doğum tarihi seçiniz."
                                                    className="form-control"
                                                    value={moment(buyer_birthday, "DD/MM/YYYY").format("YYYY-MM-DD")}
                                                    onChange={e => setBuyerBirthday(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Adres</label>
                                                <input type="text" data-pristine-required-message="Lütfen adres giriniz."
                                                    className="form-control"
                                                    name="buyer_address"
                                                    required
                                                    value={buyer_address}
                                                    onChange={e => setBuyerAddress(e.target.value)}
                                                />
                                                {formState.error.buyer_address && <p className="text-danger">{formState.error.buyer_address.error}</p>}
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <button type="button" onClick={e => { buyer_note_see ? setBuyerNoteSee(false) : setBuyerNoteSee(true); }} className="btn btn-link text-start" >Not Ekle <PlusOutlined className="font-size-16 align-middle me-2" /></button>
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12" style={buyer_note_see ? {} : { display: 'none' }}>
                                            <div className="form-group mb-3">
                                                <label>Not</label>
                                                <textarea
                                                    className="form-control"
                                                    value={buyer_note}
                                                    onChange={e => setBuyerNote(e.target.value)}>{buyer_note}</textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Mülk Bilgileri */}
                        <div className="card">
                            <div className="card-body p-4">
                                <div>
                                    <div className="row">
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Mülk Bilgileri</label>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Ada</label>
                                                <input type="number" data-pristine-required-message="Lütfen ada giriniz."
                                                    className="form-control"
                                                    onChange={e => setBob(e.target.value)}
                                                    value={bob}
                                                    defaultValue={bob}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3">
                                                <label>Parsel</label>
                                                <input type="number" data-pristine-required-message="Lütfen parsel giriniz."
                                                    className="form-control"
                                                    value={parcel}
                                                    onChange={e => setParcel(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3" style={giro_type_id == 116 ? {} : { display: 'none' }}>
                                            <div className="form-group mb-3 select-style">
                                                <label>Tapu Dairesi</label>
                                                <Select className="form-control" defaultValue={land_office_id} showSearch
                                                    optionFilterProp="children"
                                                    onChange={e => setLandOfficeId(e)}
                                                    value={land_office_id}
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >

                                                    {land_offices?.map((land_offices: LandOffice) => {
                                                        return (
                                                            <Option value={land_offices.id} title={land_offices.name}
                                                                key={land_offices.name}
                                                                id={land_offices.name}>{land_offices.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3" style={giro_type_id == 116 ? {} : { display: 'none' }}>
                                            <div className="form-group mb-3">
                                                <label>Tapu Tarihi</label>
                                                <input type="date" data-pristine-required-message="Lütfen tapu tarihini giriniz."
                                                    className="form-control"
                                                    onChange={e => setDeedDate(moment(e.target.value, "YYYY-MM-DD").format("DD/MM/YYYY"))}
                                                    defaultValue={moment(deed_date, "DD/MM/YYYY").format("YYYY-MM-DD")}
                                                    value={moment(deed_date, "DD/MM/YYYY").format("YYYY-MM-DD")}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Adres</label>
                                                <input type="text" name="property_address" data-pristine-required-message="Lütfen adres giriniz."
                                                    className="form-control"
                                                    required
                                                    defaultValue={property_address}
                                                    onBlur={e => setPropertyAddress(e.target.value)}
                                                />
                                                {formState.error.property_address && <p className="text-danger">{formState.error.property_address.error}</p>}
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Harita Konumu </label>
                                                <LoadScript
                                                    googleMapsApiKey="AIzaSyA4ZrsurXwR2QD4Xx5-CpHPD4N3qvurBMo"
                                                >
                                                    <GoogleMap
                                                        mapContainerStyle={containerStyle}
                                                        center={coordinates}
                                                        zoom={15}
                                                    >
                                                        <Marker
                                                            position={coordinates}
                                                            draggable={true}
                                                            onDragEnd={e => {
                                                                if (e.latLng && e.latLng) {
                                                                    setCoordinates({ lat: e.latLng.lat(), lng: e.latLng.lng() });
                                                                    setPropertyCoordinate(`${e.latLng.lat()},${e.latLng.lng()}`);
                                                                }
                                                            }}
                                                        />
                                                    </GoogleMap>
                                                </LoadScript>
                                            </div>
                                        </div>

                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3 select-style">
                                                <label>İl</label>
                                                <Select className="form-control" defaultValue={province_id} value={province_id} showSearch
                                                    optionFilterProp="children"
                                                    placeholder="İl Seçiniz"
                                                    onChange={e => { setProvinceId(e); setDistrictId(null); setNeighbourhoodId(null); }}
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >

                                                    {provinces?.map((provinces: Province) => {
                                                        return (
                                                            <Option value={provinces.id} title={provinces.name}
                                                                key={provinces.name}
                                                                id={provinces.name}>{provinces.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                                {formState.error.province_id && <p className="text-danger">{formState.error.province_id.error}</p>}
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3 select-style">
                                                <label>İlçe</label>
                                                <Select className="form-control" defaultValue={district_id} showSearch
                                                    optionFilterProp="children"
                                                    onChange={e => { setDistrictId(e); setNeighbourhoodId(null); }}
                                                    value={district_id}
                                                    placeholder="İlçe Seçiniz"
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >

                                                    {districts.map((districts: District) => {
                                                        return (
                                                            <Option value={districts.id} title={districts.name}
                                                                key={districts.name}
                                                                id={districts.name}>{districts.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                                {formState.error.district_id && <p className="text-danger">{formState.error.district_id.error}</p>}
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3 select-style">
                                                <label>Mahalle</label>
                                                <Select className="form-control" defaultValue={neighbourhood_id} showSearch
                                                    value={neighbourhood_id}
                                                    onChange={e => setNeighbourhoodId(e)}
                                                    optionFilterProp="children"
                                                    placeholder="Mahalle Seçiniz"
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >

                                                    {neighbourhoods.map((neighbourhoods: Neighbourhood) => {
                                                        return (
                                                            <Option value={neighbourhoods.id} title={neighbourhoods.name}
                                                                key={neighbourhoods.name}
                                                                id={neighbourhoods.name}>{neighbourhoods.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                                {formState.error.neighbourhood_id && <p className="text-danger">{formState.error.neighbourhood_id.error}</p>}
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3 select-style">
                                                <label>Gayrimenkul Cinsi</label>
                                                <Select className="form-control" defaultValue={property_genus_id} showSearch
                                                    value={property_genus_id}
                                                    optionFilterProp="children"
                                                    onChange={e => { setPropertyGenusId(e); setPropertyTypeId(null); }}
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >
                                                    {property_genuses?.map((property_genuses: Attribute) => {
                                                        return (
                                                            <Option value={property_genuses.id} title={property_genuses.name}
                                                                key={property_genuses.name}
                                                                id={property_genuses.name}>{property_genuses.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2" style={property_genus_id == 10 ? { display: 'none' } : {}} >
                                            <div className="form-group mb-3 select-style">
                                                <label>Gayrimenkul Türü</label>
                                                <Select className="form-control" defaultValue={property_type_id} showSearch
                                                    value={property_type_id}
                                                    placeholder="Gayrimenkul Türü Seçiniz"
                                                    onChange={e => setPropertyTypeId(e)}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >

                                                    {property_types.map((property_types: PropertyType) => {
                                                        return (
                                                            <Option value={property_types.id} title={property_types.name}
                                                                key={property_types.name}
                                                                id={property_types.name}>{property_types.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2" style={property_genus_id == 8 || property_genus_id == 10 ? { display: 'none' } : {}}>
                                            <div className="form-group mb-3 select-style">
                                                <label>Bulunduğu Kat</label>
                                                <Select className="form-control" defaultValue={floor_location_id} showSearch
                                                    value={floor_location_id}
                                                    placeholder="Bulunduğu Katı Seçiniz"
                                                    onChange={e => setFloorLocationId(e)}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >

                                                    {floor_locations.map((floor_locations: FloorLocation) => {
                                                        return (
                                                            <Option value={floor_locations.id} title={floor_locations.name}
                                                                key={floor_locations.name}
                                                                id={floor_locations.name}>{floor_locations.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>

                                        <div className="col-xl-2 col-md-2" style={property_genus_id == 8 || property_genus_id == 10 ? { display: 'none' } : {}}>
                                            <div className="form-group mb-3 date-style">
                                                <label>Bina Yaşı </label>
                                                <DatePicker className="form-control" value={dayjs(build_date + '-01-01', 'YYYY-MM-DD')} placeholder="Bina Yaşını Seçiniz" picker="year" format={'YYYY'} onChange={(e, dateStr) => { setBuildDate(dateStr); console.log(e); }} />
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3">
                                                <label>Brüt m<sup>2</sup></label>
                                                <input type="number" data-pristine-required-message="Lütfen brüt alanı giriniz."
                                                    className="form-control"
                                                    value={gross}
                                                    onChange={e => setGross(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2" style={property_genus_id == 8 || property_genus_id == 10 ? { display: 'none' } : {}}>
                                            <div className="form-group mb-3">
                                                <label>Net m<sup>2</sup></label>
                                                <input type="number" data-pristine-required-message="Lütfen net alanı giriniz."
                                                    className="form-control"
                                                    value={net}
                                                    onChange={e => setNet(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3" style={property_genus_id == 8 || property_genus_id == 10 ? { display: 'none' } : {}}>
                                            <div className="form-group mb-3">
                                                <label>Yapı Türü</label>
                                                <div>
                                                    <Radio.Group defaultValue={build_type_id} value={build_type_id} onChange={e => setBuildTypeId(e.target.value)}>
                                                        {build_types?.map((build_types: BuildType) => {
                                                            return (
                                                                <Radio value={build_types.id} >{build_types.name}</Radio>
                                                            )
                                                        })}
                                                    </Radio.Group>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3" style={property_genus_id == 8 || property_genus_id == 10 || build_type_id != 115 ? { display: 'none' } : {}} >
                                            <div className="form-group mb-3 select-style">
                                                <label>Site</label>
                                                <ConfigProvider renderEmpty={customizeRenderEmptyForSite}>
                                                    <Select className="form-control" defaultValue={site_id} showSearch
                                                        value={site_id}
                                                        allowClear={true}
                                                        placeholder="Site Seçiniz"
                                                        onChange={e => setSiteId(e)}
                                                        optionFilterProp="children"
                                                        onSearch={e=> setSiteSearch(e)}
                                                        filterOption={(input, option) => {                                                            
                                                            return (
                                                                option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                                option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            );

                                                        }}
                                                    >

                                                        {sites.map((sites: Site) => {
                                                            return (
                                                                <Option value={sites.id} title={sites.name}
                                                                    key={sites.name}
                                                                    id={sites.name}>{sites.name}</Option>
                                                            )
                                                        })}
                                                    </Select>
                                                </ConfigProvider>
                                            </div>
                                        </div>

                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <button type="button" onClick={e => { property_note_see ? setPropertyNoteSee(false) : setPropertyNoteSee(true); }} className="btn btn-link text-start" >Not Ekle <PlusOutlined className="font-size-16 align-middle me-2" /></button>
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12" style={property_note_see ? {} : { display: 'none' }}>
                                            <div className="form-group mb-3">
                                                <label>Not</label>
                                                <textarea
                                                    value={property_note}
                                                    className="form-control"
                                                    onChange={e => setPropertyNote(e.target.value)}>
                                                    {property_note}
                                                </textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* İşlem Bedel Bilgileri */}
                        <div className="card">
                            <div className="card-body p-4">
                                <div>
                                    <div className="row">
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>İşlem Bilgileri</label>
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3">
                                                <label>Sözleşme Tarihi</label>
                                                <input type="date" name="contract_date" required data-pristine-required-message="Lütfen sözleşme tarihini giriniz."
                                                    className="form-control"
                                                    onChange={e => setContractDate(moment(e.target.value, "YYYY-MM-DD").format("DD/MM/YYYY"))}
                                                    defaultValue={moment(contract_date, "DD/MM/YYYY").format("YYYY-MM-DD")}
                                                    value={moment(contract_date, "DD/MM/YYYY").format("YYYY-MM-DD")}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3 select-style">
                                                <label>Portföy Bulma Kaynağı</label>
                                                <Select className="form-control" defaultValue={transaction?.source_of_find_id} showSearch
                                                    value={transaction?.source_of_find_id}
                                                    placeholder="Portföy Bulma Kaynağını Seçiniz"
                                                    onChange={e => setSourceOfFindId(e)}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >

                                                    {resources_of_find.map((resources_of_find: SourceOfFind) => {
                                                        return (
                                                            <Option value={resources_of_find.id} title={resources_of_find.name}
                                                                key={resources_of_find.name}
                                                                id={resources_of_find.name}>{resources_of_find.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                                {formState.error.source_of_find_id && <p className="text-danger">{formState.error.source_of_find_id.error}</p>}
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-3">
                                            <div className="form-group mb-3 select-style">
                                                <label>{giro_type_id === 116 ? 'Portföy Satma Kaynağı' : 'Portföy Kiralama Kaynağı'}</label>
                                                <Select className="form-control" defaultValue={source_of_sell_id} showSearch
                                                    value={source_of_sell_id}
                                                    placeholder="Portföy Satma Kaynağını Seçiniz"
                                                    onChange={e => setSourceOfSellId(e)}
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) => {
                                                        return (
                                                            option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                            option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        );

                                                    }}
                                                >

                                                    {resources_of_sell.map((resources_of_sell: SourceOfSell) => {
                                                        return (
                                                            <Option value={resources_of_sell.id} title={resources_of_sell.name}
                                                                key={resources_of_sell.name}
                                                                id={resources_of_sell.name}>{resources_of_sell.name}</Option>
                                                        )
                                                    })}
                                                </Select>
                                                {formState.error.source_of_sell_id && <p className="text-danger">{formState.error.source_of_sell_id?.error}</p>}
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Yorum Durumu </label>
                                                <Checkbox.Group className="d-block pt-1" onChange={(e: any) => setCommentStatus(e)} value={comment_status}>
                                                    {comment_statuses?.map((comment_statuses: Attribute) => {
                                                        return (
                                                            <Checkbox value={comment_statuses.id}>{comment_statuses.name}</Checkbox>
                                                        )
                                                    })}
                                                </Checkbox.Group>
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3">
                                                <label>{giro_type_id === 116 ? 'Satış Bedeli' : 'Kiralama Bedeli'}</label>
                                                <NumericFormat
                                                    required
                                                    value={sale_price}
                                                    className="form-control"
                                                    name="sale_price"
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                    data-pristine-required-message="Lütfen satış bedelini giriniz."
                                                    onChange={e => { setSalePrice(e.target.value); setCalcStatus(true); setCalcStatusForAgent(true); }}
                                                />
                                                {formState.error.sale_price && <p className="text-danger">{formState.error.sale_price?.error}</p>}
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2" style={giro_type_id == 116 ? {} : { display: 'none' }}>
                                            <div className="form-group mb-3">
                                                <label>Rayiç Bedel</label>
                                                <NumericFormat
                                                    className="form-control"
                                                    value={fair_price}
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                    data-pristine-required-message="Lütfen rayiç bedeli giriniz."
                                                    onChange={e => { setFairPrice(e.target.value); setCalcStatus(true); setCalcStatusForAgent(true); }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2" style={giro_type_id == 116 ? {} : { display: 'none' }}>
                                            <div className="form-group mb-3">
                                                <label>Tapu Masrafı</label>
                                                <NumericFormat
                                                    value={title_deed_cost}
                                                    name="title_deed_cost"
                                                    className="form-control"
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                    data-pristine-required-message="Lütfen tapu masrafını giriniz."
                                                    onChange={e => { setTitleDeedCost(e.target.value); setCalcStatusForAgent(true); }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3">
                                                <label>{giro_type_id === 116 ? 'Satıcı Hizmet Bedeli' : 'Kiralama Hizmet Bedeli'}</label>
                                                <NumericFormat
                                                    value={seller_service_fee}
                                                    required
                                                    name="seller_service_fee"
                                                    className="form-control"
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                    data-pristine-required-message="Lütfen satıcı hizmet bedelini giriniz."
                                                    onChange={e => { setSellerServiceFee(e.target.value); setRefCalcStatus(true); setTimeout(() => { setCalcStatusForAgent(true); }, 700); }}
                                                />

                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2" style={giro_type_id == 116 ? {} : { display: 'none' }}>
                                            <div className="form-group mb-3">
                                                <label>Alıcı Hizmet Bedeli</label>
                                                <NumericFormat
                                                    value={buyer_service_fee}
                                                    className="form-control"
                                                    name="buyer_service_fee"
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                    data-pristine-required-message="Lütfen alıcı hizmet bedelini giriniz."
                                                    onChange={e => { setBuyerServiceFee(e.target.value); setRefCalcStatus(true); setTimeout(() => { setCalcStatusForAgent(true); }, 700); }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3">
                                                <label>Ofis İle Paylaşım Bedeli</label>
                                                <NumericFormat
                                                    value={office_referral_fee}
                                                    className="form-control"
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                    data-pristine-required-message="Lütfen ofis ile paylaşım bedelini giriniz."
                                                    onChange={e => { setOfficeReferralFee((e.target.value)); setRefCalcStatus(true); setTimeout(() => { setCalcStatusForAgent(true); }, 700); }}
                                                />
                                            </div>
                                        </div>
                                        <div className={out_office_see ? 'col-xl-10 col-md-10' : 'col-xl-12 col-md-12'}>
                                            <div className="form-group mb-3 select-style">
                                                <label>Diğer Emlakçı</label>
                                                <ConfigProvider renderEmpty={customizeRenderEmpty}>
                                                    <Select className="form-control" defaultValue={realtor_id} showSearch
                                                        value={realtor_id}
                                                        allowClear={true}
                                                        placeholder="Diğer Emlakçı Seçiniz"
                                                        onChange={e => { setRealtorId(e); e === undefined ? setOutOfficeSee(false) : setOutOfficeSee(true) }}
                                                        optionFilterProp="children"
                                                        onSearch={e=> setRealtorSearch(e)}
                                                        filterOption={(input, option) => {
                                                            return (
                                                                option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                                option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            );

                                                        }}
                                                    >

                                                        {realtors.map((realtors: Realtor) => {
                                                            let company_name=(realtors.company_name!='0')?realtors.company_name:'';
                                                            return (
                                                                <Option value={realtors.id} title={realtors.first_name + ' ' + realtors.last_name + ' ' + realtors.company_name}
                                                                    key={realtors.first_name + ' ' + realtors.last_name + ' ' + realtors.company_name}
                                                                    id={realtors.first_name + ' ' + realtors.last_name + ' ' + realtors.company_name}>{realtors.first_name + ' ' + realtors.last_name + ' ' + company_name}</Option>
                                                            )
                                                        })}
                                                    </Select>
                                                </ConfigProvider>
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2" style={out_office_see ? {} : { display: 'none' }}>
                                            <div className="form-group mb-3">
                                                <label>Ofis Dışı Paylaşım Bedeli</label>
                                                <NumericFormat
                                                    className="form-control"
                                                    name="out_office_referral_fee"
                                                    value={out_office_referral_fee}
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                    data-pristine-required-message="Lütfen ofis dışı paylaşım bedelini giriniz."
                                                    onChange={e => { setOutOfficeReferralFee((e.target.value)); setRefCalcStatus(true); setTimeout(() => { setCalcStatusForAgent(true); }, 700); }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3 d-lg-flex">
                                                <label>Satış Bedeli Ödeme Şekli: </label>
                                                <Checkbox.Group defaultValue={payment_method} value={payment_method} className="ms-2 d-block d-lg-inline-flex" onChange={(e: any) => setPaymentMethod(e)}>
                                                    {payment_methods?.map((payment_methods: Attribute) => {
                                                        return (
                                                            <Checkbox value={payment_methods.id}>{payment_methods.name}</Checkbox>
                                                        )
                                                    })}
                                                </Checkbox.Group>
                                            </div>
                                        </div>

                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <label>Toplam Hizmet Bedeli : <strong>
                                                    <NumericFormat
                                                        value={total_service_fee}
                                                        thousandSeparator="."
                                                        decimalSeparator=","
                                                        displayType="text"
                                                    /> TL </strong></label>
                                            </div>
                                        </div>
                                        {contextHolder}

                                        <div className="col-xl-12 col-md-12 d-flex align-self-center">
                                            <h4 className="text-secondary"> {transaction?.agent.first_name} {transaction?.agent.last_name} </h4>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3">
                                                <label>Toplam Hizmet Bedeli </label>
                                                <NumericFormat
                                                    data-pristine-required-message="Toplam hizmet bedelini giriniz."
                                                    className="form-control"
                                                    name="total_service_fee"
                                                    defaultValue={total_service_fee}
                                                    value={total_service_fee}
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                    required
                                                    onChange={e => { setTotalServiceFee(e.target.value); setCalcStatusForAgent(true); }} />
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3">
                                                <label>Paylaşım Yüzdesi </label>
                                                <div className="input-group">
                                                    <div className="input-group-text"><strong>%</strong></div>
                                                    <NumericFormat
                                                        key={transaction?.agent.id}
                                                        id={transaction?.agent.id.toString()}
                                                        data-pristine-required-message="Paylaşım yüzdesini giriniz."
                                                        className="form-control"
                                                        defaultValue={agent_percent?.toString().replace('.', ',')}
                                                        value={agent_percent?.toString().replace('.', ',')}
                                                        decimalSeparator=","
                                                        required
                                                        name="percent"
                                                        onChange={e => { setAgentPercent(e.target.value); setCalcStatusForAgent(true); }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3">
                                                <label>G.D. Hizmet Bedeli</label>
                                                <NumericFormat
                                                    data-pristine-required-message="G.D. hizmet bedelini giriniz."
                                                    className="form-control"
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                    required
                                                    value={agent_service_fee.toString().replace('.', ',')}
                                                    onChange={(e: any) => {
                                                        setAgentServiceFee(e.target.value);
                                                    }} />
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2 " style={transaction?.agent.team_leader_id > 0 ? {} : { display: 'none' }} >
                                            <div className="form-group mb-3">
                                                <label title="Takım Lideri ile Paylaşım Yüzdesi">T. Lideri Paylaşım Yüzdesi </label>
                                                <div className="input-group">
                                                    <div className="input-group-text"><strong>%</strong></div>
                                                    <NumericFormat
                                                        data-pristine-required-message="Toplam hizmet bedelini giriniz."
                                                        className="form-control"
                                                        decimalSeparator=","
                                                        value={team_leader_percent?.toString().replace('.', ',')}
                                                        onChange={e => {
                                                            setTeamLeaderPercent(e.target.value);
                                                            setCalcStatusForAgent(true);
                                                        }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2" style={transaction?.agent.team_leader_id > 0 ? {} : { display: 'none' }} >
                                            <div className="form-group mb-3">
                                                <label title="Takım Lideri Hizmet Bedeli">T. Lideri H. Bedeli </label>
                                                <NumericFormat
                                                    data-pristine-required-message="Toplam hizmet bedelini giriniz."
                                                    className="form-control"
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                    value={team_leader_service_fee?.toString().replace('.', ',')}
                                                    onChange={e => {
                                                        setTeamLeaderServiceFee(e.target.value);
                                                        setCalcStatusForAgent(true);
                                                    }} />
                                            </div>
                                        </div>
                                        <div className="col-xl-2 col-md-2">
                                            <div className="form-group mb-3">
                                                <label>Ofis Hizmet Bedeli</label>
                                                <NumericFormat
                                                    data-pristine-required-message="Toplam hizmet bedelini giriniz."
                                                    className="form-control"
                                                    decimalSeparator=","
                                                    thousandSeparator="."
                                                    value={office_service_fee?.toString().replace('.', ',')}
                                                    onChange={e => {
                                                        setOfficeServiceFee(e.target.value);
                                                    }} />
                                            </div>
                                        </div>
                                        <div className="clearfix border-bottom border-light mb-3"></div>
                                        <div className="col-xl-12 col-md-12">
                                            <div className="form-group mb-3">
                                                <Checkbox defaultChecked={download_word} onChange={e => setDownloadWord(e.target.checked)}>Güncelledikten sonra indir</Checkbox>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-primary" disabled={disable_button}>{save_button_text}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            {/* //Ohter Realtor Modal */}
            <Modal show={show_realtor} onHide={handleClose} animation={false} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Emlakçı Ekleme Formu</Modal.Title>
                </Modal.Header>
                <form onSubmit={realtorSubmit}>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-xl-6 col-md-6">
                                <div className="form-group mb-3">
                                    <label>Ad</label>
                                    <input type="text" data-pristine-required-message="Lütfen bir isim giriniz."
                                        className="form-control"
                                        onChange={e => setRealtorFirstName(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-xl-6 col-md-6">
                                <div className="form-group mb-3">
                                    <label>Soyad</label>
                                    <input type="text" data-pristine-required-message="Lütfen bir soyisim giriniz."
                                        className="form-control"
                                        onChange={e => setRealtorLastName(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-xl-12 col-md-12">
                                <div className="form-group mb-3">
                                    <label>Firma Adı</label>
                                    <input type="text" data-pristine-required-message="Lütfen bir firma adı giriniz."
                                        className="form-control"
                                        onChange={e => setRealtorCompanyName(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-xl-12 col-md-12">
                                <div className="form-group mb-3">
                                    <label>Email</label>
                                    <input type="email" data-pristine-required-message="Lütfen bir email adresi giriniz."
                                        className="form-control"
                                        onChange={e => setRealtorEmail(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-xl-12 col-md-12">
                                <div className="form-group mb-3">
                                    <label>Telefon</label>
                                    <div className="input-group">
                                        <div className="input-group-text p-0">
                                            <Select defaultValue={realtor_phone_code_id} style={{ width: 100 }} showSearch
                                                onChange={e => setRealtorPhoneCodeId(e.target.value)}
                                                optionFilterProp="children"
                                                filterOption={(input, option) => {
                                                    return (
                                                        option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                                        option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    );

                                                }}
                                            >

                                                {phone_codes?.map((phone_codes: PhoneCode) => {
                                                    let img_url = `https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/6.6.6/flags/4x3/${phone_codes.code}.svg`;
                                                    return (
                                                        <Option value={phone_codes.id} title={phone_codes.name}
                                                            key={phone_codes.name}
                                                            id={phone_codes.phone_code + ' ' + phone_codes.name}><img src={img_url} height={16} /> +{phone_codes.phone_code}<br /><small className="text-black-50"> {phone_codes.name}</small></Option>
                                                    )
                                                })}
                                            </Select>

                                        </div>
                                        <PatternFormat format="(###) ### ## ##" className="form-control" data-pristine-required-message="Lütfen bir telefon numarası giriniz." allowEmptyFormatting mask=" " onChange={e => setRealtorPhone(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-12 col-md-12">
                                <div className="form-group mb-3">
                                    <label>Not</label>
                                    <textarea
                                        className="form-control"
                                        onChange={e => setRealtorNote(e.target.value)}>
                                    </textarea>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            İptal
                        </Button>
                        <button type="submit" className="btn btn-primary">Kaydet</button>
                    </Modal.Footer>
                </form>
            </Modal>

            {/* //Site Modal */}
            <Modal show={show_site_modal} onHide={handleSiteClose} animation={false} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Site Ekleme Formu</Modal.Title>
                </Modal.Header>
                <form onSubmit={siteSubmit}>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-xl-12 col-md-12">
                                <div className="form-group mb-3">
                                    <label>Site Adı</label>
                                    <input type="text" data-pristine-required-message="Lütfen bir site adı giriniz."
                                        className="form-control"
                                        onChange={e => setSiteName(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-xl-12 col-md-12">
                                <div className="form-group mb-3">
                                    <label>Adres</label>
                                    <input type="text" data-pristine-required-message="Lütfen adres giriniz."
                                        className="form-control"
                                        onChange={e => setSiteAddress(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-xl-6 col-md-6">
                                <div className="form-group mb-3">
                                    <label>Toplam Daire</label>
                                    <input type="number" data-pristine-required-message="Lütfen daire adetini giriniz."
                                        className="form-control"
                                        onChange={e => setTotalHouse(parseInt(e.target.value))}
                                    />
                                </div>
                            </div>
                            <div className="col-xl-6 col-md-6">
                                <div className="form-group mb-3">
                                    <label>Yönetici Adı</label>
                                    <input type="text" data-pristine-required-message="Lütfen yönetici adıı giriniz."
                                        className="form-control"
                                        onChange={e => setManagerName(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-xl-6 col-md-6">
                                <div className="form-group mb-3">
                                    <label>Ofis Telefon</label>
                                    <PatternFormat format="0(###) ### ## ##" className="form-control" data-pristine-required-message="Lütfen bir telefon numarası giriniz." allowEmptyFormatting mask=" " onChange={e => setOfficePhone(e.target.value)} />
                                </div>
                            </div>
                            <div className="col-xl-6 col-md-6">
                                <div className="form-group mb-3">
                                    <label>Cep Telefon</label>
                                    <PatternFormat format="0(###) ### ## ##" className="form-control" data-pristine-required-message="Lütfen bir telefon numarası giriniz." allowEmptyFormatting mask=" " onChange={e => setMobilePhone(e.target.value)} />
                                </div>
                            </div>
                            <div className="col-xl-12 col-md-12">
                                <div className="form-group mb-3">
                                    <label>Not</label>
                                    <textarea
                                        className="form-control"
                                        onChange={e => setSiteNote(e.target.value)}>
                                    </textarea>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            İptal
                        </Button>
                        <button type="submit" className="btn btn-primary">Kaydet</button>
                    </Modal.Footer>
                </form>
            </Modal>

            {/* //Download Modal */}
            <Modal show={show_download} onHide={handleDownloadClose} animation={false} centered>

                <Modal.Body>
                    <table className="table table-hover mb-0">
                        <tbody>
                            {ret_agents && Array.isArray(ret_agents) ? ret_agents.map((item: any, i: any) => {
                                let download_url = `https://lluparapp.remaxaqua.com/transactions/documents/${item.id}.docx`;
                                return (
                                    <tr>
                                        <th scope="row">{item.name}</th>
                                        <td><a href={download_url} className="btn btn-sm btn-soft-primary waves-effect waves-light"> <DownloadOutlined style={{ fontSize: '16px' }} /> </a></td>
                                    </tr>
                                )
                            }) : null}
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleDownloadClose}>
                        Kapat
                    </Button>
                </Modal.Footer>
            </Modal>
        </Wrapper >
    )
}

export default Edit;