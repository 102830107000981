import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Radio, Select, DatePicker, Checkbox, ConfigProvider, message, Button, Modal, AutoComplete, Popover } from 'antd';
import { Neighbourhood } from '../../models/general/location/neighbourhood/neighbourhood';
import { Province } from '../../models/general/location/province/province';
import { useProvinceContext } from '../../providers/general/location/province/provinceProvider';
import { useBuildTypeContext } from '../../providers/attribute/buildType/buildTypeProvider';
import { usePropertyGenusContext } from '../../providers/attribute/propertyGenus/propertyGenusProvider';
import { District } from '../../models/general/location/district/district';
import axios from 'axios';

import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import Geocode from "react-geocode";
import { Attribute } from '../../models/attribute/attribute';
import { PropertyType } from '../../models/attribute/propertyType/propertyType';
import { FloorLocation } from '../../models/attribute/floorLocation/floorLocation';
import { BuildType } from '../../models/attribute/buildType/buildType';
import { InboxOutlined, PlusOutlined } from '@ant-design/icons';
import { PatternFormat } from 'react-number-format';
import { Site } from '../../models/general/site/site';
import { BsQuestionCircleFill } from 'react-icons/bs';
import { BiBuilding,BiBuildings } from 'react-icons/bi';

import { Property as PropertyModel } from '../../models/general/property/property';

const { Option } = Select;

interface FormData {
    province_id: string | null;
    province_name: string | null;
    district_id: string | null;
    district_name: string | null;
    neighbourhood_id: string | null;
    neighbourhood_hsb_id: string | null;
    neighbourhood_name: string | null;
    csb_floor_id: string | null;
    csb_parcel_id: string | null;
    rectangle_coordinates: string | null;
    get_coordinate: boolean;
    property_address: string | null;
    map_address: string | null;
    flat: string | null;
    building_number: string | null;
    build_name: string | null;
    street: string | null;
    bob: string | null;
    parcel: string | null;
    gross: string | null;
    net: string | null;
    land_office_id: number | null;
    property_genus_id: number | null;
    property_type_id: number | null;
    floor_location_id: number | null;
    build_type_id: number | null;
    building_type_id: number | null;
    build_date: Date | null;
    building_search: string | null;
    building_id: string | null;
    block_id: number | null;
    property_note_see: boolean;
    property_note: string | null;
  }
  
  interface PropertyProps {
    pageInfo: {
      title: string;
    };
    exportData: (formData: FormData) => void;
    initialData: any; 
  }
  
  const Property: React.FC<PropertyProps> = (props) => {
        const [propertyData, setPropertyData] = useState({
            save_status: false,
            property_id: null,
            province_id: null,
            province_name: '',
            district_id: null,
            district_name: '',
            neighbourhood_id: null,
            neighbourhood_hsb_id: null,
            neighbourhood_name: '',
            csb_floor_id: null,
            csb_parcel_id: null,
            rectangle_coordinates: '',
            get_coordinate: false,
            property_address: '',
            map_address: '',
            flat: null,
            building_number : null,
            build_name: null,
            street: null,
            bob: null,
            parcel: null,
            gross: null,
            net: null,
            land_office_id: 1,
            property_genus_id: 7,
            property_type_id: 14,
            floor_location_id: null,
            build_type_id: 114,
            building_type_id: 257,
            build_date: null,
            building_search: '',
            building_id: null,
            block_id: null,
            property_note_see: false,
            property_note: '',
            coordinates: { lat: 36.87524849186124, lng: 30.64364152042535 }
          });

          useEffect(() => {
            if (props.initialData) {
              setPropertyData(props.initialData);
            }
          }, [props.initialData]); // Dışarıdan gelen veri değiştiğinde tetiklenecek
        

    const [province_id, setProvinceId] = useState<any | null>(7);
    const [province_name, setProvinceName] = useState<any | null>('');
    const [district_id, setDistrictId] = useState<any | null>(2038);
    const [district_name, setDistrictName] = useState<any | null>('');
    const [neighbourhood_id, setNeighbourhoodId] = useState<any | null>(2338);
    const [neighbourhood_hsb_id, setNeighbourhoodHsbId] = useState<any | null>(null);
    const [neighbourhood_name, setNeighbourhoodName] = useState<any | null>('');
    const [property_id, setPropertyId] = useState<any | null>(0);

    const [csb_floor_id, setCsbFloorId] = useState<any | null>(0);
    const [csb_parcel_id, setCsbParcelId] = useState<any | null>(0);
    const [rectangle_coordinates, setRectangleCoordinates] = useState<any | null>('');
    const [get_coordinate, setGetCoordinate] = useState(false);
    const [property_address, setPropertyAddress] = useState<any | null>('');
    const [map_address, setMapAddress] = useState<any | null>('');
    const [flat, setFlat] = useState<any | null>(null);
    const [build_name, setBuildName] = useState<any | null>(null);
    const [floor_location_name, setFloorLocationName] = useState<any | null>(null);
    const [street, setStreet] = useState<any | null>(null);
    const [bob, setBob] = useState<any | null>(null);
    const [parcel, setParcel] = useState<any | null>(null);
    const [gross, setGross] = useState<any | null>(null);
    const [net, setNet] = useState<any | null>(null);
    const [land_office_id, setLandOfficeId] = useState<any | null>(1);
    const [property_genus_id, setPropertyGenusId] = useState<any | null>(7);
    const [property_type_id, setPropertyTypeId] = useState<any | null>(14);
    const [floor_location_id, setFloorLocationId] = useState<any | null>();
    const [build_type_id, setBuildTypeId] = useState<any | null>(114);
    const [building_type_id, setBuildingTypeId] = useState<any | null>(257);
    const [build_date, setBuildDate] = useState<any | null>(null);
    const [building_search, setBuildingSearch] = useState<any | null>('');
    const [building_id, setBuildingId] = useState<any | null>(null);
    const [block_id, setBlockId] = useState<number | null>(null);
    const [property_note_see, setPropertyNoteSee] = useState(false);
    const [property_note, setPropertyNote] = useState<any | null>('');

    const [provinces, setProvinces] = useProvinceContext();
    const [districts, setDistricts] = useState([]);
    const [neighbourhoods, setNeighbourhoods] = useState([]);
    const [build_types, setBuildTypes] = useBuildTypeContext();
    const [property_genuses, setPropertyGenuses] = usePropertyGenusContext();
    const [property_types, setPropertyTypes] = useState([]);
    const [floor_locations, setFloorLocations] = useState([]);
    const [buildings, setBuildings] = useState([]);
    const [blocks, setBlocks] = useState([]);
    const [build_type_name, setBuildTypeName] = useState('Bina');
    const [found_properties, setFoundProperties] = useState<any | null>([]);

    const [coordinates, setCoordinates] = useState({ lat: 36.87524849186124, lng: 30.64364152042535 });
    const [property_coordinate, setPropertyCoordinate] = useState<any | null>('36.87524849186124,30.64364152042535');
    const [selectedOption, setSelectedOption] = useState<any | null>('');
    const [search, setSearch] = useState(false);
    const [search_value, setSearchValue] = useState<string | null>(null);
    const [autocomplete_status, setAutocomplateStatus] = useState(false);
    const [save_status, setSaveStatus] = useState(false);
    
    const containerStyle = {
        width: '100%',
        height: '400px'
    };


    useEffect(() => {
        (
            async () => {

                const { data } = await axios.get('location/district/' + propertyData?.province_id);

                setDistricts(data);
            }
        )()
    }, [propertyData?.province_id]);


    useEffect(() => {

        (
            async () => {
                if (typeof province_name === 'string' && province_name.trim().length > 0) {
                    const { data } = await axios.get('location/province/name-like/' + province_name);
                    if (data.id > 0) { setDistrictId(null); setNeighbourhoodId(null); }
                    setProvinceId(data.id);
                }
            }
        )()

    }, [province_name])


    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('location/neighbourhood/' + district_id);

                setNeighbourhoods(data);
            }
        )()
    }, [district_id]);

    useEffect(() => {
        setTimeout(() => {

            (
                async () => {
                    if (typeof province_name === 'string' && province_name.trim().length > 0) {

                        const { data } = await axios.get(`location/district/name-like/${district_name}/${province_id}`);
                        setDistrictId(data.id);
                    }
                }
            )()
        }, 1000);

    }, [district_name, province_id])

    useEffect(() => {
        setTimeout(() => {

            (
                async () => {
                    if (typeof province_name === 'string' && province_name.trim().length > 0) {

                        const { data } = await axios.get(`location/neighbourhood/name-like/${neighbourhood_name}/${district_id}`);
                        setNeighbourhoodId(data.id);
                    }
                }
            )()
        }, 1000);

    }, [neighbourhood_name, district_id])

    useEffect(()=>{
        let propAddress = '';

        if (neighbourhood_name) {
            const cleanedNeighbourhood = neighbourhood_name.replace("MAHALLESİ", "").trim();          
            propAddress += cleanedNeighbourhood + ',';
          }
        if (propertyData?.street) propAddress += ' ' + propertyData?.street + ',';
        if (propertyData?.build_name) propAddress += ' ' + propertyData?.build_name + ' ';
        if (propertyData?.flat) propAddress += ' No: ' + propertyData?.flat + ',';
        if (district_name) propAddress += ' ' + district_name + ' / ';
        if (province_name) propAddress += ' ' + province_name;
        
        propAddress = propAddress.trim().toLocaleUpperCase('tr-TR');
        let propAddressForContract = '';                
        propAddressForContract += neighbourhood_name;
        if (propertyData?.street) propAddressForContract += ' ' + propertyData?.street;
        if (propertyData?.build_name) propAddressForContract += ' ' + propertyData?.build_name;
        if (propertyData?.flat) propAddressForContract += ' No: ' + propertyData?.building_number;
        if (propertyData?.floor_location_id && Number(propertyData?.floor_location_id) > 0) propAddressForContract += ' Kat: ' + floor_location_name;
        if (propertyData?.building_number) propAddressForContract += ' Daire: ' + propertyData?.flat + ',';

        if (district_name) propAddressForContract += ' ' + district_name + ' / ';
        if (province_name) propAddressForContract += ' ' + province_name;
        
        propAddressForContract = propAddressForContract.trim().toLocaleUpperCase('tr-TR');
          console.log(propAddressForContract);
        setPropertyData((propertyData: any) => ({
            ...propertyData,
            property_address: propAddressForContract,
            map_address: propAddress,
        }));

        if(propAddress){
            Geocode.fromAddress(propAddress).then(
                (response: any) => {
                    const { lat, lng } = response.results[0].geometry.location;
                    setPropertyData((propertyData: any) => ({
                        ...propertyData,
                        coordinates: { lat: lat, lng: lng },
                        property_coordinate: `${lat},${lng}`
                    }));

                },
                (error: any) => {
                    //console.error(error);
                }
            );
        }
    },[province_id,district_id,neighbourhood_id,propertyData?.street,propertyData?.flat,propertyData?.build_name,propertyData?.building_number,propertyData?.floor_location_id])

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('attributes/8/' + property_genus_id);

                setPropertyTypes(data.data);
            }
        )()
    }, [property_genus_id]);

    useEffect(() => {
        (
            async () => {
                const { data } = await axios.get('attributes/9');
                setFloorLocations(data.data);
            }
        )()
    }, []);

    useEffect(() => {
        (property_genus_id == 8 || property_genus_id == 10 || build_type_id == 115) ? setBuildTypeName('Site') : setBuildTypeName('Bina');
        (property_genus_id == 8 || property_genus_id == 10 || build_type_id == 115) ? setBuildingTypeId(258) : setBuildingTypeId(257);
    }, [property_genus_id, property_genus_id, build_type_id])

    //Site
    const [building_name, setBuildingName] = useState('');
    const [building_address, setBuildingAddress] = useState('');
    const [total_house, setTotalHouse] = useState(0);
    const [manager_name, setManagerName] = useState('');
    const [mobile_phone, setMobilePhone] = useState('');
    const [office_phone, setOfficePhone] = useState('');
    const [building_note, setBuildingNote] = useState('');

    useEffect(() => {
        let isMounted = true;

        const fetchBuildings = async () => {
            try {
                let response;

                if (building_search.length > 0) {
                    response = await axios.get(`buildings?filter_type_id=${building_type_id}&filter_name=${building_search}`);
                } else {
                    response = await axios.get(`buildings?filter_type_id=${building_type_id}`);
                }

                if (isMounted) {
                    setBuildings(response.data.data || []);
                }
            } catch (error) {
                console.log(error);
            }
        };

        try {
            fetchBuildings();
        } catch (error) {
            console.log(error);
        }

        const intervalId = setInterval(() => {
            try {
                fetchBuildings();
            } catch (error) {
                console.log(error);
            }
        }, 115000);

        return () => {
            isMounted = false;
            clearInterval(intervalId);
        };
    }, [building_search, build_type_id]);

    //Google Maps Geo
    useEffect(() => {
        Geocode.fromAddress(propertyData?.map_address).then(
            (response: any) => {
                const { lat, lng } = response.results[0].geometry.location;
                setPropertyData((propertyData: any) => ({
                    ...propertyData,
                    coordinates: { lat: lat, lng: lng },
                    property_coordinate: `${lat},${lng}`
                }));
            },
            (error: any) => {
                //console.error(error);
            }
        );
    }, [propertyData?.map_address]);

    //Google Maps Geo For Site
    const [building_coordinates, setBuildingCoordinates] = useState('');

    useEffect(() => {
        Geocode.fromAddress(building_address).then(
            (response: any) => {
                const { lat, lng } = response.results[0].geometry.location;
                setBuildingCoordinates(`${lat},${lng}`)
            },
            (error: any) => {
                //console.error(error);
            }
        );
    }, [building_address]);

    const customizeRenderEmptyForBuilding = () => (
        <div style={{ textAlign: 'center' }}>
            <InboxOutlined style={{ fontSize: 40 }} />
            <p>{build_type_name} Bulunamadı!</p>
            <Button type="primary" onClick={showModal}>Yeni Ekle</Button>
        </div>
    );
    
    const customizeRenderEmptyForBlock = () => (
        <div style={{ textAlign: 'center' }}>
            <InboxOutlined style={{ fontSize: 40 }} />
            <p>Blok Bulunamadı!</p>
            <Button type="primary" onClick={() => setOpen(true)}>
        Blok Yönetimi
      </Button>
        </div>
    );

    //Add Site Modal
    const [show_building_modal, setShowBuildingModal] = useState(false);
    const handleBuildingClose = () => setShowBuildingModal(false);

    const handleBuildingShow = (e: any) => {
        (
            () => {
                setShowBuildingModal(true);
            }
        )()
    };

    //Modal
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);

    const showModal = () => {
        setOpen(true);
    };

    const handleOk = () => {
        setConfirmLoading(true);
        setTimeout(() => {
            setOpen(false);
            setConfirmLoading(false);
        }, 2000);
    };

    useEffect(() => {
        if (confirmLoading) {
            const submitBuilding = async () => {
                const new_site_id = await axios.post('buildings', {
                    name: building_name,
                    address: building_address,
                    manager_name: manager_name,
                    office_phone: office_phone,
                    mobile_phone: mobile_phone,
                    coordinate: building_coordinates,
                    definition: building_note,
                    house_count: total_house,
                    type_id: building_type_id,
                });
                setBuildingId(new_site_id);
            };

            submitBuilding();
            setBuildingSearch(`${build_name}`);
            setConfirmLoading(false);
        }
    }, [confirmLoading]);

    const handleCancel = () => {
        setOpen(false);
    };

    const fieldNames = [
        "province",
        "district",
        "neighbourhood",
        "street",
        "flatNumber",
        "propertyAddress",
    ];


    const handleInputChange = (field:any, value: any) => {
        setPropertyData((propertyData: any) => ({
          ...propertyData,
          [field]: value,
        }));
        props.exportData({ ...propertyData, [field]: value });
      };

    // const next = () => {
    //     if (pageInfo.page < pageInfo.lastPage) {
    //         pageChanged(pageInfo.page + 1);
    //     }
    // }

    // const prev = () => {
    //     if (pageInfo.page >= 1) {
    //         pageChanged(pageInfo.page - 1);
    //     }
    // }

    //AutoComplete
    useEffect(() => {
        (async () => {
            try {
                let response:any|null=null; 
                let propertiesData:PropertyModel|null=null;

                if (search_value === '' || search_value === null) {

                     response = await axios.get('properties-autocomplete');
                }else{
                    if(search_value.length>2){
                      response = await axios.get('properties-autocomplete?filter_search='+search_value);
                    }
                }       

                console.log(response);
                propertiesData = response?.data?.data?.map((property: PropertyModel) => ({
                    id: property.id,
 
                    // value: (
                    //     <div>
                    //         <strong>ID: {property.id}</strong><br />
                    //         <span>Building Name: {property.building?.name}</span><br />
                    //         <span>İç Kapı No: {property.building?.flat}</span>
                    //     </div>
                    // ),
                    value: (<> {property.building.type.id===258?<BiBuildings style={{ height: '16px', width: '16px', marginTop: '-3px' }}/>:<BiBuilding style={{ height: '16px', width: '16px', marginTop: '-3px' }}/> } <strong>{property?.id}</strong> - {property.building?.name} <strong className='text-danger'>İç Kapı No: </strong> {property?.flat}  | <strong className='text-danger'>Ada/Parsel: </strong> {property?.bob} / {property?.parcel} | <strong className='text-danger'>Adres: </strong> {property?.address}</>),
                    building_name: property.building?.name,
                    province_id: property?.province_id,
                    district_id: property?.district_id,
                    neighbourhood_id: property?.neighbourhood_id,
                    street: property?.street,
                    flat: property?.flat,
                    address: property?.address,
                    bob: property?.bob,
                    parcel: property?.parcel,
                    coordinate: property?.building?.coordinate,
                    property_genus_id: property?.property_genus_id,
                    property_type_id: property?.property_type_id,
                    floor_location_id: property?.floor_location_id,
                    build_date: property?.build_date,
                    gross: property?.gross,
                    net: property?.net,
                    building_type_id: property?.building?.type?.id,
                    build_type_name: property?.building?.type?.name,
                    building_id: property?.building.id,
                    block_id: property?.building.block_id,
                    definition: property?.definition,
                }));
                setFoundProperties(propertiesData);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        })();
    }, [search_value]);

    const content = (
        <div>
            <p>
                <strong>Mülk numarası, <br /> bina adı, <br /> site adı, <br /> ada veya parsel </strong> <br /> bilgileri ile sorgulama yapabilirsiniz.
            </p>
        </div>
    );

    return (
        <div>
            <div className="row">
                <div className={props.pageInfo?.title ? 'col-xl-12 col-md-12 d-block' : 'd-none'}>
                    <div className="form-group mb-3">
                        <label>{props.pageInfo?.title}  </label>
                    </div>
                </div>

                <div className="col-xl-12 col-md-12">
                    <div className="form-group mb-3 select-style">
                        <div className='row'>
                            <div className='col-md-1'>
                                <label className='mt-2'>
                                    <Popover content={content} title="Nasıl kullanılır?">
                                        Mülk Bul <BsQuestionCircleFill style={{ color: '#1c84ee' }} />
                                    </Popover>
                                </label>
                            </div>
                            <div className='col-md-11'>
                                <AutoComplete
                                    className='form-control'
                                    options={found_properties}
                                    onSearch={e => setSearchValue(e)}
                                    defaultValue ={selectedOption}
                                    key={building_id}
                                    onSelect={(e,o) => {
                                        handleInputChange('property_id', o.id);
                                        setPropertyData((propertyData: any) => ({
                                            ...propertyData,
                                            property_id: o.id,
                                            province_id: o.province_id,
                                            district_id: o.district_id,
                                            neighbourhood_id: o.neighbourhood_id,
                                            build_name: o.build_name,
                                            flat: o.flat,
                                            address: o.address,
                                            bob: o.bob,
                                            parcel: o.parcel,
                                            property_address: o.address,
                                            map_address: o.address,
                                            coordinates: { lat: parseFloat(o.coordinate.split(',')[0]), lng: parseFloat(o.coordinate.split(',')[1]) },
                                          }));

                                        setSelectedOption(o.id+' '+o.building_name);
                                        setPropertyId(o.id);  
                                        setProvinceId(o.province_id);  
                                        setDistrictId(o.district_id);  
                                        setNeighbourhoodId(o.neighbourhood_id);  
                                        setStreet(o.street);  
                                        setFlat(o.flat);
                                        setPropertyAddress(o.address);   
                                        setMapAddress(o.address);   
                                        setBob(o.bob);   
                                        setParcel(o.parcel);   
                                        setCoordinates({ lat: parseFloat(o.coordinate.split(',')[0]), lng: parseFloat(o.coordinate.split(',')[1]) });

                                        setPropertyGenusId(o.property_genus_id);   
                                        setPropertyTypeId(o.property_type_id);   
                                        setFloorLocationId(o.floor_location_id);   
                                        setBuildDate(o.build_date);   
                                        setGross(o.gross);   
                                        setNet(o.net);
                                        setBuildingTypeId(o.building_type_id);
                                        setBuildTypeId(o.building_type_id===258?115:114);
                                        setBuildTypeName(o.build_type_name);
                                        setBuildingId(o.building_id);
                                        setPropertyNote(o.definition);
                                        setPropertyNoteSee((o.definition==='')?false:true);
                                        setAutocomplateStatus(true);
                                    }}
                                    placeholder="Mülk numarası, bina adı, site adı, ada veya parsel girebilirsiniz."
                                    filterOption={(inputValue: string, option: any) => {
                                        return true;
                                        // if (option && option.value && typeof option.value === 'string') {
                                        //     const optionText = option.value.toLocaleUpperCase('tr');
                                        //     const searchValue = inputValue.toLocaleUpperCase('tr');
                                        //     return optionText.indexOf(searchValue) !== -1;
                                        // }
                                        // return false;
                                    }}
                                    // filterOption={(inputValue: string, option: any) => {
                                    //     if (option && option.value) {
                                    //       return option.value.toLocaleUpperCase('tr').indexOf(inputValue.toLocaleUpperCase('tr')) !== -1;
                                    //     }
                                    //     return false;
                                    //   }}
                                />
                            </div>
                        </div>

                    </div>
                </div>

                <div className="col-xl-2 col-md-2">
                    <div className="form-group mb-3 select-style">
                        <label>İl</label>
                        <Select className="form-control" defaultValue={propertyData?.province_id} value={propertyData?.province_id} showSearch
                            optionFilterProp="children"
                            autoComplete={'off'}
                            placeholder="İl Seçiniz"
                            onChange={(e, option: any) => { setProvinceName(option.children); setDistrictId(null); setNeighbourhoodId(null); handleInputChange('province_id', e) }}
                            onBlur={e => setGetCoordinate(true)}
                            filterOption={(input, option) => {
                                return (
                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                );

                            }}
                        >

                            {provinces?.map((provinces: Province) => {
                                return (
                                    <Option value={provinces.id} title={provinces.name}
                                        key={provinces.name}
                                        id={provinces.name}>{provinces.name}</Option>
                                )
                            })}
                        </Select>
                    </div>
                </div>
                <div className="col-xl-2 col-md-2">
                    <div className="form-group mb-3 select-style">
                        <label>İlçe</label>
                        <Select className="form-control" defaultValue={propertyData?.district_id} value={propertyData?.district_id} showSearch
                            optionFilterProp="children"
                            autoComplete={'off'}
                            onChange={(e, option: any) => { setDistrictName(option.children); setNeighbourhoodId(null); handleInputChange('district_id', e) }}
                            value={propertyData?.district_id}
                            onBlur={e => setGetCoordinate(true)}
                            placeholder="İlçe Seçiniz"
                            filterOption={(input, option) => {
                                return (
                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                );

                            }}
                        >     

                            {districts.map((districts: District) => {
                                return (
                                    <Option value={districts.id} title={districts.name}
                                        key={districts.name}
                                        id={districts.name}>{districts.name}</Option>
                                )
                            })}
                        </Select>
                    </div>
                </div>
                <div className="col-xl-2 col-md-2">
                    <div className="form-group mb-3 select-style">
                        <label>Mahalle</label>
                        <Select className="form-control" defaultValue={propertyData?.neighbourhood_id} showSearch
                            value={propertyData?.neighbourhood_id}
                            onChange={(e, option: any) => { setNeighbourhoodName(option.children); handleInputChange('neighbourhood_id', e) }}
                            optionFilterProp="children"
                            placeholder="Mahalle Seçiniz"
                            onBlur={e => setGetCoordinate(true)}
                            filterOption={(input, option) => {
                                return (
                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                );

                            }}
                        >
                            {neighbourhoods.map((neighbourhoods: Neighbourhood) => {
                                return (
                                    <Option value={neighbourhoods.id} title={neighbourhoods.name}
                                        key={neighbourhoods.name}
                                        id={neighbourhoods.name}>{neighbourhoods.name}</Option>
                                )
                            })}
                        </Select>
                    </div>
                </div>
                <div className="col-xl-2 col-md-2">
                    <div className="form-group mb-3">
                        <label>Cadde / Sokak</label>
                        <input type="text"
                            className="form-control"
                            defaultValue={propertyData?.street}
                            onChange={(e) => {  handleInputChange('street', e.target.value); }}
                            onBlur={e => setGetCoordinate(true)}
                        />
                    </div>
                </div>
                <div className="col-xl-2 col-md-2">
                    <div className="form-group mb-3">
                        <label>Kapı No</label>
                        <input type="text" data-pristine-required-message="Lütfen bağımsız kapı no giriniz."
                            className="form-control"
                            defaultValue={propertyData?.building_number}
                            onChange={(e) => { handleInputChange('building_number', e.target.value); }}
                        />
                    </div>
                </div>
                <div className="col-xl-2 col-md-2">
                    <div className="form-group mb-3">
                        <label>İç Kapı No</label>
                        <input type="text" data-pristine-required-message="Lütfen bağımsız bölüm no giriniz."
                            className="form-control"
                            defaultValue={propertyData?.flat}
                            onChange={(e) => { handleInputChange('flat', e.target.value); }}
                        />
                    </div>
                </div>
                <div className="col-xl-2 col-md-2">
                    <div className="form-group mb-3 select-style">
                        <label>Gayrimenkul Cinsi</label>
                        <Select className="form-control" defaultValue={property_genus_id} showSearch
                            optionFilterProp="children"
                            onChange={e => { setPropertyGenusId(e); setPropertyTypeId(null); handleInputChange('property_genus_id', e); }}
                            filterOption={(input, option) => {
                                return (
                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                );

                            }}
                        >
                            {property_genuses?.map((property_genuses: Attribute) => {
                                return (
                                    <Option value={property_genuses.id} title={property_genuses.name}
                                        key={property_genuses.name}
                                        id={property_genuses.name}>{property_genuses.name}</Option>
                                )
                            })}
                        </Select>
                    </div>
                </div>
                <div className="col-xl-2 col-md-2" style={property_genus_id == 10 ? { display: 'none' } : {}} >
                    <div className="form-group mb-3 select-style">
                        <label>Gayrimenkul Türü</label>
                        <Select className="form-control" defaultValue={property_type_id} showSearch
                            value={property_type_id}
                            placeholder="Gayrimenkul Türü Seçiniz"
                            onChange={(e) => { setPropertyTypeId(e); handleInputChange('property_type_id', e); }}
                            optionFilterProp="children"
                            filterOption={(input, option) => {
                                return (
                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                );

                            }}
                        >
                            {property_types.map((property_types: PropertyType) => {
                                return (
                                    <Option value={property_types.id} title={property_types.name}
                                        key={property_types.name}
                                        id={property_types.name}>{property_types.name}</Option>
                                )
                            })}
                        </Select>
                    </div>
                </div>
                <div className="col-xl-2 col-md-2" style={property_genus_id == 8 || property_genus_id == 10 ? { display: 'none' } : {}}>
                    <div className="form-group mb-3 select-style">
                        <label>Bulunduğu Kat</label>
                        <Select className="form-control" defaultValue={floor_location_id} showSearch
                            value={floor_location_id}
                            placeholder="Bulunduğu Katı Seçiniz"
                            onChange={(e,o:any) => { setFloorLocationId(e); setFloorLocationName(o?.title);  handleInputChange('floor_location_id', e); }}
                            optionFilterProp="children"
                            filterOption={(input, option) => {
                                return (
                                    option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                    option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                );

                            }}
                        >

                            {floor_locations?.map((floor_locations: FloorLocation) => {
                                return (
                                    <Option value={floor_locations.id} title={floor_locations.name}
                                        key={floor_locations.name}
                                        id={floor_locations.name}>{floor_locations.name}</Option>
                                )
                            })}
                        </Select>
                    </div>
                </div>

                <div className="col-xl-2 col-md-2" style={property_genus_id == 8 || property_genus_id == 10 ? { display: 'none' } : {}}>
                    <div className="form-group mb-3 date-style">
                        <label>Bina Yaşı</label>
                        <DatePicker className="form-control" placeholder="Bina Yaşını Seçiniz" picker="year" format={'YYYY'} onChange={(e, dateStr) => { setBuildDate(dateStr); handleInputChange('build_date', dateStr); }} />
                    </div>
                </div>
                <div className="col-xl-2 col-md-2">
                    <div className="form-group mb-3">
                        <label>Brüt m<sup>2</sup></label>
                        <input type="number" data-pristine-required-message="Lütfen brüt alanı giriniz."
                            className="form-control"
                            value={gross}
                            defaultValue={gross}
                            onChange={(e) => { setGross(e.target.value); handleInputChange('gross', e.target.value); }}
                        />
                    </div>
                </div>
                <div className="col-xl-2 col-md-2" style={property_genus_id == 8 || property_genus_id == 10 ? { display: 'none' } : {}}>
                    <div className="form-group mb-3">
                        <label>Net m<sup>2</sup></label>
                        <input type="number" data-pristine-required-message="Lütfen net alanı giriniz."
                            className="form-control"
                            onChange={(e) => { setNet(e.target.value); handleInputChange('net', e.target.value); }}
                        />
                    </div>
                </div>
                <div className="col-xl-3 col-md-3" style={property_genus_id == 8 || property_genus_id == 10 ? { display: 'none' } : {}}>
                    <div className="form-group mb-3">
                        <label>Yapı Türü</label>
                        <div>
                            <Radio.Group defaultValue={build_type_id} value={build_type_id} onChange={(e) => { setBuildTypeId(e.target.value); handleInputChange('build_type_id', e.target.value); }}>
                                {build_types?.map((build_types: BuildType) => {
                                    return (
                                        <Radio value={build_types.id} >{build_types.name}</Radio>
                                    )
                                })}
                            </Radio.Group>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3 col-md-3" style={property_genus_id == 8 || property_genus_id == 10 ? { display: 'none' } : {}}>
                    <div className="form-group mb-3 select-style">
                        <label>{build_type_name}</label>
                        <ConfigProvider renderEmpty={customizeRenderEmptyForBuilding}>
                            <Select className="form-control" defaultValue={building_id} showSearch
                                value={building_id}
                                allowClear={true}
                                placeholder={`${build_type_name} Seçiniz`}
                                onChange={(e,o:any) => { setBuildingId(e);  setPropertyData((propertyData: any) => ({...propertyData, build_name:o?.title})); setBuildName(o?.title); handleInputChange('building_id', e); }}
                                onSearch={e => setBuildingSearch(e)}
                                optionFilterProp="children"
                                filterOption={(input, option) => {
                                    return (
                                        option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                        option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    );

                                }}
                            >
                                {(Array.isArray(buildings) && buildings.length > 0) ? buildings.map((buildings: Site) => {
                                    return (
                                        <Option value={buildings.id} title={buildings.name} key={buildings.name} id={buildings.name}>
                                            {buildings.name}
                                        </Option>
                                    )
                                }) : null}
                            </Select>
                        </ConfigProvider>
                    </div>
                </div>
                <div className="col-xl-3 col-md-3" style={build_type_id == 115 ? {} : { display: 'none' }}>
                    <div className="form-group mb-3 select-style">
                        <label>Blok</label>
                        <ConfigProvider renderEmpty={customizeRenderEmptyForBlock}>
                            <Select className="form-control" defaultValue={block_id} showSearch
                                value={block_id}
                                allowClear={true}
                                placeholder={`Blok Seçiniz`}
                                onChange={(e,o:any) => { setBlockId(e);  setBuildName(o?.title); handleInputChange('block_id', e); }}
                                onSearch={e => setBuildingSearch(e)}
                                optionFilterProp="children"
                                filterOption={(input, option) => {
                                    return (
                                        option?.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                        option?.id.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    );

                                }}
                            >
                                {(Array.isArray(blocks) && blocks.length > 0) ? blocks.map((blocks: Attribute) => {
                                    return (
                                        <Option value={blocks.id} title={blocks.name} key={blocks.name} id={blocks.name}>
                                            {blocks.name}
                                        </Option>
                                    )
                                }) : null}
                            </Select>
                        </ConfigProvider>
                    </div>
                </div>
                <div className="col-xl-8 col-md-8">
                    <div className="form-group mb-3">
                        <label>Adres</label>
                        <input type="text" name="property_address" data-pristine-required-message="Lütfen adres giriniz."
                            className="form-control"
                            autoComplete={'off'}
                            value={propertyData?.property_address}
                            defaultValue={propertyData?.property_address}
                            onBlur={(e) => { handleInputChange('property_address', e.target.value); }}
                        />
                    </div>
                </div>
                <div className="col-xl-2 col-md-2">
                    <div className="form-group mb-3">
                        <label>Ada</label>
                        <input type="number" data-pristine-required-message="Lütfen ada giriniz."
                            className="form-control"
                            defaultValue={propertyData?.bob}
                            onChange={(e) => { handleInputChange('bob', e.target.value); }}
                            onBlur={e => setGetCoordinate(true)}
                        />
                    </div>
                </div>
                <div className="col-xl-2 col-md-2">
                    <div className="form-group mb-3">
                        <label>Parsel</label>
                        <input type="number" data-pristine-required-message="Lütfen parsel giriniz."
                            className="form-control"
                            defaultValue={propertyData?.parcel}
                            onChange={(e) => { handleInputChange('parcel', e.target.value); }}
                            onBlur={e => setGetCoordinate(true)}

                        />
                    </div>
                </div>
                <div className="col-xl-12 col-md-12">
                    <div className="form-group mb-3">
                        <label>Harita Konumu </label>
                        <LoadScript
                            googleMapsApiKey="AIzaSyA4ZrsurXwR2QD4Xx5-CpHPD4N3qvurBMo"
                        >
                            <GoogleMap
                                key={building_id}
                                mapContainerStyle={containerStyle}
                                center={propertyData?.coordinates}
                                zoom={15}
                            >
                                <Marker
                                    position={propertyData?.coordinates}
                                    draggable={true}
                                    onDragEnd={e => {
                                        if (e.latLng && e.latLng) {
                                            handleInputChange('property_coordinate', `${e.latLng.lat()},${e.latLng.lng()}`);
                                            handleInputChange('coordinates', `${e.latLng.lat()},${e.latLng.lng()}`);
                                            handleInputChange('building_coordinates', `${e.latLng.lat()},${e.latLng.lng()}`);
                                        }
                                    }}
                                />
                            </GoogleMap>
                        </LoadScript>
                    </div>
                </div>
                
                <div className="col-xl-12 col-md-12">
                    <div className="form-group mb-3">
                        <button type="button" onClick={e => { property_note_see ? setPropertyNoteSee(false) : setPropertyNoteSee(true); }} className="btn btn-link text-start" >Not Ekle <PlusOutlined className="font-size-16 align-middle me-2" /></button>
                    </div>
                </div>
                <div className="col-xl-12 col-md-12" style={property_note_see ? {} : { display: 'none' }}>
                    <div className="form-group mb-3">
                        <label>Not</label>
                        <textarea
                            className="form-control"
                            onChange={(e) => { setPropertyNote(e.target.value); handleInputChange('property_note', e.target.value); }}>
                        </textarea>
                    </div>
                </div>
                {autocomplete_status?
                <div className="col-xl-12 col-md-12">
                    <div className="form-group mb-3">
                        <Checkbox defaultChecked={save_status} onChange={e => setSaveStatus(e.target.checked)}>Yeni mülk olarak kaydet</Checkbox>
                    </div>
                </div>:null}
            </div>

            <Modal
                title={`${build_type_name} Ekleme Formu`}
                open={open}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
            >
                <div className="row">
                    <div className="col-xl-12 col-md-12">
                        <div className="form-group mb-3">
                            <label>{build_type_name} Adı</label>
                            <input type="text"
                                className="form-control"
                                onChange={e => setBuildingName(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-xl-12 col-md-12">
                        <div className="form-group mb-3">
                            <label>Adres</label>
                            <input type="text"
                                className="form-control"
                                onChange={e => setBuildingAddress(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-xl-6 col-md-6">
                        <div className="form-group mb-3">
                            <label>Toplam Daire</label>
                            <input type="number"
                                className="form-control"
                                onChange={e => setTotalHouse(parseInt(e.target.value))}
                            />
                        </div>
                    </div>
                    <div className="col-xl-6 col-md-6">
                        <div className="form-group mb-3">
                            <label>Yönetici Adı</label>
                            <input type="text"
                                className="form-control"
                                onChange={e => setManagerName(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="col-xl-6 col-md-6">
                        <div className="form-group mb-3">
                            <label>Ofis Telefon</label>
                            <PatternFormat format="0(###) ### ## ##" className="form-control" data-pristine-required-message="Lütfen bir telefon numarası giriniz." allowEmptyFormatting mask=" " onChange={e => setOfficePhone(e.target.value)} />
                        </div>
                    </div>
                    <div className="col-xl-6 col-md-6">
                        <div className="form-group mb-3">
                            <label>Cep Telefon</label>
                            <PatternFormat format="0(###) ### ## ##" className="form-control" data-pristine-required-message="Lütfen bir telefon numarası giriniz." allowEmptyFormatting mask=" " onChange={e => setMobilePhone(e.target.value)} />
                        </div>
                    </div>
                    <div className="col-xl-12 col-md-12">
                        <div className="form-group mb-3">
                            <label>Not</label>
                            <textarea
                                className="form-control"
                                onChange={e => setBuildingNote(e.target.value)}>
                            </textarea>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default Property;
