import { useEffect, useState } from 'react';
import axios from 'axios';
import DoughnutChartWithCard from '../chart/doughnutChartWithCard';

const TransactionTypesCountChart = ({ start_date, end_date, dateTypeName, sendFilter }: any) => {

    const [transactions_sum_count_report, setTransactionsSumCountReport] = useState<any | null>([]);
    const [chartsData, setChartsData] = useState({
        chartSales: {
            labels: [''],
            datasets: [
                {
                    label: '# of Votes',
                    data: [0],
                    backgroundColor: [
                        'rgb(105, 81, 206)',
                        'rgb(240, 77, 128)',
                        'rgba(255, 204, 90, 0.85)',
                        'rgb(22, 218, 241)'
                    ],
                    borderColor: [
                        'rgb(105, 81, 206)',
                        'rgb(240, 77, 128)',
                        'rgba(255, 204, 90, 0.85)',
                        'rgb(22, 218, 241)'
                    ],
                    borderWidth: 1,
                },
            ],
        },
        chartRent: {
            labels: [''],
            datasets: [
                {
                    label: '# of Votes',
                    data: [0],
                    backgroundColor: [
                        'rgb(105, 81, 206)',
                        'rgb(240, 77, 128)',
                        'rgba(255, 204, 90, 0.85)',
                        'rgb(22, 218, 241)'
                    ],
                    borderColor: [
                        'rgb(105, 81, 206)',
                        'rgb(240, 77, 128)',
                        'rgba(255, 204, 90, 0.85)',
                        'rgb(22, 218, 241)'
                    ],
                    borderWidth: 1,
                },
            ],
        },
    });

    useEffect(() => {
        (
            async () => {
                const generateChartData = (propertyGenus: any) => {
                    const labels: any = [];
                    const data: any = [];

                    Object.values(propertyGenus).forEach((item: any) => {
                        labels.push(item.name);
                        data.push(item.value);
                    });

                    return {
                        labels: labels,
                        datasets: [
                            {
                                label: '# of Votes',
                                data: data,
                                backgroundColor: [
                                    'rgb(105, 81, 206)',
                                    'rgb(240, 77, 128)',
                                    'rgba(255, 204, 90, 0.85)',
                                    'rgb(22, 218, 241)'
                                ],
                                borderColor: [
                                    'rgb(105, 81, 206)',
                                    'rgb(240, 77, 128)',
                                    'rgba(255, 204, 90, 0.85)',
                                    'rgb(22, 218, 241)'
                                ],
                                borderWidth: 1,
                            }
                        ],
                    };
                };

                    axios.get(`dashboard?filter_between[]=${start_date}&filter_between[]=${end_date}`)
                        .then(({ data }) => {
                            setTransactionsSumCountReport(data?.transactions?.report);

                            // Döngüyle chartSales ve chartRent verilerini oluştur
                            const chartSalesData = generateChartData(data?.transactions?.report?.giro_type?.total['116'].property_genus);
                            const chartRentData = generateChartData(data?.transactions?.report?.giro_type?.total['117'].property_genus);

                            setChartsData({
                                chartSales: chartSalesData,
                                chartRent: chartRentData
                            });
                        });
            }
        )()
    }, [sendFilter]);

    return (
        <>
            <div className="col-xl-3 col-md-6">
                <DoughnutChartWithCard data={chartsData.chartSales} count={transactions_sum_count_report?.total_giro_type_116} dateTypeName={dateTypeName} text={'Toplam Satış'} url={'/report/giros'} price={transactions_sum_count_report?.giro_type?.sum?.total_service_fee?.["116"]} />
            </div>
            <div className="col-xl-3 col-md-6">
                <DoughnutChartWithCard data={chartsData.chartRent} count={transactions_sum_count_report?.total_giro_type_117} dateTypeName={dateTypeName} text={'Toplam Kiralama'} url={'/report/giros'} price={transactions_sum_count_report?.giro_type?.sum?.total_service_fee?.["117"]} />
            </div>
        </>

    );
};

export default TransactionTypesCountChart;
