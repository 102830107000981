import { Doughnut } from 'react-chartjs-2';
import CountUp from 'react-countup';
import { NavLink } from 'react-router-dom';

const DoughnutChartWithCard = ({ count, dateTypeName,data,text,url,price=null }: any) => {
    return (
        <div className="card card-h-100">
        <div className="card-body">
            <div className="d-flex align-items-center">
                <div className="flex-grow-1">
                    <span className="text-muted mb-3 lh-1 d-block text-truncate"><NavLink to={url} className={'text-muted'}>{text}</NavLink></span>
                    <h4 className="mb-3">
                        <CountUp end={count} />
                    </h4>
                    <div className="text-nowrap">
                    {price > 0 ? (
                                <span className="badge bg-soft-success text-success">₺{price}</span>
                            ) : null}
                        <span className="ms-1 text-muted font-size-13">{dateTypeName}</span>
                    </div>
                </div>
                <div className="flex-shrink-0 text-end dash-widget">
                    <Doughnut data={data}
                        options={{
                            responsive: true,
                            maintainAspectRatio: true,
                            cutout: 25,
                            plugins: {
                                legend: {
                                    display: false,
                                }
                            }
                        }} />
                </div>
            </div>
        </div>
    </div>
    );
};

export default DoughnutChartWithCard;
