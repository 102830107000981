import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { NavLink } from 'react-router-dom';
import { User } from "../../models/user";
import * as Icon from 'react-feather';
import * as Icons from 'react-icons';
import { GrMoney, GrTransaction, GrUserSettings, GrCalendar } from "react-icons/gr";
import { BsCalendar4Week, BsFileEarmarkText, BsArrowLeftRight,BsFilesAlt, BsBuilding } from "react-icons/bs";
import * as Bs from "react-icons/bs";
import { FiUsers, FiPhoneCall, FiFilePlus } from "react-icons/fi";
import { BiLira, BiBuildingHouse } from "react-icons/bi";

import { MdOutlineRealEstateAgent } from "react-icons/md";

import { GiDesk } from "react-icons/gi";
import * as Bi from "react-icons/bi";
import { FaWpforms, FaRegDotCircle } from "react-icons/fa";
import { HiOutlinePresentationChartBar } from "react-icons/hi"
import { VscAccount } from "react-icons/vsc"
import { AiOutlineFileDone, AiOutlineComment } from 'react-icons/ai';


import axios from 'axios';
import { Module } from "../../models/system/module/module";

import "react-datepicker/dist/react-datepicker.css";

const Menu = (props: { user: User }) => {
    useEffect(() => {
        console.log(props.user.role_id);
      }, [props]);
    
    const { user } = props;

    const [role_id, setRoleId] = useState(12);
    const [menus, setMenus] = useState([]);
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setRoleId(props.user.role_id);

        }, 10000); // 1000 milisaniye (1 saniye) bekletiyoruz
        
        return () => clearTimeout(timeoutId); // cleanup işlemi
    }, [props]);

    // useEffect(() => {
    //     (
    //         async () => {

    //             const { data } = await axios.get(`modules-menu/${props.user.role_id}`);
    //             setMenus(data);


    //         }
    //     )()
    // }, []);

    const navTabs = [menus];
    // const CustomIcon = (Icons{,iconName}:any) => {
    //        const Icon = Icons[iconName];

    //        if (!Icon) return <p>Icon not found!</p>;

    //        return <Icon />;
    //      };
    const components = {
        PhotoStory: ''
    };

    return (
        <div className="vertical-menu">
            <div data-simplebar className="h-100">

                <div id="sidebar-menu">

                    <ul className="metismenu list-unstyled" id="side-menu">
                        <li className="menu-title" data-key="t-menu">Menu</li>
                        {/* {navTabs.map((n) => {     
                            const SpecificStory = n.icon;

                                                    return (
                        <li>                              
                            <NavLink to={n.slug}> 
                    <SpecificStory/>
                                <span data-key="t-contract">{n.name}</span>
                            </NavLink>
                        </li>
                         )
                        })} */}
                        <li>
                            <NavLink to={'/'} end>
                                <Icon.Home />
                                {/* {props.user.role.id} */}
                                <span data-key="t-dashboard">Gösterge Paneli </span>
                            </NavLink>
                        </li>
                        {props.user.id === 1 || props.user.id === 100 || props.user.id === 101 ? (
                            <>
                                <li className="menu-title mt-2" data-key="t-components">Raporlar</li>
                                <li>
                                    <NavLink to={'/report/giros'} end>
                                        <Icon.BarChart2 />
                                        <span data-key="t-dashboard">Ciro</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/accounting/table-revenues">
                                        <GiDesk />
                                        <span data-key="t-table-revenues">Masa Gelirleri</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/revenue-expense-reports">
                                        <BiLira />
                                        <span data-key="t-desk-revenues">Gelir / Gider Raporları</span>
                                    </NavLink>
                                </li>
                                <li className="menu-title mt-2" data-key="t-components">Muhasebe</li>
                                <li>
                                    <NavLink to={'/accounting/transactions'} end>
                                        <BsArrowLeftRight />
                                        <span data-key="t-dashboard">İşlemler </span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/accounting/expenses">
                                        <Icon.List />
                                        <span data-key="t-expenses">Giderler</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/contract/contract-templates">
                                        <BsFilesAlt />
                                        <span data-key="t-forms">Sözleşme Şablonları</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/contract/lease-agreements">
                                        <BsFileEarmarkText />
                                        <span data-key="t-forms">Kira Kontratları</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/contract/down-payment-agreements">
                                        <FiFilePlus />
                                        <span data-key="t-forms">Ön Kapora Sözleşmeleri</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/contract/purchase-sale-agreements">
                                        <AiOutlineFileDone />
                                        <span data-key="t-forms">Alım Satım Protokolü</span>
                                    </NavLink>
                                </li>
                                <li className="menu-title mt-2" data-key="t-components">Gayrimenkul</li>
                                <li>
                                    <NavLink to="/contract/contract-templates">
                                        <MdOutlineRealEstateAgent />
                                        <span data-key="t-forms">Mülkler</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/real-estate/buildings">
                                        <BiBuildingHouse />
                                        <span data-key="t-forms">Yapılar</span>
                                    </NavLink>
                                </li>
                                <li className="menu-title mt-2" data-key="t-components">Asistan</li>
                                <li>
                                    <NavLink to={'/asistant/calls'} end>
                                        <FiPhoneCall />
                                        <span data-key="t-dashboard">Aramalar </span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={'/asistant/watch-schedule'} end>
                                        <BsCalendar4Week />
                                        <span data-key="t-dashboard">Nöbet Çizelgesi </span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={'/contract/authorization-agreements'} end>
                                        <FaWpforms />
                                        <span data-key="t-dashboard">Yetki Sözleşmeleri </span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={'/customer/satisfaction-survey'} end>
                                        <AiOutlineComment />
                                        <span data-key="t-dashboard"> Müşteri Yorumları</span>
                                    </NavLink>
                                </li>
                                
                                <li className="menu-title mt-2" data-key="t-components">Recruiter</li>
                                <li>
                                    <NavLink to={'/recruitment/meets'} end>
                                        <FiUsers />
                                        <span data-key="t-dashboard">Görüşmeler </span>
                                    </NavLink>
                                </li>
                                <li className="menu-title mt-2" data-key="t-components">Personel</li>
                                <li>
                                    <NavLink to={'/employee/work-schedules'} end>
                                        <BsCalendar4Week />
                                        <span data-key="t-dashboard">Günlük İş Raporu </span>
                                    </NavLink>
                                </li>
                                <li className="menu-title mt-2" data-key="t-components">Kullanıcı Yönetimi</li>
                                <li>
                                    <NavLink to={'/users'} end>
                                        <FiUsers />
                                        <span data-key="t-dashboard">Kullanıcılar </span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={'/roles'} end>
                                        <Icon.UserCheck />
                                        <span data-key="t-dashboard">Rol Yönetimi </span>
                                    </NavLink>
                                </li>
                            </>
                        ) : null}
                        {props.user.role.id === 10 ? (
                            <>
                                <li>
                                    <NavLink to={'/accounting/transactions'} end>
                                        <BsArrowLeftRight />
                                        <span data-key="t-dashboard">İşlemler </span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/accounting/table-revenues">
                                        <GiDesk />
                                        <span data-key="t-table-revenues">Masa Gelirleri</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/accounting/expenses">
                                        <Icon.List />
                                        <span data-key="t-expenses">Giderler</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/contract/contract-templates">
                                        <FaWpforms />
                                        <span data-key="t-forms">Sözleşme Şablonları</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/contract/lease-agreements">
                                        <BsFileEarmarkText />
                                        <span data-key="t-forms">Kira Kontratları</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={'/contract/authorization-agreements'} end>
                                        <FaWpforms />
                                        <span data-key="t-dashboard">Yetki Sözleşmeleri </span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/contract/down-payment-agreements">
                                        <FiFilePlus />
                                        <span data-key="t-forms">Ön Kapora Sözleşmeleri</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={'/customer/satisfaction-survey'} end>
                                        <AiOutlineComment />
                                        <span data-key="t-dashboard"> Müşteri Yorumları</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={'/employee/work-schedule'} end>
                                        <BsCalendar4Week />
                                        <span data-key="t-dashboard">Günlük İş Raporu </span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={'/users'} end>
                                        <FiUsers />
                                        <span data-key="t-dashboard">Kullanıcılar </span>
                                    </NavLink>
                                </li>
                            </>
                        ) : null}
                         {props.user.role.id === 19 ? (
                            <>
                                <li>
                                    <NavLink to={'/accounting/transactions'} end>
                                        <BsArrowLeftRight />
                                        <span data-key="t-dashboard">İşlemler </span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/contract/contract-templates">
                                        <FaWpforms />
                                        <span data-key="t-forms">Sözleşme Şablonları</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/contract/lease-agreements">
                                        <BsFileEarmarkText />
                                        <span data-key="t-forms">Kira Kontratları</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={'/contract/authorization-agreements'} end>
                                        <FaWpforms />
                                        <span data-key="t-dashboard">Yetki Sözleşmeleri </span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/contract/down-payment-agreements">
                                        <FiFilePlus />
                                        <span data-key="t-forms">Ön Kapora Sözleşmeleri</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={'/customer/satisfaction-survey'} end>
                                        <AiOutlineComment />
                                        <span data-key="t-dashboard"> Müşteri Yorumları</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={'/employee/work-schedule'} end>
                                        <BsCalendar4Week />
                                        <span data-key="t-dashboard">Günlük İş Raporu </span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={'/users'} end>
                                        <FiUsers />
                                        <span data-key="t-dashboard">Kullanıcılar </span>
                                    </NavLink>
                                </li>
                            </>
                        ) : null}
                        {props.user.role.id === 17? (
                            <>
                                <li>
                                    <NavLink to={'/employee/work-schedule'} end>
                                        <BsCalendar4Week />
                                        <span data-key="t-dashboard">Günlük İş Raporu </span>
                                    </NavLink>
                                </li>
                            </>
                        ) : null}
                        
                        {props.user.role.id === 16? (
                            <>
                                <li>
                                    <NavLink to={'/employee/work-schedule'} end>
                                        <BsCalendar4Week />
                                        <span data-key="t-dashboard">Günlük İş Raporu </span>
                                    </NavLink>
                                </li>
                            </>
                        ) : null}

                        {props.user.role.id === 15 ? (
                            <>
                                <li>
                                    <NavLink to={'/asistant/calls'} end>
                                        <FiPhoneCall />
                                        <span data-key="t-dashboard">Aramalar </span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={'/asistant/watch-schedule'} end>
                                        <BsCalendar4Week />
                                        <span data-key="t-dashboard">Nöbet Çizelgesi </span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={'/customer/satisfaction-survey'} end>
                                        <AiOutlineComment />
                                        <span data-key="t-dashboard"> Müşteri Yorumları</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={'/contract/authorization-agreements'} end>
                                        <FaWpforms />
                                        <span data-key="t-dashboard">Yetki Sözleşmeleri </span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={'/employee/work-schedule'} end>
                                        <BsCalendar4Week />
                                        <span data-key="t-dashboard">Günlük İş Raporu </span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={'/users'} end>
                                        <FiUsers />
                                        <span data-key="t-dashboard">Kullanıcılar </span>
                                    </NavLink>
                                </li>
                            </>
                        ) : null}

                        {props.user.role.id === 12 ? (
                            <>
                                <li>
                                    <NavLink to={'/employee/work-schedule'} end>
                                        <BsCalendar4Week />
                                        <span data-key="t-dashboard">Günlük İş Raporu </span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={'/recruitment/meets'} end>
                                        <FiUsers />
                                        <span data-key="t-dashboard">Görüşmeler </span>
                                    </NavLink>
                                </li>
                            </>
                        ) : null}

{props.user.role.id === 14 ? (
                            <>
                                <li>
                                    <NavLink to={'/employee/work-schedule'} end>
                                        <BsCalendar4Week />
                                        <span data-key="t-dashboard">Günlük İş Raporu </span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={'/asistant/watch-schedule'} end>
                                        <BsCalendar4Week />
                                        <span data-key="t-dashboard">Nöbet Çizelgesi </span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={'/recruitment/meets'} end>
                                        <FiUsers />
                                        <span data-key="t-dashboard">Görüşmeler </span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to={'/users'} end>
                                        <FiUsers />
                                        <span data-key="t-dashboard">Kullanıcılar </span>
                                    </NavLink>
                                </li>
                            </>
                        ) : null}


                        {/* {menus.map((m: any) => {
                            let mIcon = m.icon;

                            const SpecificIcon = m.icon;

                            const navTabs = [{
                                route: m.slug,
                                icon: m.icon,
                                label: m.name
                            }];

                            return (
                                <li>
                                    <NavLink to={m.slug}>
                                        <SpecificIcon />
                                        <FaRegDotCircle />
                                        <span data-key="t-contract">{m.name}</span>
                                    </NavLink>
                                </li>
                            )
                        })}


                        <li>
                            <NavLink to="/forms">
                                <FaWpforms />
                                <span data-key="t-forms">Formlar</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/activities">
                                <Icon.Activity />
                                <span data-key="t-activities">Aktiviteler</span>
                            </NavLink>
                        </li>
                        <li className="menu-title" data-key="t-accountant">Muhasebe</li>
                        <li>
                            <NavLink to="/turnover-reports">
                                <Icon.BarChart2 />
                                <span data-key="t-dashboard">Ciro Raporları</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/desk-revenues">
                                <GiDesk />
                                <span data-key="t-desk-revenues">Masa Gelirleri</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/desk-revenues">
                                <Icon.List />
                                <span data-key="t-desk-revenues">Gider Listesi</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/desk-revenues">
                                <BiLira />
                                <span data-key="t-desk-revenues">Gelir / Gider Raporları</span>
                            </NavLink>
                        </li>
                        <li className="menu-title" data-key="t-real-estate-agent">Gayrimenkul Danışmanı</li>
                        <li>
                            <NavLink to="/activity-transactions">
                                <Icon.Activity />
                                <span data-key="t-activity-transactions">Aktivite İşlemleri</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/form-transactions">
                                <FaWpforms />
                                <span data-key="t-form-transactions">Form İşlemleri</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/form-transactions">
                                <HiOutlinePresentationChartBar />
                                <span data-key="t-form-transactions">Sunum İşlemleri</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/business-card">
                                <AiOutlineIdcard />
                                <span data-key="t-business-card">Kartvizit</span>
                            </NavLink>
                        </li>
                        <li className="menu-title" data-key="t-assistant">Asistan</li>
                        <li>
                            <NavLink to="/asistant/calls">
                                <Icon.PhoneIncoming />
                                <span data-key="t-dashboard">Gelen Aramalar</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/asistant/watch-schedule">
                                <BsCalendar4Week />
                                <span data-key="t-watch-schedule">Nöbet Çizelgesi</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/form-settings">
                                <FaWpforms />
                                <span data-key="t-form-settings">Form Yönetimi</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/asistant-settings">
                                <Icon.Settings />
                                <span data-key="t-asistant-settings">Asistan Ayarları</span>
                            </NavLink>
                        </li>

                        <li className="menu-title" data-key="t-recruiter">Recruiter</li>
                        <li>
                            <NavLink to="/recruitment/meets">
                                <Icon.Calendar />
                                <span data-key="t-dashboard">Görüşmeler</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/recruiter-calls">
                                <Icon.PhoneCall />
                                <span data-key="t-calls">Aramalar</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/meet-reports">
                                <MdOutlineBubbleChart />
                                <span data-key="t-meet-reports">Görüşme Raporları</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/recruiter-settings">
                                <Icon.Settings />
                                <span data-key="t-recruiter-settings">Recruiter Ayarları</span>
                            </NavLink>
                        </li>
                        <li className="menu-title" data-key="t-admin">Yönetim</li>
                        <li>
                            <NavLink to="/roles">
                                <Icon.UserCheck />
                                <span data-key="t-dashboard">Roller</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/users">
                                <Icon.Users />
                                <span data-key="t-dashboard">Kullanıcılar</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/my-account">
                                <VscAccount />
                                <span data-key="t-my-account">Hesabım</span>
                            </NavLink>
                        </li>

                        <li>
                            <NavLink to="/settings">
                                <Icon.Settings />
                                <span data-key="t-settings">Ayarlar</span>
                            </NavLink>
                        </li>
                        <li className="menu-title" data-key="t-admin">Ayarlar</li>
                        <li>
                            <NavLink to="/attribute/results">
                                <Icon.Settings />
                                <span data-key="t-settings">Sonuç Türleri</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/attribute/communications">
                                <Icon.Settings />
                                <span data-key="t-settings">İletişim Kaynakları</span>
                            </NavLink>
                        </li> */}
                    </ul>
                </div>
            </div>
        </div>
    )
}
export default connect(
    (state: { user: User }) => {
        return {
            user: state.user
        };
    }
)(Menu);
